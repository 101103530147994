import { forwardRef } from 'react';

import type { ButtonTypeMap } from '@mui/material/Button';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import Styled, { type ButtonProps } from './styled';

export const Button: OverridableComponent<ButtonTypeMap<ButtonProps>> = forwardRef(
  ({ fullWidth = true, variant = 'outlined', ...props }, ref) => {
    return <Styled.Button ref={ref} fullWidth={fullWidth} variant={variant} {...props} />;
  },
);
