import { chipClasses } from '@mui/material/Chip';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { alpha, type Components, type Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';
import { Z_INDEXES } from 'constants/elevations';

import { themeTypography } from './typography';

export const createComponents = (palette: Theme['palette']): Components => ({
  MuiButton: {
    defaultProps: { size: 'large', variant: 'contained' },
    styleOverrides: {
      root: {
        borderRadius: '8px',
        textTransform: 'none',

        fontFamily: '"Inter"',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',

        minWidth: 'auto',
      },
      sizeMedium: {
        padding: '8px 12px',
        borderRadius: '8px',

        fontFamily: '"Inter"',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
      },
      sizeSmall: {
        padding: '4px 8px',
        borderRadius: '4px',

        fontSize: '14px',
        fontFamily: '"Inter"',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '20px',
      },
      sizeLarge: {
        padding: '12px 16px',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
      },
      body: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
      },
      '#root': {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        minHeight: '100vh',
      },
      '#nprogress': {
        pointerEvents: 'none',
      },
      '#nprogress .bar': {
        height: 3,
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: Z_INDEXES.PROGRESS,
        backgroundColor: palette.primary.main,
      },
      a: {
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '.cky-btn-revisit-wrapper': {
        display: 'none !important',
      },
      // toast override
      '.Toastify__toast-container': {
        minWidth: 288,
        width: 'max-content',
        maxWidth: '90%',
        padding: 0,
        zIndex: Z_INDEXES.TOAST,
      },
      '.Toastify__toast': {
        borderRadius: 32,
        padding: '8px 32px',
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: 'auto',
      },
      '.Toastify__toast-body': {
        padding: '8px 0',
        flex: 'none',
        width: '100%',
      },
      '.Toastify__toast--success': {
        color: palette.success.main,
        background: palette.grey['500'],
      },
      '.Toastify__toast--error': {
        color: 'white',
        background: palette.error.main,
      },
      '.Toastify__progress-bar': {
        visibility: 'hidden',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      checkedIcon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_30_171)">
            <rect width="16" height="16" rx="4" fill={palette.primary.main} />
            <path
              d="M2.66663 8L5.99996 11.3333"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.00004 11.3333L13.3334 4"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_30_171">
              <rect width="16" height="16" rx="4" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      indeterminateIcon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="4" fill="#0452FB" />
          <path d="M12 8H3.99976" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      ),
      icon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="4" fill={palette.text.primary} fillOpacity="0.08" />
        </svg>
      ),
    },
    styleOverrides: {
      root: {
        padding: 8,

        '& svg': {
          borderRadius: '4px',
        },
        '& svg rect': {
          transition: 'all 0.3s ease-in-out',
        },

        '&:hover:not(.Mui-checked)': {
          '& svg': {
            '& rect': {
              stroke: 'rgba(4, 82, 251, 0.24)',
              fill: 'white',
            },
          },
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      checkedIcon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_33_403)">
            <rect width="16" height="16" rx="8" fill={palette.primary.main} />
            <circle cx="7.99992" cy="8.00001" r="2.66667" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_33_403">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      icon: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="8" fill={palette.text.primary} fillOpacity="0.08" />
        </svg>
      ),
    },
    styleOverrides: {
      root: {
        padding: 8,

        '& svg': {
          borderRadius: '4px',
          width: 24,
          height: 24,
        },
        '& svg rect': {
          transition: 'all 0.3s ease-in-out',
        },

        '&:hover:not(.Mui-checked)': {
          '& svg': {
            '& rect': {
              stroke: 'rgba(4, 82, 251, 0.24)',
              fill: 'white',
            },
          },
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      caption: {
        color: palette.text.secondary,
      },
      root: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: { shrink: true, size: 'normal' },
    styleOverrides: {
      root: {
        fontFamily: '"Inter"',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: palette.text.secondary,

        transform: 'none',

        [`&.${inputLabelClasses.sizeSmall}`]: {
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontFamily: '"Inter"',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        color: palette.text.primary,
        backgroundColor: 'white',
        borderRadius: 8,

        '&::placeholder': {
          opacity: 1,
        },

        [`& .${inputBaseClasses.inputAdornedStart}`]: {
          paddingLeft: '8px',
        },

        [`& .${inputBaseClasses.inputAdornedEnd}`]: {
          paddingRight: '8px',
        },
        padding: '14px 12px',
      },
      sizeSmall: {
        padding: '8px 12px',
        borderRadius: 8,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.MuiOutlinedInput-root': {
          borderRadius: 8,
        },
        '&.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          borderRadius: 8,
        },

        [`& .${inputBaseClasses.sizeSmall}`]: {
          borderRadius: 8,
        },

        [`&.${outlinedInputClasses.adornedEnd}`]: {
          paddingRight: '12px',
        },

        [`&.${outlinedInputClasses.adornedStart}`]: {
          paddingLeft: '12px',
        },

        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: 'rgba(16, 26, 51, 0.12)',
        },

        '&:hover': {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(4, 82, 251, 0.24)',
          },
        },

        [`&.${outlinedInputClasses.focused}`]: {
          backgroundColor: 'transparent',
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(4, 82, 251, 0.24)',
            borderWidth: 1,
          },
        },
        [`&.${outlinedInputClasses.error}`]: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(255, 96, 74, 0.24)',
            borderWidth: 1,
          },
        },
      },
      input: {
        padding: 0,
        height: '20px',
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <Icon name="arrow-down" size="small" />,
    },
    styleOverrides: {
      endAdornment: {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      inputRoot: {
        '& .MuiAutocomplete-input': {
          padding: '0 !important',
        },
      },
      root: {
        '& .MuiOutlinedInput-root': {
          padding: '14px 12px',
          paddingRight: '36px !important',
          borderRadius: 8,
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          padding: '8px 12px',
          borderRadius: 8,
        },
      },
      popper: {
        zIndex: Z_INDEXES.SNACKBAR_POPOVER,
      },
      paper: {
        boxShadow: '0px 0px 0px 1px rgba(16, 26, 51, 0.08), 0px 2px 4px rgba(16, 26, 51, 0.08)',
        borderRadius: 8,
        marginTop: 8,
      },
      listbox: {
        padding: 0,

        '& .MuiAutocomplete-option': {
          ...themeTypography['body-2'],
          padding: 8,
          color: palette.grey['500'],

          '& div + span': {
            marginLeft: 8,
          },

          '&:hover': {
            '& span': {
              textDecoration: 'none',
            },
          },

          '&:not(:last-child)': {
            borderBottom: `1px solid ${palette.grey['200']}`,
          },
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...themeTypography['body-2'],
        marginBottom: 8,
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        '& label+.MuiFormControl-root': {
          marginTop: 0,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        marginLeft: 0,
        marginRight: 0,

        [`& ${formHelperTextClasses.error}`]: {
          color: palette.error.main,
        },
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      backdrop: {
        backgroundColor: 'rgba(16, 26, 51, 0.48)',
      },
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        '& .MuiModal-backdrop': {
          backgroundColor: 'transparent',
        },
        zIndex: Z_INDEXES.SNACKBAR_POPOVER,
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: (props) => <Icon {...props} size="small" name="arrow-down" />,
    },
    styleOverrides: {
      select: {
        // icons
        '& ~ div': {
          position: 'absolute',
          right: 10,
          transition: 'all .2s',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
        height: 'auto',
        backgroundColor: palette.grey['200'],

        [`& .${chipClasses.labelSmall}`]: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '14px',
          letterSpacing: '0.08em',
          textTransform: 'uppercase',
          paddingLeft: '8px',
          paddingRight: '8px',
        },

        [`& .${chipClasses.labelMedium}`]: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '14px',
          letterSpacing: '0.08em',
          textTransform: 'uppercase',
        },

        [`& .${chipClasses.label}`]: {
          color: palette.grey['500'],
        },

        [`&.${chipClasses.colorSuccess}`]: {
          backgroundColor: alpha(palette.success.main, 0.48),
        },

        [`&.${chipClasses.colorWarning}`]: {
          backgroundColor: alpha(palette.warning.main, 0.48),
        },

        [`&.${chipClasses.colorError}`]: {
          backgroundColor: alpha(palette.error.main, 0.32),
        },

        [`&.${chipClasses.colorInfo}`]: {
          backgroundColor: 'transparent',
          border: `1px solid ${palette.divider}`,
        },

        [`&.${chipClasses.colorPrimary}`]: {
          backgroundColor: palette.primary.main,

          [`& .${chipClasses.label}`]: {
            color: palette.primary.contrastText,
          },
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        boxShadow: '0px 0px 0px 1px rgba(16, 26, 51, 0.08), 0px 2px 4px rgba(16, 26, 51, 0.08)',
        borderRadius: 8,
        marginTop: 8,
      },
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...themeTypography['body-2'],
        padding: 8,
        color: palette.grey['500'],

        '& div + span': {
          marginLeft: 8,
        },

        '&:hover': {
          '& span': {
            textDecoration: 'none',
          },
        },

        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.grey['200']}`,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        ...themeTypography.badge,
        textTransform: 'uppercase',
        color: palette.grey['600'],
        whiteSpace: 'nowrap',
      },
      body: {
        ...themeTypography['body-2'],
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&.Mui-active': {
          color: palette.grey['600'],
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        color: palette.grey['600'],
        minWidth: 36,
        height: 36,
        borderRadius: '50%',
        '&.Mui-selected': {
          background: 'transparent',
          border: `1px solid ${palette.grey['200']}`,
          '&:hover': {
            background: palette.grey['200'],
          },
        },
        '&:hover': {
          background: palette.grey['200'],
        },
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
      focusVisibleClassName: 'Mui-focusVisible',
    },
    styleOverrides: {
      root: {
        width: 40,
        height: 24,
        padding: 0,
      },
      switchBase: {
        padding: 0,
        margin: 4,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: 'white',
          '& + .MuiSwitch-track': {
            backgroundColor: palette.primary.main,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: palette.primary.main,
          border: '6px solid white',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: palette.grey[100],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      thumb: {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
        boxShadow: `0px 0px 0px 1px ${palette.divider}, 0px 2px 4px ${palette.divider}`,
      },
      track: {
        borderRadius: 24 / 2,
        backgroundColor: palette.divider,
        opacity: 1,
        transition: 'background-color 0.2s',
      },
    },
  },
});
