import type { MultiFactorModel } from './models';

export type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;

  isMFACompleted: boolean | null;
  mfa: MultiFactorModel | null;

  wasQuizClosed: boolean;
};

export enum UserRole {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_TECH_SUPPORT = 'ROLE_TECH_SUPPORT',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ROLE_MARKETING = 'ROLE_MARKETING',
  ROLE_CAN_SWITCH_USER = 'ROLE_CAN_SWITCH_USER',
}

export type MultiFactorProvider = 'webauthn' | 'google';

type MultiFactorHardwareAllowCredentials = {
  type: 'public-key';
  id: string;
};

export type MultiFactorHardwareOptions = {
  challenge: string;
  allowCredentials: MultiFactorHardwareAllowCredentials[];
};
