import { styled } from '@mui/material/styles';

import { TextButton } from 'components/TextButton';

const SetIPButton = styled(TextButton)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.grey['600'],
}));

export default {
  SetIPButton,
};
