import Box from '@mui/material/Box';
import { formLabelClasses } from '@mui/material/FormLabel';
import Grid, { gridClasses, type GridProps as MuiGridProps } from '@mui/material/Grid';
import MuiIconButton from '@mui/material/IconButton';
import Select, { selectClasses } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

type GridProps = Omit<MuiGridProps, 'container' | 'item'>;

const PaymentFeeRoot = styled((props: Omit<GridProps, 'container' | 'item'>) => <Grid container {...props} />)(
  ({ theme }) => ({
    rowGap: theme.spacing(2),
  }),
);

const PaymentFeeItem = styled((props: Omit<GridProps, 'container' | 'item'>) => <Grid item {...props} />)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const PaymentFeeItemLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& span + button': {
    marginLeft: theme.spacing(1),
  },
}));

const IconButton = styled(MuiIconButton)({
  padding: 0,
});

const TopUpGrid = styled(Grid)(({ theme }) => ({
  [`& .${formLabelClasses.root}`]: theme.typography.title,
}));

const PaymentMethodsDropdown = styled(Select)(({ theme }) => ({
  padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,

  [`& .${selectClasses.select} .${gridClasses.item}:last-child`]: {
    display: 'none',
  },
}));

export default {
  PaymentFeeRoot,
  PaymentFeeItem,
  PaymentFeeItemLabel,
  IconButton,
  TopUpGrid,
  PaymentMethodsDropdown,
};
