import type { ButtonProps } from '@mui/material/Button';
import type { Theme } from '@mui/material/styles';

export function getButtonDisabledColors({
  color,
  theme,
  loading = false,
}: {
  color: ButtonProps['color'];
  theme: Theme;
  loading?: boolean;
}) {
  if (!color) return {};

  switch (color) {
    case 'success':
      return loading
        ? { backgroundColor: theme.palette.success.main }
        : { backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText, opacity: 0.48 };
    case 'primary':
      return loading
        ? { backgroundColor: theme.palette.primary.main }
        : { backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, opacity: 0.48 };
    case 'error':
      return loading
        ? { backgroundColor: theme.palette.error.main }
        : { backgroundColor: theme.palette.error.main, color: theme.palette.common.white, opacity: 0.48 };
    case 'secondary':
      return loading
        ? { backgroundColor: theme.palette.divider }
        : { backgroundColor: theme.palette.divider, color: theme.palette.text.primary, opacity: 0.48 };

    default:
      return {};
  }
}
