import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const LayoutRoot = styled(
  ({
    $isMobile,
    ...props
  }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { $isMobile: boolean }) => <div {...props} />,
)(({ theme, $isMobile }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'grid',
  flex: '1 1 auto',
  height: '100%',
  position: 'relative',
  gridTemplateColumns: $isMobile ? '1fr' : 'repeat(2, 1fr)',
}));

const DesktopLayoutAdornment = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.darkest,
  display: 'flex',
  flex: '1 1 auto',
  position: 'fixed',
  width: '50vw',
  height: '100vh',
  padding: theme.spacing(6),

  '& svg': {
    zIndex: theme.zIndex.drawer,
  },
}));

const RightSideWrapper = styled(Box)(({ theme }) => ({
  marginLeft: '50vw',
  width: '50vw',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    width: '100vw',
    marginLeft: 0,
  },
}));

const MobileLayoutAdornment = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),

  '& svg': {
    zIndex: theme.zIndex.drawer,
  },
}));

const AuthVectorWrapper = styled('span')({
  position: 'absolute',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'right center',
  backgroundImage: 'url("/resources/vectors/auth.svg")',
  height: '100%',
  width: '100%',
  right: 0,
  top: 0,
  bottom: 0,
});

export default {
  LayoutRoot,
  MobileLayoutAdornment,
  DesktopLayoutAdornment,
  RightSideWrapper,
  AuthVectorWrapper,
};
