import type { FormikErrors, FormikTouched } from 'formik';

type FieldErrorOptions<T extends object, K extends keyof T> = {
  fieldName: K;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
};

export type FormErrors = Record<string, string | string[] | undefined>;

export function getFieldError<T extends object, K extends keyof T>({
  errors,
  fieldName,
  touched,
}: FieldErrorOptions<T, K>) {
  const isFieldTouched = !!touched[fieldName];
  const hasError = !!errors[fieldName];

  const errorMessage = errors[fieldName];

  return {
    isTouched: isFieldTouched,
    hasError,
    errorMessage: errorMessage || '',
  };
}

export function shouldFieldDisplayError<T extends object, K extends keyof T>(
  options: FieldErrorOptions<T, K>,
): boolean {
  const { hasError, isTouched } = getFieldError(options);

  return isTouched && hasError;
}
