export const posthogEvents = {
  userLoggedOut: 'user_logged_out',
  userPasswordReset: 'user_password_reset',
  userDetailsEdited: 'user_details_edited',
  security2FaDisabled: 'security_2fa_disabled',
  security2FaEnabled: 'security_2fa_enabled',
  notificationsAllEnabled: 'notifications_all_enabled',
  notificationsAllDisabled: 'notifications_all_disabled',
  accountInvoicesView: 'account_invoices_view',
  accountInvoiceView: 'account_invoice_view',
  accountTransactionsView: 'account_transactions_view',
  emailConfirmationResent: 'email_confirmation_resent',

  orderProxyMobileSelected: 'order_proxy_mobile_selected',
  orderProxyDatacenterSelected: 'order_proxy_datacenter_selected',
  orderProxyStaticResidentialSelected: 'order_proxy_static_residential_selected',
  orderProxyRotatingResidentialSelected: 'order_proxy_rotating_residential_selected',

  proxyExtendBandwidth: 'proxy_bandwidth_extended',
  proxyExtendPeriod: 'proxy_period_extended',
  proxyReactivated: 'proxy_reactivated',
  proxyAutoExtendEnabled: 'proxy_auto_extend_enabled',
  proxyAutoExtendDisabled: 'proxy_auto_extend_disabled',
  proxyProtocolChanged: 'proxy_protocol_changed',
  proxyCredentialsDownloaded: 'proxy_credentials_download',
  proxyGlobalIPWhitelistModified: 'proxy_globalIpWhitelist_modified',
  proxyIpWhitelistModified: 'proxy_ipWhitelist_modified',
  proxyNoteAdded: 'proxy_note_added',
  proxyNoteDeleted: 'proxy_note_deleted',
  proxyCredentialsRegenerated: 'proxy_credentials_regenerated',
  proxyUpgradeBandwidthSpeed: 'proxy_upgrade_bandwidth_speed',
  proxyUpgradeThreads: 'proxy_upgrade_threads',
  proxyAuthenticationTypeModified: 'proxy_authentication_type_modified',
  proxyPortChanged: 'proxy_port_changed',

  // bulk
  proxyBulkActionClicked: 'proxy_bulk_action_clicked',
  proxyExtendBandwidthBulk: 'proxy_bandwidth_extended_bulk',
  proxyExtendPeriodBulk: 'proxy_period_extended_bulk',
  proxyAutoExtendBulk: 'proxy_auto_extend_bulk',
  proxyProtocolChangedBulk: 'proxy_protocol_changed_bulk',
  proxyAuthenticationTypeModifiedBulk: 'proxy_authentication_type_modified_bulk',

  review: {
    dismissClicked: 'review_dismiss_clicked',
    leaveReviewClicked: 'leave_a_review_clicked',
  },

  proxy: {
    rotating: {
      credentialGenerated: 'rotating_residential_creds_generated',
    },
  },

  marketplace: {
    dealSelected: 'marketplace_deal_selected',
  },

  vpnAutoExtendEnabled: 'vpn_auto_extend_enabled',
  vpnAutoExtendDisabled: 'vpn_auto_extend_disabled',
  vpnExtendPeriod: 'vpn_period_extended',

  // Funnels
  topUp: {
    click: 'topup_click',
    success: 'topup_success',
    failure: 'topup_failure',
    canceled: 'topup_canceled',
  },

  orderTopUp: {
    click: 'order_topup_click',
    success: 'order_topup_success',
    failure: 'order_topup_failure',
    canceled: 'order_topup_canceled',
  },

  signUp: {
    registered: 'user_registered',
    failed: 'user_registration_failed',
  },

  signIn: {
    loggedIn: 'user_logged_in',
    twoFactor: 'user_login_2fa',
    failed: 'user_login_failed',
  },

  order: {
    started: 'order_started',
    continued: 'order_continued',
    typeSelected: 'order_proxy_type_selected',
    vpnPlanSelected: 'order_vpn_plan_selected',
    codeApplied: 'order_coupon_code_applied',
    invalidCode: 'order_invalid_coupon_code',
    exit: 'order_exit',
    outOfStock: 'order_out_of_stock',

    // extended metrics
    visited: 'order_proxy_visited',
    packageTypeSelected: 'order_proxy_package_type_selected',
    ipvSelected: 'order_proxy_ipv_selected',
    countrySelected: 'order_proxy_country_selected',
    ispSelected: 'order_proxy_isp_selected',
    periodSelected: 'order_proxy_period_selected',
    proxyEditClicked: 'order_proxy_edit_clicked',
    configurationDeadEnd: 'order_proxy_configuration_dead_end',
    emailConfirmationDisplayed: 'order_email_confirmation_displayed',

    personalDetails: {
      visited: 'order_user_details_visited',
      skipped: 'order_user_details_skipped',
      filled: 'order_user_details_filled',
      failed: 'order_user_details_failed',
    },

    checkout: {
      visited: 'order_checkout_visited',
      paymentChosen: 'order_checkout_payment_chosen',
      initiated: 'order_checkout_initiated',
      executed: 'order_executed',
      failure: 'order_failure',
    },

    packages: {
      standard: 'order_standard_proxy',
      premium: 'order_premium_proxy',
    },

    staticResidentialTimePeriod: 'order_success_static_time_period_amount',
    rotatingBandwidth: 'order_success_rotating_bandwidth_amount',

    setupCompleted: 'order_proxy_setup_form_completed',
    experiencedUser: 'experienced_user',
    newbieUser: 'newbie_user',
    quizClicked: 'order_quiz_clicked',
  },

  quiz: {
    started: 'quiz_started',
    purpose: 'quiz_purpose',
    targetCountry: 'quiz_target_country',
    targetState: 'quiz_target_state',
    targetDomain: 'quiz_target_domain',
    selectedRecommendation: 'quiz_selected_recommendation',
  },
};
