import { Fragment, forwardRef } from 'react';

import Box from '@mui/material/Box';
import type { CardProps as MuiCardProps, CardTypeMap } from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography from '@mui/material/Typography';

import Styled, { type CardType, type Direction } from './styled';

export type CardProps = MuiCardProps & {
  type?: CardType;
  size?: 'large' | 'small';
  direction?: Direction;
  label?: string | null;
  titleActions?: JSX.Element[];
  actions?: JSX.Element[];
  extra?: JSX.Element;
  centered?: boolean;
};

export const Card: OverridableComponent<CardTypeMap<CardProps>> = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      type = 'grey',
      direction = 'horizontal',
      size = 'large',
      label,
      actions,
      children,
      titleActions,
      extra,
      centered,
      ...props
    },
    ref,
  ) => {
    return (
      <Grid container direction="column" rowGap={label ? 2 : 0}>
        {label && (
          <Grid item display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="title">{label}</Typography>

            {titleActions && (
              <Box>
                {titleActions.map((actionItem, index) => (
                  <Fragment key={index}>{actionItem}</Fragment>
                ))}
              </Box>
            )}
          </Grid>
        )}

        <Styled.Card ref={ref} type={type} size={size} {...props}>
          <Styled.CardContent direction={direction} centered={centered}>
            {children}

            {actions && (
              <Styled.CardActionsContainer direction={direction} size={size} spacing={size === 'small' ? 1 : 2}>
                {actions.map((actionItem, index) => (
                  <Grid item key={index}>
                    {actionItem}
                  </Grid>
                ))}
              </Styled.CardActionsContainer>
            )}
          </Styled.CardContent>
        </Styled.Card>

        {extra}
      </Grid>
    );
  },
);
