import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useHideModal } from 'components/modals/context';
import { useShowModal } from 'modules/common/GlobalModals';
import { paths } from 'paths';
import { useGetUserSessionQuery } from 'store/api';
import { VerificationStatus } from 'store/auth/models';

import { useFeatureFlag } from './useFeatureFlag';

export function useIDVerificationStatus() {
  const { t } = useTranslation();
  const { data: session, isLoading, refetch, isUninitialized } = useGetUserSessionQuery();
  const navigate = useNavigate();
  const hideModal = useHideModal();
  const showModal = useShowModal();
  const { isIdentityVerificationEnabled } = useFeatureFlag();

  const verificationRequest = useMemo(
    () => session?.profileDetails?.verificationRequest,
    [session?.profileDetails?.verificationRequest],
  );

  const isVerificationRequired = useMemo(() => {
    if (!verificationRequest) return false;

    return verificationRequest.status === VerificationStatus.REQUIRED;
  }, [verificationRequest]);

  const shouldDisplayIDNotification = useMemo(() => {
    return (
      isIdentityVerificationEnabled &&
      (isVerificationRequired || verificationRequest?.status === VerificationStatus.FAILED)
    );
  }, [isIdentityVerificationEnabled, isVerificationRequired, verificationRequest?.status]);

  const shouldDisplayStatus = useMemo(() => {
    if (!verificationRequest) return false;

    return [VerificationStatus.FAILED, VerificationStatus.NOT_VERIFIED, VerificationStatus.PENDING].includes(
      verificationRequest.status,
    );
  }, [verificationRequest]);

  const shouldForceIDVerification = useCallback(() => {
    if (!isVerificationRequired || !isIdentityVerificationEnabled) return false;

    showModal('account.id.verification', {
      title: t('account.modals.idVerification.title'),
      subtitle: t('account.modals.idVerification.subtitle'),
      confirmLabel: t('common:buttons.continueVerification'),
      closeDisabled: true,
      onConfirm: () => {
        navigate(paths.account.identityVerification);
        hideModal();
      },
    });

    return true;
  }, [hideModal, isIdentityVerificationEnabled, isVerificationRequired, navigate, showModal, t]);

  return useMemo(
    () => ({
      isLoading,
      isVerificationRequired,
      shouldDisplayIDNotification,
      shouldDisplayStatus,
      verificationRequest,
      isUninitialized,
      shouldForceIDVerification,
      refetch,
    }),
    [
      isLoading,
      isVerificationRequired,
      shouldDisplayIDNotification,
      shouldDisplayStatus,
      verificationRequest,
      isUninitialized,
      shouldForceIDVerification,
      refetch,
    ],
  );
}
