import { ADMIN_ROLES } from 'constants/roles';

import { parseJWT } from './jwt';
import { PersistService } from './persist';

export function isAdminUser() {
  const { accessToken } = PersistService.getCredentials();

  const payload = parseJWT(accessToken);

  if (!payload?.roles) return false;

  return payload.roles.some((r) => ADMIN_ROLES.includes(r));
}
