import { createContext, useCallback, useContext, useRef, type ReactNode } from 'react';

import ModalHost from './ModalHost';
import type { ModalManagerRenderFunctionType, ModalStateType } from './types';

type ModalProvider = {
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ModalContext = createContext<(state: ModalStateType) => void>(() => {});

function useModalManager() {
  const setState = useContext(ModalContext);
  const show = (id: string, params?: Record<string, unknown>) => setState({ id, params: params ?? {} });
  const hide = () =>
    setState({
      id: undefined,
      params: undefined,
    } as unknown as Record<string, never>);

  return { show, hide };
}

export function useHideModal() {
  return useModalManager().hide;
}

export function createModalManager<S extends ModalStateType>(renderFn: ModalManagerRenderFunctionType<S>) {
  const Provider = ({ children }: ModalProvider) => {
    const ref = useRef<ModalHost<S>>(null);
    const update = useCallback((state: ModalStateType) => {
      if (ref.current) {
        ref.current.setState(state as ModalStateType<S['id'], S['params']>);
      }
    }, []);

    return (
      <ModalContext.Provider value={update}>
        <ModalHost ref={ref} renderFn={renderFn} />
        {children}
      </ModalContext.Provider>
    );
  };

  function useShowModal() {
    const { show } = useModalManager();

    return (id: S['id'], params?: S['params']) => show(id, params);
  }

  return { Provider, useShowModal };
}
