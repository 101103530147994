import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Autocomplete } from 'components/form-partials/Autocomplete';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { MultiUpdatesAccordion } from 'modules/proxies/MultiUpdatesAccordion';
import { useGetProxyAuthenticationTypeQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { AuthenticationType } from 'store/proxies/types';

import { IPWhitelistForm } from '../IPWhitelistModal/IPWhitelistForm';

export type ChangeAuthMethodFormProps = {
  proxyIds: Array<ProxyModel['id']>;
};

export type ChangeAuthMethodValues = {
  type: AuthenticationType;
  ipWhitelist: string[];
};

export function ChangeAuthMethodForm({ proxyIds }: ChangeAuthMethodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { values, handleSubmit, isSubmitting, isValid } = useFormikContext<ChangeAuthMethodValues>();

  const { data: authTypeResponse, isLoading: isAuthTypeLoading } = useGetProxyAuthenticationTypeQuery(proxyIds[0]);

  const options = useMemo(
    () =>
      authTypeResponse?.availableAuthenticationTypes.map((type) => ({
        value: type,
        label: t(`common:form.${type}`),
      })) ?? [],
    [authTypeResponse, t],
  );

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.change')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeAuthMethod.title')}
      subtitle={t('proxies.modals.changeAuthMethod.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <Box>
          <Autocomplete disableClearable options={options} name="type" />
          {values.type === AuthenticationType.USERNAME_PASSWORD && !isAuthTypeLoading && (
            <Typography variant="caption">{t('common:form.helpers.credentials')}</Typography>
          )}
        </Box>

        {values.type === AuthenticationType.IP_WHITELIST && !isAuthTypeLoading && (
          <IPWhitelistForm proxyIds={proxyIds} />
        )}
      </Stack>
    </ModalContainer>
  );
}
