import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

export function useUplinkSpeedFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (uplinkSpeed: number) => {
      if (uplinkSpeed === 100) return t('common:form.defaultMbit', { amount: uplinkSpeed });

      if (uplinkSpeed < 1024) return t('common:form.mbit', { amount: uplinkSpeed });

      return t('common:form.gbit', { amount: +(uplinkSpeed / 1024).toFixed(1) });
    },
    [t],
  );
}
