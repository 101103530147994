import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useAuthParamsGuard } from 'hooks/useAuthParamsGuard';
import { useNprogress } from 'hooks/useNProgress';
import { useRouter } from 'hooks/useRouter';
import { paths } from 'paths';
import { useAppSelector } from 'store';

type GuestGuardProps = {
  children: ReactNode;
};

export function GuestGuard({ children }: GuestGuardProps) {
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  const { isAuthenticated, isMFACompleted } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();

  useNprogress();
  useAuthParamsGuard();

  const check = useCallback(() => {
    if (isAuthenticated) {
      setChecked(true);

      return router.replace(paths.index);
    }

    if (isMFACompleted != null && !isMFACompleted) {
      setChecked(true);

      return router.replace(paths.auth.mfa);
    }

    if (pathname.includes(paths.auth.mfa)) {
      setChecked(true);

      return router.replace(paths.auth.signIn);
    }

    setChecked(true);
  }, [isAuthenticated, isMFACompleted, pathname, router]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) return null;

  return <>{children}</>;
}
