import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import axios, { type AxiosRequestConfig, isAxiosError } from 'axios';
import { camelizeKeys } from 'humps';
import isObject from 'lodash/isObject';

import { store } from 'store';
import { isTokenExpired, isTokenValid, parseJWT } from 'utils/jwt';
import { PersistService } from 'utils/persist';

import type { TokenDTO } from './auth/dtos';
import { toTokenModel } from './auth/helpers';
import { onSignOut } from './auth/reducers';

// Array of endpoints that are not included to camelizeKeys
const excludedEndpoints = ['order/configuration', 'features'];

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  transformResponse: [
    ...(axios.defaults.transformResponse as []),
    function camelize<T>(data: T) {
      if (data instanceof Blob) {
        return data;
      }

      // return data if endpoint is excluded
      // @ts-expect-error - this is a proper way to access the url
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (excludedEndpoints.includes(this.url || '')) {
        return data;
      }

      return camelizeKeys(data);
    },
  ],
  withCredentials: true,
});

type AxiosBaseQueryParams = Omit<
  AxiosRequestConfig,
  'url' | 'method' | 'data' | 'params' | 'headers' | 'maxRedirects'
> & {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
  maxRedirects?: AxiosRequestConfig['maxRedirects'];
  isRefresh?: boolean;
};

export const tags = [
  // Account related tags
  'Account',
  'Account-Balance',
  'Account-Details',
  'Account-Communication-Preferences',
  'Account-2FA-Methods-Statuses',
  'Account-Card-Details',
  'Account-Billing-Preferences',

  'API-Key',

  // App
  'Maintenance-Windows',

  // Session
  'Session',

  // Proxy actions
  'Extend-Bandwidth-Price',
  'Extend-Period-Price',
  'Reactivate-Proxy-Price',
  'Upgrade-Bandwidth-Speed-Price',
  'Change-Bandwidth-Speed-Options',
  'Upgrade-Threads-Price',
  'Change-Threads-Options',

  // Proxy order
  'Order-Configuration',

  // Proxy lists
  'Proxy',
  'Proxy-ISP',
  'Proxy-Summary',
  'Proxy-IPWhitelist',
  'Proxy-Protocol',
  'Proxy-Global-IP-Whitelist',
  'Proxy-Auth-Type',

  'Proxy-Admin-Events',
  'Proxy-Admin-Change-History',
  'Proxy-Admin-Details',

  // Proxy routes
  'Proxy-Admin-Routes',

  // Referrals
  'Referrals-Details',
  'Account-Bank-Details',

  // VPNs
  'VPN',
  'VPN-Locations',
  'VPN-Ports',
  'VPN-Admin-Events',
  'VPN-Admin-Change-History',
  'VPN-Admin-Details',
] as const;

export type ValidationError = {
  message?: string;
  error?: string;
  stripeError?: string;
  errors?: Record<string, Array<{ code: string; parameters: Record<string, string> }>>;
};

export type AxiosBaseQueryError = {
  message: string;
  errors: string | string[] | Record<string, string[]> | ValidationError['errors'];
  status: number;
  stripeError?: string;
};

export function isAxiosBaseQueryError(error: unknown): error is AxiosBaseQueryError {
  return isObject(error) && 'status' in error && 'errors' in error;
}

export type AxiosBaseQueryFn = BaseQueryFn<AxiosBaseQueryParams, unknown, AxiosBaseQueryError>;

export type TagType = (typeof tags)[number];

export type AppEndpointBuilder = EndpointBuilder<AxiosBaseQueryFn, TagType, 'api'>;

const mutex = new Mutex();

const axiosBaseQuery: AxiosBaseQueryFn = async ({
  url,
  method,
  headers,
  maxRedirects,
  isRefresh = false,
  ...config
}) => {
  const { accessToken, impersonatedEmail } = PersistService.getCredentials();

  try {
    const result = await apiInstance.request({
      ...config,
      url,
      method,
      maxRedirects,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        ...headers,
        ...(accessToken && !isRefresh ? { Authorization: `Bearer ${accessToken}` } : undefined),
        ...(impersonatedEmail && { 'X-Switch-User': impersonatedEmail }),
      },
    });

    return { data: result.data };
  } catch (error) {
    if (!isAxiosError(error)) throw error;

    if (error.response?.status === 403 && impersonatedEmail) {
      PersistService.remove('X-Switch-User');
      window.location.reload();
    }

    const errors = error.response?.data.errors as ValidationError['errors'];

    return {
      error: {
        message: error.response?.data.message ?? error.response?.data.error ?? error.code,
        status: Number(error.response?.status ?? error.status),
        errors,
        stripeError: error.response?.data.stripeError,
      },
    };
  }
};

const axiosBaseQueryWithReAuth: AxiosBaseQueryFn = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  const { accessToken, refreshToken } = PersistService.getCredentials();

  let result: QueryReturnValue<unknown, AxiosBaseQueryError, object> = { data: null };

  if (!accessToken || !refreshToken) {
    // Send a request to unprotected routes
    result = await axiosBaseQuery(args, api, extraOptions);
  } else if (!isTokenValid(accessToken)) {
    result = { error: { message: 'TOKEN_MALFORMED', status: 401, errors: [] } };
    store.dispatch(onSignOut());
  } else if (!isTokenExpired(accessToken) && mutex.isLocked()) {
    // Wait to unlock requests because of token refresh
    await mutex.waitForUnlock();

    result = await axiosBaseQuery(args, api, extraOptions);
  } else if (!isTokenExpired(accessToken)) {
    // Send a request to protected routes
    result = await axiosBaseQuery(args, api, extraOptions);
  } else {
    // Token is expired and requests are blocked
    const release = await mutex.acquire();

    try {
      const refreshResult = await axiosBaseQuery(
        {
          ...args,
          method: 'POST',
          url: '/token/refresh',
          data: { refresh_token: refreshToken },
          isRefresh: true,
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const credentials = toTokenModel(refreshResult.data as TokenDTO);

        PersistService.setCredentials(credentials);

        // Save new exp for the token
        const payload = parseJWT(credentials.accessToken);

        PersistService.set('token-expiry-time', payload ? payload.exp : null);

        result = await axiosBaseQuery(args, api, extraOptions);
      } else {
        result = await axiosBaseQuery({ ...args, method: 'POST', url: '/logout' }, api, extraOptions);
        store.dispatch(onSignOut());
      }
    } finally {
      release();
    }
  }

  return result;
};

export const base = createApi({
  baseQuery: axiosBaseQueryWithReAuth,
  tagTypes: tags,
  endpoints: () => ({}),
});

export type BaseApi = typeof base;
