import { PAYMENT_FEES } from 'constants/paymentFee';
import { toPaymentOption } from 'modules/billing/helpers';
import type { TopUpPaymentOptionType } from 'modules/billing/types';
import type { CreditCardModel } from 'store/accounts/models';

import type { TopUpOption } from './types';

/**
 * Builds top up option object
 *
 * @throws {Error} once the type is invalid
 * @param {TopUpPaymentOptionType} type
 * @returns {TopUpOption}
 */
function buildBaseTopUpOption(type: TopUpPaymentOptionType): TopUpOption {
  const paymentFee = PAYMENT_FEES[toPaymentOption(type)];

  if (!paymentFee) throw new Error(`Payment fee cannot be created for ${type}`);

  return { paymentFee, type };
}

export function buildTopUpPaymentOptions(creditCard?: CreditCardModel): TopUpOption[] {
  const baseOptions: TopUpOption[] = [
    buildBaseTopUpOption('paypal'),
    buildBaseTopUpOption('alipay'),
    buildBaseTopUpOption('crypto'),
    buildBaseTopUpOption('giropay'),
    buildBaseTopUpOption('sofort'),
    buildBaseTopUpOption('ideal'),
    buildBaseTopUpOption('blik'),
    buildBaseTopUpOption('p24'),
  ];

  if (!creditCard) {
    return [buildBaseTopUpOption('new-card'), ...baseOptions];
  }

  return [buildBaseTopUpOption('existing-card'), buildBaseTopUpOption('another-card'), ...baseOptions];
}
