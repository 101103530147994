import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { TopUpButton } from 'modules/billing/TopUpButton';
import { useGetUserAccountBalanceQuery } from 'store/api';
import { CurrencyFormatter } from 'utils/currency';

import Styled from './styled';

type BalanceSummaryProps = {
  price?: number;
  isLoading?: boolean;
};

export function BalanceSummary({ price = 0, isLoading }: BalanceSummaryProps) {
  const { t } = useTranslation();
  const { data, isLoading: isBalanceLoading } = useGetUserAccountBalanceQuery();

  const balance = useMemo(() => data?.balance || 0, [data]);

  return (
    <Styled.TopUpButtonContainer>
      <Box display="flex">
        <Typography variant="body-2" color="gray">
          {t('common:paymentMethods.balance')}{' '}
        </Typography>
        <TopUpButton variant="text" label={t('common:buttons.topUp')} />
      </Box>
      {isLoading || isBalanceLoading ? (
        <Skeleton height={32} width={100} />
      ) : (
        <Typography variant="body-2" color={price > balance ? 'error' : undefined}>
          {CurrencyFormatter.format(balance)}
        </Typography>
      )}
    </Styled.TopUpButtonContainer>
  );
}
