import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useReactivateProxyMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { ToastManager } from 'utils/toast';

import { ReactivateProxyForm, type ReactivateProxyFormProps } from './ReactivateProxyForm';

export type ReactivateProxyModalProps = ReactivateProxyFormProps;

export function ReactivateProxyModal({ proxyId }: ReactivateProxyModalProps) {
  const { t } = useTranslation();
  const [reactivateProxy] = useReactivateProxyMutation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  return (
    <Formik
      initialValues={{
        periodInMonths: 1,
      }}
      onSubmit={async ({ periodInMonths }) => {
        try {
          await reactivateProxy({ proxyId, periodInMonths }).unwrap();

          posthog?.capture(posthogEvents.proxyReactivated);

          ToastManager.success(t('proxies.modals.reactivate.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('proxies.modals.extendPeriod.failure'));
          }
        }
      }}
    >
      <ReactivateProxyForm proxyId={proxyId} />
    </Formik>
  );
}
