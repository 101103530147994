import { styled, alpha } from '@mui/material/styles';

import { Card, type CardProps } from 'components/Card';

type ContainerProps = CardProps & {
  isFailed?: boolean;
};

const Container = styled(({ isFailed, ...props }: ContainerProps) => <Card {...props} />)(({ isFailed, theme }) => ({
  width: 'auto',
  backgroundColor: isFailed ? alpha(theme.palette.error.main, 0.85) : theme.palette.warning.main,
}));

export default { Container };
