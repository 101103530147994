import { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { Outlet } from 'react-router-dom';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useAppSelector } from 'store';
import { useGetCreditCardDetailsQuery } from 'store/api';

import { OrderLayoutAppBar } from './OrderLayoutAppBar';
import Styled from './styled';
import { useOrderFlow } from '../hooks/useOrderFlow';
import { useSelectedOrderTypeGuard } from '../hooks/useSelectedOrderTypeGuard';
import { OrderDisabled } from '../OrderDisabled';

export function OrderLayout() {
  const { isOrderingEnabled, isFeatureFlagsLoading } = useFeatureFlag();
  const { isLoading: isCardLoading } = useGetCreditCardDetailsQuery();
  const { testVariant } = useOrderFlow();
  const { currentStep } = useAppSelector((state) => state.order);

  useSelectedOrderTypeGuard();

  const content = useMemo(() => {
    if (isFeatureFlagsLoading || isCardLoading) {
      return (
        <Styled.OrderLayoutLoadingContainer>
          <CircularProgress size={64} />
        </Styled.OrderLayoutLoadingContainer>
      );
    }

    if (!isOrderingEnabled) {
      return <OrderDisabled />;
    }

    return <Outlet />;
  }, [isCardLoading, isFeatureFlagsLoading, isOrderingEnabled]);

  return (
    <Styled.LayoutRoot>
      <OrderLayoutAppBar />

      <Styled.OrderLayoutContainer
        isOrderingEnabled={isOrderingEnabled}
        hasOffset={!!currentStep && currentStep !== 'vpn-plan'}
        testVariant={testVariant}
      >
        {content}
      </Styled.OrderLayoutContainer>
    </Styled.LayoutRoot>
  );
}
