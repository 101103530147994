import { type ComponentPropsWithoutRef, type CSSProperties, type ElementType, type ReactNode, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Modal, { type ModalProps } from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import Styled from './styled';
import { useHideModal } from '../context';

type ModalContainerProps<C extends ElementType = 'div'> = Omit<ModalProps, 'title' | 'open' | 'children'> & {
  component?: C;
  containerProps?: ComponentPropsWithoutRef<C>;
  title?: ReactNode;
  subtitle?: ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
  children?: ReactNode;
  actions?: JSX.Element[];
  footer?: JSX.Element;
  scrollable?: boolean;
};

export function ModalContainer<C extends ElementType = 'div'>({
  title,
  subtitle,
  children,
  actions,
  footer,
  scrollable = false,
  maxWidth = 420,
  component,
  containerProps,
  ...props
}: ModalContainerProps<C>) {
  const hideModal = useHideModal();

  const ContainerComponent = component || 'div';

  const gridTemplateAreas = useMemo(() => {
    const areas: string[] = [];

    if (title || subtitle) {
      areas.push('"header"');
    }

    if (children) {
      areas.push('"content"');
    }

    if (actions || footer) {
      areas.push('"footer"');
    }

    return areas.join(' ');
  }, [actions, children, footer, subtitle, title]);

  return (
    <Modal open onClose={hideModal} {...props}>
      <Styled.ModalContainer
        component={ContainerComponent}
        maxWidth={maxWidth}
        gridTemplateAreas={gridTemplateAreas}
        contentScrollable={scrollable}
        {...containerProps}
      >
        {(title || subtitle) && (
          <Styled.ModalHeader>
            {title && (
              <Typography textAlign="center" variant="headline-1" component="p">
                {title}
              </Typography>
            )}

            {subtitle && (
              <Typography textAlign="center" variant="body-1" component="p" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </Styled.ModalHeader>
        )}

        {children && <Styled.ModalContent scrollable={scrollable}>{children}</Styled.ModalContent>}

        {(actions || footer) && (
          <Styled.ModalFooter>
            <Grid container spacing={2}>
              {actions?.map((actionItem, index) => (
                <Grid item xs={12 / actions.length} key={index}>
                  {actionItem}
                </Grid>
              ))}

              {footer && (
                <Grid item xs={12}>
                  {footer}
                </Grid>
              )}
            </Grid>
          </Styled.ModalFooter>
        )}
      </Styled.ModalContainer>
    </Modal>
  );
}
