import { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'store';
import type { ProxySetupFormModel } from 'store/orders/models';
import { setCouponCode, setCurrentStep, setServiceSettings } from 'store/orders/reducers';
import { AuthenticationType, type IPVersion, NetworkType, Protocol } from 'store/proxies/types';

const ORDER_STATE_DEFAULTS = {
  ipVersion: null,
  region: null,
  ipWhitelist: [''],
  package: null,
  bandwidth: 5,
  quantity: 1,
  timePeriod: '1m',
  isAutoExtendEnabled: false,
  autoExtendBandwidth: 10,
  isUnusedProxy: false,
  lastUpdatedKey: null,
};

export function useInitOrderStateFromParams() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const parsedNextworkType = (searchParams.get('networkType') as NetworkType) || undefined;
    const parsedTimePeriod = searchParams.get('timePeriod') || undefined;
    const parsedIsUnusedProxy = searchParams.get('isUnusedProxy') || undefined;
    const parsedBandwidth = searchParams.get('bandwidth') || undefined;
    const parsedPackage = searchParams.get('package') || undefined;
    const parsedIpVersion = searchParams.get('ipVersion') || undefined;
    const parsedCountry = searchParams.get('country') || undefined;
    const parsedCouponCode = searchParams.get('couponCode') || undefined;

    if (parsedCouponCode) {
      dispatch(setCouponCode(parsedCouponCode));
    }

    if (parsedNextworkType) {
      const serviceSettings: Partial<ProxySetupFormModel> = {};

      // all further updates only applicable when network type is provided
      if (parsedTimePeriod) {
        serviceSettings.timePeriod = parsedTimePeriod;
      }

      if (parsedIsUnusedProxy) {
        serviceSettings.isUnusedProxy = parsedIsUnusedProxy === 'true';
      }

      if (parsedBandwidth) {
        serviceSettings.bandwidth = parseInt(parsedBandwidth, 10);
      }

      if (parsedPackage) {
        serviceSettings.package = parseInt(parsedPackage, 10);
      }

      if (parsedIpVersion) {
        serviceSettings.ipVersion = parsedIpVersion as IPVersion;
      }

      if (parsedCountry) {
        serviceSettings.country = parsedCountry;
      }

      dispatch(
        setServiceSettings(
          isEmpty(serviceSettings)
            ? null
            : {
                networkType: parsedNextworkType,
                proxyProtocol: parsedNextworkType === NetworkType.Mobile ? Protocol.HTTPS : Protocol.HTTP,
                authenticationType:
                  parsedNextworkType === NetworkType.Mobile
                    ? AuthenticationType.IP_WHITELIST
                    : AuthenticationType.USERNAME_PASSWORD,
                couponCode: parsedCouponCode || null,
                ...ORDER_STATE_DEFAULTS,
                ...serviceSettings,
              },
        ),
      );

      dispatch(setCurrentStep('proxy-setup'));
    }

    setInitialized(true);
  }, [searchParams, dispatch]);

  return {
    initialized,
  };
}
