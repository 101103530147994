import { useCallback, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'translations';
import 'utils/yup';

import { useListenFreshchatClose } from 'components/Freshchat/hooks/useListenFreshchatClose';
import { useFreshChat } from 'hooks/useFreshChat';
import { useLoadTranslations } from 'hooks/useLoadTranslations';
import { SupportedLanguage } from 'modules/common/Language/types';
import { router } from 'routes';
import { useAppSelector } from 'store';
import { toLanguagePayload } from 'store/accounts/helpers';
import { useChangeLanguageMutation, useGetUserSessionQuery } from 'store/api';
import type { ITheme } from 'theme';
import { PersistService } from 'utils/persist';

export function App() {
  const posthog = usePostHog();
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));
  const listenFreshchatClose = useListenFreshchatClose();

  const toggleWidget = useCallback(() => {
    window.fcWidget?.setConfig({
      headerProperty: { hideChatButton: isMobileDevice },
    });
    listenFreshchatClose();
  }, [isMobileDevice, listenFreshchatClose]);

  const FreshChat = useFreshChat(toggleWidget);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { i18n } = useTranslation();

  const { data: session } = useGetUserSessionQuery(undefined, { skip: !isAuthenticated });

  const [changeLanguage] = useChangeLanguageMutation();

  useLoadTranslations();

  const updateLanguage = useCallback(() => {
    if (!session || !isAuthenticated) return;

    // No need to update language when it's already set
    if (session.language) return;

    try {
      changeLanguage({ language: toLanguagePayload(i18n.language as SupportedLanguage) });
    } catch {
      // noop
    }
  }, [changeLanguage, i18n.language, isAuthenticated, session]);

  useEffect(() => {
    toggleWidget();
  }, [toggleWidget]);

  useEffect(() => {
    if (!session?.userId) return;

    posthog?.identify(session.userId.toString());
  }, [posthog, session]);

  useEffect(() => {
    if (!session) return;

    i18n.changeLanguage(session.language ?? i18n.language ?? PersistService.get('language') ?? SupportedLanguage.EN);
  }, [i18n, session]);

  useEffect(() => {
    updateLanguage();
  }, [updateLanguage]);

  return (
    <>
      <RouterProvider router={router} />
      {FreshChat}

      <ToastContainer
        draggable={false}
        position={toast.POSITION.BOTTOM_CENTER}
        closeButton={false}
        closeOnClick={false}
        icon={false}
        limit={3}
      />
    </>
  );
}
