import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const MultiProxyAccordion = styled(Accordion)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '&.Mui-expanded': {
    margin: 0,
  },

  '& .MuiAccordionSummary-root': {
    padding: 0,
    minHeight: 'auto !important',
    width: '100%',
  },

  '& .MuiAccordionDetails-root': {
    padding: `${theme.spacing(0.5)} 0 0`,
  },

  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,

    '& .MuiButtonBase-root': {
      justifyContent: 'space-between',
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(1),

    svg: {
      color: theme.palette.grey['500'],
    },
  },
}));

export default { MultiProxyAccordion };
