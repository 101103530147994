import { PaymentOption } from 'modules/billing/types';

export const PAYMENT_FEES: { [K in PaymentOption]: string | null } = {
  [PaymentOption.WALLET]: null,
  [PaymentOption.ALIPAY]: '(2.9% + $0.3)',
  [PaymentOption.CRYPTO]: '(1%)',
  [PaymentOption.STRIPE]: '(2.9% + $0.3)',
  [PaymentOption.PAYPAL]: '(3.49% + $0.49)',
  [PaymentOption.GIROPAY]: '(1.4% + $0.3)',
  [PaymentOption.SOFORT]: '(2.9% + $0.3)',
  [PaymentOption.IDEAL]: '(35¢)',
  [PaymentOption.BLIK]: '(1.6% + $0.3)',
  [PaymentOption.P24]: '(2.2% + $0.3)',
};
