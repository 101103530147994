import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,

  width: '100%',
  height: '100%',
});

export default { Container };
