import { useState } from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

import { Tabs, useSyncTabs } from 'components/Tabs';
import { paths } from 'paths';

import Styled from './styled';

const NAV_ITEMS = [
  { label: 'account.general.title', path: paths.account.index },
  { label: 'account.notifications.title', path: paths.account.notifications },
  { label: 'account.security.title', path: paths.account.security },
  { label: 'account.idVerification.title', path: paths.account.identityVerification },
  { label: 'account.payments.title', path: paths.account.payments },
];

export function AccountLayout() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  useSyncTabs({ items: NAV_ITEMS.map(({ path }) => path), callback: setValue });

  return (
    <Styled.Container direction="column" spacing={4}>
      <Grid item>
        <Tabs
          value={value}
          onChange={(_, newValue: number) => setValue(newValue)}
          items={NAV_ITEMS}
          renderItem={(Component, item, key) => (
            <Component key={key} component={Link} to={item.path} label={t(item.label)} />
          )}
          keyExtractor={({ label }) => label}
          variant="scrollable"
          scrollButtons="auto"
        />
      </Grid>

      <Grid item>
        <Outlet />
      </Grid>
    </Styled.Container>
  );
}
