import Stack from '@mui/material/Stack';

import { Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Input } from 'components/form-partials/Input';
import { useAdminModal } from 'hooks/useAdminModal';

export function SetupForm() {
  const { t } = useTranslation();

  const { onClose } = useAdminModal();

  return (
    <Form noValidate>
      <Stack spacing={4}>
        <Input fullWidth name="url" placeholder={t('common:form.optional', { field: t('common:form.url') })} />

        <Stack direction="row" spacing={2}>
          <Button fullWidth color="secondary" onClick={onClose}>
            {t('common:buttons.cancel')}
          </Button>

          <Button fullWidth type="submit">
            {t('common:buttons.run')}
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
