import { useEffect, useMemo, useRef } from 'react';

import { useGetOrderQuery } from 'store/api';
import { OrderStatus } from 'store/orders/types';

export function useGetOrder(orderId: string) {
  const skip = useRef(false);
  const query = useGetOrderQuery(orderId, { pollingInterval: 5_000, skip: skip.current });

  useEffect(() => {
    if (!query.data) return;

    if (![OrderStatus.FAILED, OrderStatus.FULFILLED].includes(query.data.status)) return;

    skip.current = true;
  }, [query.data]);

  return useMemo(() => query, [query]);
}
