import { type TOptionsBase, t } from 'i18next';
import isBic from 'validator/lib/isBIC';
import * as Yup from 'yup';

import { createTranslationLink } from 'translations/helpers';
import { CurrencyFormatter } from 'utils/currency';

import { truncateArrayPath } from '../stringHelpers';

const COMMON_RULES = ['required', 'min', 'max', 'email', 'matches', 'oneOf'];

const rules: Record<string, typeof COMMON_RULES> = {
  mixed: COMMON_RULES,
  string: COMMON_RULES,
  number: COMMON_RULES,
  date: COMMON_RULES,
  boolean: COMMON_RULES,
  object: COMMON_RULES,
  array: COMMON_RULES,
};

function getErrorKey(rule: string, type: string, path?: string) {
  if (rule === 'min' || rule === 'max') {
    return `errors:${rule}.${type}`;
  }

  // ! Workaround for the new Password Confirmation validation case - probably will need some adjustments in the future
  if (rule === 'oneOf' && path != null) {
    return `errors:${rule}.${path}`;
  }

  return `errors:${rule}`;
}

Yup.setLocale(
  Object.fromEntries(
    Object.keys(rules).map((type) => [
      type,
      Object.fromEntries(
        rules[type].map((rule) => [
          rule,
          (params: TOptionsBase & { path: string }) =>
            t(getErrorKey(rule, type, params.path), {
              ...params,
              field: t(`common:form.${truncateArrayPath(params.path)}`, { interpolation: { escapeValue: false } }),
              interpolation: { escapeValue: false },
            }),
        ]),
      ),
    ]),
  ),
);

// Yup custom methods
Yup.addMethod(Yup.string, 'bic', function validationMethod() {
  return this.test(
    'BIC is valid',
    createTranslationLink('errors:invalid', { field: t('common:form.bic') }),
    function validate(value = '') {
      return isBic(value);
    },
  );
});

Yup.addMethod(Yup.number, 'minimumTopUpValue', function validationMethod() {
  return this.test(
    'Minimum Top-Up value',
    createTranslationLink('order.modals.topUp.amountValidation', { amount: CurrencyFormatter.format(5) }),
    function validate(value = 5) {
      return value >= 5;
    },
  );
});
