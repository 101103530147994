import axios, { type AxiosInstance } from 'axios';
import i18n from 'i18next';
import clone from 'lodash/cloneDeep';
import { stringify } from 'qs';

import type { SupportedLanguage } from 'modules/common/Language/types';

import * as Helpers from './helpers.mjs';
import type { ILanguageContextValue, ILanguageResource, IPoeResponseItem, IPoeTranslation } from './types';

type PoeLoaderSubscriberType = () => void;

// Client side resource loader that pulls live translations from POEditor
export default class PoeLoader {
  private readonly http: AxiosInstance;

  private status: 'idle' | 'loading' | 'loaded' = 'idle';

  private readonly subscribers: PoeLoaderSubscriberType[] = [];

  constructor(
    private readonly languageCodes: SupportedLanguage[],
    private readonly defaultResources: Record<string, ILanguageResource>,
  ) {
    if (!process.env.REACT_APP_API_GATEWAY) {
      throw new Error('Missing REACT_APP_API_GATEWAY config');
    }

    this.http = axios.create({ baseURL: process.env.REACT_APP_API_GATEWAY });
  }

  private async getTranslations(languageCode: SupportedLanguage): Promise<Record<string, IPoeTranslation[]>> {
    try {
      const response = await this.http.get<{ terms: IPoeResponseItem[] }>(
        `poe?${stringify({
          api_token: process.env.REACT_APP_POE_READ_TOKEN,
          project_id: process.env.REACT_APP_POE_PROJECT_ID,
          language: Helpers.toPoeditorLanguage(languageCode),
        })}`,
      );

      return response.data.terms.reduce<Record<string, IPoeTranslation[]>>((memo, { context, term, translation }) => {
        const { actualContext, namespace } = Helpers.getNamespaceFromContext(context);

        if (!(namespace in memo)) {
          memo[namespace] = [];
        }

        memo[namespace].push({ key: term, context: actualContext, content: translation.content });

        return memo;
      }, {});
    } catch {
      return {};
    }
  }

  private async getResources() {
    const translations = await Promise.all(this.languageCodes.map((code) => this.getTranslations(code)));

    return this.languageCodes.reduce<Record<string, ILanguageResource>>((acc, code, index) => {
      acc[code] = Object.keys(translations[index]).reduce<ILanguageResource>((memo, namespace) => {
        memo[namespace] = Helpers.mapTranslationsToResource(
          translations[index][namespace],
          clone(this.defaultResources[code][namespace]),
        ) as ILanguageContextValue;

        return memo;
      }, {});

      return acc;
    }, {});
  }

  subscribe(subscriber: PoeLoaderSubscriberType): () => void {
    this.subscribers.push(subscriber);

    if (this.status === 'idle') {
      this.status = 'loading';
      this.getResources().then((resources) => {
        Object.keys(resources).forEach((code) => {
          Object.keys(resources[code]).forEach((namespace) => {
            i18n.addResourceBundle(code, namespace, resources[code][namespace], true, true);
          });
        });

        this.status = 'loaded';
        this.subscribers.forEach((sub) => {
          sub();
        });
      });
    }

    return () => {
      const index = this.subscribers.indexOf(subscriber);

      if (index !== -1) {
        this.subscribers.splice(index, 1);
      }
    };
  }
}
