import { paths } from 'paths';
import type { OrderStep } from 'store/orders/types';

export const ORDER_PATH_TO_STEP_MAP: Record<string, OrderStep> = {
  [paths.order.vpn]: 'vpn-plan',
  [paths.order.proxy]: 'proxy-setup',
  [paths.order.details]: 'personal-details',
  [paths.order.checkout]: 'checkout',
};

export const ORDER_STEP_TO_PATH_MAP: Record<OrderStep, string> = {
  'vpn-plan': paths.order.vpn,
  'proxy-setup': paths.order.proxy,
  'personal-details': paths.order.details,
  checkout: paths.order.checkout,
};

export function getOrderStep(path: string): OrderStep | null {
  const currentStep = ORDER_PATH_TO_STEP_MAP[path];

  return currentStep ?? null;
}

export function getOrderPath(step: OrderStep | null): string {
  if (!step) return paths.order.index;

  return ORDER_STEP_TO_PATH_MAP[step];
}
