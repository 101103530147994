import { useCallback, useMemo } from 'react';

import type { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Flag } from 'components/Flag';
import { useAppSelector } from 'store';
import { toLanguagePayload } from 'store/accounts/helpers';
import { useChangeLanguageMutation } from 'store/api';
import type { Option } from 'types';

import Styled, { type ContainerProps } from './styled';
import { SupportedLanguage } from './types';

export type LanguageDropdownProps = ContainerProps & {
  iconOnly?: boolean;
};

export function LanguageDropdown({ iconOnly = false, floating = false }: LanguageDropdownProps) {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, accessToken } = useAppSelector((state) => state.auth);
  const [changeLanguage] = useChangeLanguageMutation();

  const getFlagForLanguage = useCallback((language: SupportedLanguage) => {
    if (language === SupportedLanguage.CN) return 'CN';

    if (language === SupportedLanguage.VI) return 'VN';

    if (language === SupportedLanguage.ES) return 'ES';

    if (language === SupportedLanguage.RU) return 'RU';

    return 'US';
  }, []);

  const getLanguageValue = useCallback((language: string) => {
    if (language.includes('en-') || language.includes('en_')) return 'en';

    return language;
  }, []);

  const options = useMemo<Option[]>(
    () =>
      Object.entries(SupportedLanguage).map(([key, value]) => ({
        label: t(`common:languages.${key}`),
        value,
      })),
    [t],
  );

  const handleChangeLanguage = useCallback(
    (e: SelectChangeEvent) => {
      try {
        const lng = e.target.value as SupportedLanguage;

        i18n.changeLanguage(lng);

        if (isAuthenticated && accessToken) {
          changeLanguage({ language: toLanguagePayload(lng) });
        }
      } catch {
        // noop
      }
    },
    [accessToken, changeLanguage, i18n, isAuthenticated],
  );

  return (
    <Styled.Container floating={floating}>
      <Styled.Select
        fullWidth
        size="small"
        value={getLanguageValue(i18n.language)}
        iconOnly={iconOnly}
        onChange={handleChangeLanguage}
      >
        {options.map(({ label, value }) => (
          <Styled.MenuItem
            disableRipple
            iconOnly={iconOnly}
            key={typeof value !== 'object' ? value : undefined}
            value={typeof value !== 'object' ? value : undefined}
          >
            <Flag size={16} country={getFlagForLanguage(value as SupportedLanguage)} />

            {!iconOnly && <Typography variant="body-2">{label}</Typography>}
          </Styled.MenuItem>
        ))}
      </Styled.Select>
    </Styled.Container>
  );
}
