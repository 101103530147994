import type { Shadows, Theme, ThemeOptions } from '@mui/material/styles';

import { createComponents } from './components';
import { themePalette } from './palette';
import { themeTypography } from './typography';

export type ExtendedTheme = {
  appBar: { height: number };
};

interface IThemeOptions extends ThemeOptions, ExtendedTheme {}

export const theme: IThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 577,
      md: 769,
      lg: 976,
      xl: 1201,
    },
  },
  shadows: Array(25).fill('none') as Shadows,
  components: createComponents(themePalette as Theme['palette']),
  palette: themePalette,
  typography: themeTypography,
  appBar: {
    height: 64,
  },
};
