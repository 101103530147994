import { forwardRef } from 'react';

import Button, { loadingButtonClasses, type LoadingButtonProps } from '@mui/lab/LoadingButton';
import { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { getButtonDisabledColors } from 'theme/utils/disabled';

const MuiButton = styled(
  forwardRef<
    HTMLButtonElement,
    LoadingButtonProps & {
      $isLarge?: boolean;
      maxWidth?: number;
      $hasBothIcons?: boolean;
    }
  >(({ $isLarge, $hasBothIcons, maxWidth, ...props }, ref) => <Button ref={ref} {...props} />),
)(({ color, loading, theme, maxWidth, $isLarge = false, $hasBothIcons = false }) => ({
  [`&.${buttonClasses.disabled}`]: getButtonDisabledColors({ color, theme, loading }),
  [`& .${loadingButtonClasses.loadingIndicator}, &.${buttonClasses.containedError}`]: {
    color: theme.palette.common.white,
  },
  '&.MuiButton-sizeMedium.MuiButton-text': {
    fontWeight: '400',
  },
  ...(maxWidth && { maxWidth }),
  ...($hasBothIcons
    ? {
        '& .MuiButton-startIcon': { marginRight: $isLarge ? theme.spacing(1) : theme.spacing(0.75), marginLeft: 0 },
        '& .MuiButton-endIcon': { marginRight: 0, marginLeft: $isLarge ? theme.spacing(1) : theme.spacing(0.75) },
      }
    : {
        '& .MuiButton-startIcon': { marginRight: 0, marginLeft: 0 },
        '& .MuiButton-endIcon': { marginRight: 0, marginLeft: 0 },
      }),
}));

export default { MuiButton };
