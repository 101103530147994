import { useEffect, useState } from 'react';

export function useListenFreshchatUnreadCount() {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    // freshchat should be initialized by other code, need space to hook on events
    setTimeout(() => {
      window.fcWidget?.on('unreadCount:notify', ({ count }) => {
        setHasUnreadMessages(!!count);
      });
    }, 2000);
  }, []);

  return {
    hasUnreadMessages,
  };
}
