import Box, { type BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const TitleWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
}));

type ChipProps = BoxProps & {
  checked?: boolean;
};

const Chip = styled(({ checked, ...props }: ChipProps) => <Box {...props} />)(({ checked, theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.divider,
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ...(checked && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const SubtitleWrapper = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
}));

export default { Chip, SubtitleWrapper, TitleWrapper };
