import { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import { Icon } from 'components/Icon';
import { AccountMenu } from 'modules/accounts/AccountMenu';

import Styled from './styled';

export function AccountButton() {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <>
      <Styled.AccountButtonWrapper component={ButtonBase} onClick={() => setIsPopoverOpen(true)}>
        <Box ref={anchorRef}>
          <Icon name="profile-circle" iconStroke={(theme) => theme.palette.grey['600']} />
        </Box>
      </Styled.AccountButtonWrapper>

      <AccountMenu anchorEl={anchorRef.current} open={isPopoverOpen} onClose={() => setIsPopoverOpen(false)} />
    </>
  );
}
