import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as ProfileCircleIcon } from 'assets/icons/profile-circle.svg';
import { ReactComponent as RadiusCheckFilledIcon } from 'assets/icons/radius-check-filled.svg';
import { ReactComponent as RadiusCheckIcon } from 'assets/icons/radius-check.svg';

import { arrow } from './arrow';
import { business } from './business';
import { call } from './call';
import { company } from './company';
import { computers } from './computers';
import { content } from './content';
import { design } from './design';
import { email } from './email';
import { essetional } from './essetional';
import { location } from './location';
import { messages } from './messages';
import { money } from './money';
import { navigation } from './navigation';
import { notification } from './notification';
import { programming } from './programming';
import { proxy } from './proxy';
import { search } from './search';
import { security } from './security';
import { settings } from './settings';
import { shop } from './shop';
import { support } from './support';
import { time } from './time';
import { users } from './users';

export const icons = {
  ...arrow,
  ...business,
  ...call,
  ...company,
  ...computers,
  ...content,
  ...design,
  ...email,
  ...essetional,
  ...location,
  ...messages,
  ...money,
  ...notification,
  ...proxy,
  ...search,
  ...security,
  ...settings,
  ...shop,
  ...navigation,
  ...programming,
  ...support,
  ...time,
  ...users,
  'radius-check': RadiusCheckIcon,
  'radius-check-filled': RadiusCheckFilledIcon,
  'profile-circle': ProfileCircleIcon,
  menu: MenuIcon,
  cross: CrossIcon,
};
