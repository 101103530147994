import Grid from '@mui/material/Grid';

import { Outlet } from 'react-router-dom';

import Styled from './styled';

export function ReferralsLayout() {
  return (
    <Styled.Container direction="column" spacing={4}>
      <Grid item>
        <Outlet />
      </Grid>
    </Styled.Container>
  );
}
