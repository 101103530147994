import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const LoaderWrapper = styled(Box)({ position: 'relative' });

const CounterWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  maxHeight: theme.spacing(8),
}));

export default { LoaderWrapper, CounterWrapper };
