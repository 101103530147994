import { useMemo } from 'react';

import { ReactComponent as AnotherCardIcon } from 'assets/icons/payment-methods/another-card.svg';
import { ReactComponent as AliPayIcon } from 'assets/icons/payment-vendors/ali-pay.svg';
import { ReactComponent as AmexIcon } from 'assets/icons/payment-vendors/amex.svg';
import { ReactComponent as BlikIcon } from 'assets/icons/payment-vendors/blik.svg';
import { ReactComponent as CartesIcon } from 'assets/icons/payment-vendors/cartes.svg';
import { ReactComponent as CoinGateIcon } from 'assets/icons/payment-vendors/coingate.svg';
import { ReactComponent as DefaultVendorIcon } from 'assets/icons/payment-vendors/default.svg';
import { ReactComponent as DiscoverIcon } from 'assets/icons/payment-vendors/discover.svg';
import { ReactComponent as GiropayIcon } from 'assets/icons/payment-vendors/giropay.svg';
import { ReactComponent as IdealIcon } from 'assets/icons/payment-vendors/ideal.svg';
import { ReactComponent as JCBIcon } from 'assets/icons/payment-vendors/jcb.svg';
import { ReactComponent as KlarnaIcon } from 'assets/icons/payment-vendors/klarna.svg';
import { ReactComponent as MaestroIcon } from 'assets/icons/payment-vendors/maestro.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/payment-vendors/master-card.svg';
import { ReactComponent as P24Icon } from 'assets/icons/payment-vendors/p24.svg';
import { ReactComponent as PayPalIcon } from 'assets/icons/payment-vendors/pay-pal.svg';
import { ReactComponent as UnionPayIcon } from 'assets/icons/payment-vendors/union-pay.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/payment-vendors/visa.svg';

import Styled from './styled';

export const paymentVendors = {
  'another-card': AnotherCardIcon,
  'ali-pay': AliPayIcon,
  amex: AmexIcon,
  blik: BlikIcon,
  cartes: CartesIcon,
  coingate: CoinGateIcon,
  default: DefaultVendorIcon,
  discover: DiscoverIcon,
  giropay: GiropayIcon,
  ideal: IdealIcon,
  jcb: JCBIcon,
  klarna: KlarnaIcon,
  maestro: MaestroIcon,
  'master-card': MasterCardIcon,
  p24: P24Icon,
  'pay-pal': PayPalIcon,
  'union-pay': UnionPayIcon,
  visa: VisaIcon,
};

export type PaymentVendor = keyof typeof paymentVendors;

type PaymentVendorIconProps = {
  type: PaymentVendor;
  size?: number;
};

export function PaymentVendorIcon({ type, size = 48 }: PaymentVendorIconProps) {
  const Component = useMemo(() => {
    const Icon = paymentVendors[type];

    return Icon ?? DefaultVendorIcon;
  }, [type]);

  return (
    <Styled.IconWrapper size={size}>
      <Component />
    </Styled.IconWrapper>
  );
}
