import { useState, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { PaymentElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { useStripeContext } from 'modules/billing/Stripe';
import { paths } from 'paths';
import { isAxiosBaseQueryError } from 'store/base';
import { CurrencyFormatter } from 'utils/currency';

import { useTopUpModalContext } from '../context';

export function PaymentElementForm() {
  const { error, createPaymentMethod, handlePayment, handleSubmit } = useStripeContext();
  const { fee, isTopUpLoading, values, handleTopUp, setStep } = useTopUpModalContext();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [internalError, setInternalError] = useState<string | null>(null);

  /**
   * Handles the back action for PaymentElement form
   */
  const handleBack = useCallback(() => {
    setStep('top-up-form');
  }, [setStep]);

  /**
   * Handles the form submit start
   */
  const handleStart = useCallback(() => {
    setInternalError(null);
    setIsLoading(true);
  }, []);

  /**
   * Handles the POST /account/billing/top-up request error
   *
   * @param {unknown} error}
   */
  const handleTopUpError = useCallback((topUpError: unknown) => {
    setIsLoading(false);

    if (!isAxiosBaseQueryError(topUpError)) return;

    setInternalError(topUpError.stripeError ?? null);
  }, []);

  /**
   * Handles the <PaymentElement /> submit success
   */
  const handleSubmitSuccess = useCallback(async () => {
    if (!values) return;

    const paymentMethod = await createPaymentMethod(values.type);

    if (!paymentMethod) {
      return setIsLoading(false);
    }

    return handleTopUp({
      values,
      paymentMethodId: paymentMethod.id,
      onError: handleTopUpError,
      onSuccess: ({ data }) =>
        handlePayment(data, {
          onFailure: () => setIsLoading(false),
          onSuccess: () => setStep('status'),
        }),
    });
  }, [createPaymentMethod, handlePayment, handleTopUpError, handleTopUp, setStep, values]);

  return (
    <form
      onSubmit={(event) =>
        handleSubmit({
          event,
          onStart: handleStart,
          onFailure: () => setIsLoading(false),
          onSuccess: handleSubmitSuccess,
        })
      }
    >
      <Grid container direction="column" rowSpacing={4}>
        {fee && (
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item component={Typography} variant="title">
                {t('common:topUpAmount')}
              </Grid>

              <Grid item component={Typography} variant="body-1" color="grey.500">
                {CurrencyFormatter.format(fee.finalPrice)}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item>
          <Grid container direction="column" rowGap={4}>
            <Grid item>
              <PaymentElement onReady={() => setIsFormReady(true)} />
            </Grid>

            {(error || internalError) && (
              <Grid item component={Typography} variant="body-2" color="error.main">
                {internalError || error?.message}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container columnSpacing={2}>
            <Grid xs={6} item>
              <Button fullWidth color="secondary" disabled={isLoading || isTopUpLoading} onClick={handleBack}>
                {t('common:back')}
              </Button>
            </Grid>

            <Grid xs={6} item>
              <Button fullWidth type="submit" disabled={!isFormReady} loading={isLoading || isTopUpLoading}>
                {t('common:buttons.continue')}
              </Button>
            </Grid>
          </Grid>

          <Grid container direction="column" rowGap={2} justifyContent="center" alignItems="center" mt={2}>
            <Grid item component={Typography} variant="caption" color="text.secondary">
              {t('order.checkout.trust')}
            </Grid>

            <Grid item component={Typography} variant="caption" color="text.secondary">
              <Trans i18nKey="order.checkout.stripe">
                All payment methods are safe and processed by
                <Link to={paths.external.stripe} target="_blank">
                  Stripe
                </Link>
              </Trans>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
