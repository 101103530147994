import { areIntervalsOverlapping } from 'date-fns';
import dayjs from 'dayjs';

import type { ProxyFilterValues } from 'modules/proxies/hooks/useProxyFilters';

import {
  type BandwidthSpeedDto,
  type DiagnosticRoutineResponse,
  type MaintenanceWindowDTO,
  type ProxyAdminDetailsResponse,
  type ProxyChangelogItemDTO,
  type ProxyChangelogResponse,
  type ProxyEventDTO,
  type ProxyEventsResponse,
  type ProxyISPsResponse,
  type ProxyQueryParams,
  type ProxyResponse,
  type ProxyResponseAllowedAction,
  ProxyResponseStatus,
  type ProxySubnetDTO,
  type ProxySummaryResponse,
  type ThreadsUpgradeDTO,
} from './dtos';
import {
  type BandwidthSpeedOptions,
  type BandwidthSpeedPrice,
  type DiagnosticRoutineModel,
  type MaintenanceStatus,
  type ProxyAdminDetailsModel,
  type ProxyChangelogItemModel,
  type ProxyChangelogModel,
  type ProxyEventModel,
  type ProxyISP,
  type ProxyModel,
  ProxyModelAllowedAction,
  type ProxySubnet,
  type ProxySummaryModel,
  type ThreadsChangeOptions,
  type ThreadsUpgradePrice,
} from './models';
import { ProxyStatus } from './types';

// #region Helper mappers

/**
 * Converts proxy response status to proxy status
 * @param {ProxyResponseStatus} from
 * @returns {ProxyStatus}
 */
function toProxyStatus(from: ProxyResponse): ProxyStatus {
  if (
    [
      ProxyResponseStatus.CANCELED,
      ProxyResponseStatus.EXPIRED,
      ProxyResponseStatus.INITIATING,
      ProxyResponseStatus.PENDING,
    ].includes(from.status)
  ) {
    return ProxyStatus[ProxyResponseStatus[from.status]];
  }

  const threeDaysBefore = dayjs(from.expiresAt).subtract(3, 'day');

  if (dayjs().isBetween(threeDaysBefore, dayjs(from.expiresAt))) {
    return ProxyStatus.EXPIRING_SOON;
  }

  return ProxyStatus[ProxyResponseStatus[from.status]];
}

/**
 * Converts proxy response action to proxy
 */
function toProxyModelAllowedAction(
  action: ProxyResponseAllowedAction | ProxyModelAllowedAction,
): ProxyModelAllowedAction {
  if (action === ProxyModelAllowedAction.SHOW_DETAILS) return ProxyModelAllowedAction.SHOW_DETAILS;

  return ProxyModelAllowedAction[action];
}

// #endregion

// #region Response to Model mappers

/**
 * Converts proxy summary response to proxy summary model
 *
 * @param {ProxySummaryResponse} from
 * @returns {ProxySummaryModel}
 */
export function toProxySummaryModel(from: ProxySummaryResponse): ProxySummaryModel {
  return {
    active: from.activeProxyCount,
    expiring: from.expiringProxyCount,
    inactive: from.inactiveProxyCount,
  };
}

/**
 * Converts proxy response to proxy model
 *
 * @param {ProxyResponse} from
 * @returns {ProxyModel}
 */
export function toProxyModel(from: ProxyResponse): ProxyModel {
  const { status, actions, uplinkSpeed, threads, ...rest } = from;

  return {
    ...rest,
    status: toProxyStatus(from),
    actions: [ProxyModelAllowedAction.SHOW_DETAILS, ...actions].map(toProxyModelAllowedAction),
    uplinkSpeed: uplinkSpeed?.value,
    threads: threads?.value,
    routes: from.routes,
  };
}

// #endregion

export function toProxyQueryParams(values: ProxyFilterValues): Partial<ProxyQueryParams> {
  return {
    status: values.status ?? undefined,
    ipVersion: values.ipVersion ?? undefined,
    publicIp: values.publicIp ?? undefined,
    connectionType: values.connectionType ?? undefined,
    authenticationType: values.authenticationType ?? undefined,
    ispName: values.ispName ?? undefined,
    countryCode: values.countryCode ?? undefined,
    proxyType: values.network ?? undefined,
  };
}

export function toProxyISPModel(dto: ProxyISPsResponse['data'][number]): ProxyISP {
  return {
    id: dto.id,
    name: dto.name,
  };
}

export function toBandwidthSpeedPrice(dto: BandwidthSpeedDto['availableUplinkSpeeds'][number]): BandwidthSpeedPrice {
  return {
    mbit: dto.id,
    price: Number(dto.totalPrice),
  };
}

export function toBandwidthSpeedOptions(dto: BandwidthSpeedDto): BandwidthSpeedOptions {
  return {
    currentUplinkSpeed: dto.currentUplinkSpeed,
    options: dto.availableUplinkSpeeds.map((i) => i.id),
  };
}

export function toThreadsPrice(dto: ThreadsUpgradeDTO['availableThreads'][number]): ThreadsUpgradePrice {
  return {
    thread: dto.id,
    price: Number(dto.totalPrice),
  };
}

export function toThreadsOptions(dto: ThreadsUpgradeDTO): ThreadsChangeOptions {
  return {
    currentThreads: dto.currentThreads,
    options: dto.availableThreads.map((i) => i.id),
  };
}

export function proxyQueryToUrlSearchParams(proxyQueryParams: ProxyQueryParams) {
  const newSearchParams = new URLSearchParams({
    page: proxyQueryParams.page.toString(),
    perPage: proxyQueryParams.perPage.toString(),
    // pass preset only when no status provided
    ...(proxyQueryParams.preset &&
      (!proxyQueryParams.status || proxyQueryParams.status.length === 0) && {
        preset: proxyQueryParams.preset.toLowerCase(),
      }),
    ...(proxyQueryParams.ipVersion && { ipVersion: proxyQueryParams.ipVersion }),
    ...(proxyQueryParams.publicIp && { publicIp: proxyQueryParams.publicIp }),
    ...(proxyQueryParams.connectionType && { connectionType: proxyQueryParams.connectionType }),
    ...(proxyQueryParams.authenticationType && { authenticationType: proxyQueryParams.authenticationType }),
    ...(proxyQueryParams.ispName && { ispName: proxyQueryParams.ispName }),
    ...(proxyQueryParams.countryCode && { countryCode: proxyQueryParams.countryCode }),
    ...(proxyQueryParams.order?.bandwidth && {
      'order[bandwidth]': proxyQueryParams.order.bandwidth,
    }),
    ...(proxyQueryParams.order?.expiresAt && {
      'order[expiresAt]': proxyQueryParams.order.expiresAt,
    }),
    ...(proxyQueryParams.order?.createdAt && {
      'order[createdAt]': proxyQueryParams.order.createdAt,
    }),
  });

  proxyQueryParams.proxyType?.forEach((item) => newSearchParams.append('proxyType', item));
  proxyQueryParams.status?.forEach((item) => newSearchParams.append('status', item));

  return newSearchParams;
}

/**
 * Converts proxy event DTO to proxy event model
 *
 * @param {ProxyEventDTO} from
 * @returns {ProxyEventModel}
 */
export function toProxyEvent(from: ProxyEventDTO): ProxyEventModel {
  return from;
}

/**
 * Converts proxy events response to proxy events model
 *
 * @param {ProxyEventsResponse} from
 * @returns {ProxyEventsModel}
 */
export function toProxyEventsModel(from: ProxyEventsResponse): ProxyEventModel[] {
  return from.flat().map(toProxyEvent);
}

/**
 * Converts proxy change history DTO to proxy change history model
 *
 * @param {ProxyChangelogItemDTO} from
 * @returns {ProxyChangelogModel}
 */
export function toProxyChangelogItemModel(from: ProxyChangelogItemDTO): ProxyChangelogItemModel {
  return from;
}

/**
 * Converts change history response to  proxy change history model
 *
 * @param {ProxyChangelogResponse} from
 * @returns {ProxyChangelogModel}
 */
export function toProxyChangelogModel(from: ProxyChangelogResponse): ProxyChangelogModel {
  return from.map(toProxyChangelogItemModel);
}

/**
 * Converts proxy admin details response to proxy admin details model
 *
 * @param {ProxyAdminDetailsResponse} from
 * @returns {ProxyAdminDetailsModel}
 */
export function toProxyAdminDetailsModel(from: ProxyAdminDetailsResponse): ProxyAdminDetailsModel {
  return from;
}

/**
 * Detects overlapping time intervals in maintenance status
 *
 * @param {MaintenanceStatus[]} from
 * @returns {MaintenanceStatus[]}
 */
function getOverlappingTimeIntervals(from: MaintenanceStatus[]): MaintenanceStatus[] {
  const overlapping: MaintenanceStatus[] = [];

  for (let i = 0; i < from.length - 1; i++) {
    for (let j = i + 1; j < from.length; j++) {
      const startI = dayjs(from[i].start);
      const endI = dayjs(from[i].end);
      const startJ = dayjs(from[j].start);
      const endJ = dayjs(from[j].end);

      const areOverlapping = areIntervalsOverlapping(
        { start: startI.toDate(), end: endI.toDate() },
        { start: startJ.toDate(), end: endJ.toDate() },
        { inclusive: true },
      );

      if (areOverlapping) {
        overlapping.push({
          start: startI.isBefore(startJ) ? startI.toISOString() : startJ.toISOString(),
          end: endI.isAfter(endJ) ? endI.toISOString() : endJ.toISOString(),
          note: from[i].note ?? from[j].note ?? null,
        });
      }
    }
  }

  return overlapping;
}

/**
 * Converts maintenance window response to maintenance status model
 *
 * @param {MaintenanceWindowDTO} from
 * @returns {MaintenanceStatus}
 */
export function toMaintenanceStatus(from: MaintenanceWindowDTO[]): MaintenanceStatus | null {
  // ! One date available only
  if (from.length === 1) return { start: from[0].startAt, end: from[0].endAt, note: from[0].note };

  const statuses = from.map<MaintenanceStatus>(({ startAt, endAt, note }) => ({ start: startAt, end: endAt, note }));

  return (
    // ! Get only the clesest date range
    getOverlappingTimeIntervals(statuses)[0] ??
    // ! Get the clsest date range from the response
    statuses[0] ??
    // ! Return null if not of above passes requirements
    null
  );
}

export function toProxySubnets(from: ProxySubnetDTO[]): ProxySubnet[] {
  return from.map(({ id, cidr, serverName, countryCode }) => ({
    id,
    label: `${cidr} (${serverName})`,
    countryCode,
  }));
}

export function toDiagnosticRoutineModel(from: DiagnosticRoutineResponse): DiagnosticRoutineModel;
export function toDiagnosticRoutineModel(from: DiagnosticRoutineResponse[]): DiagnosticRoutineModel[];

export function toDiagnosticRoutineModel(
  from: DiagnosticRoutineResponse | DiagnosticRoutineResponse[],
): DiagnosticRoutineModel | DiagnosticRoutineModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toDiagnosticRoutineModel(item));
  }

  return { ...from };
}
