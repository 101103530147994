import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const PayoutSummaryItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default { PayoutSummaryItem };
