import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button, type ButtonProps } from 'components/Button';
import { posthogEvents } from 'constants/posthogEvents';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { resetOrderFlow } from 'store/orders/reducers';

import { useOrderFlow } from '../hooks/useOrderFlow';

type Props = Omit<ButtonProps, 'onClick'>;

export function OrderProxyButton({ size = 'medium', ...props }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const { isNetworkTypeSelected, isVPNFlow, isValidNetworkType, isMarketplaceFlow, startNewOrderFlow } = useOrderFlow();

  return (
    <Button
      component={Link}
      to={paths.order.index}
      onClick={(e) => {
        e.preventDefault();

        // Reset exsiting order flow and start new Proxy order flow
        if (isVPNFlow || !isValidNetworkType) {
          dispatch(resetOrderFlow());
          startNewOrderFlow(null);

          return navigate(paths.order.index);
        }

        // Start new Proxy flow
        if (!isNetworkTypeSelected) {
          startNewOrderFlow(null);

          return navigate(paths.order.index);
        }

        // Continue previous order flow
        posthog?.capture(posthogEvents.order.continued, { isMarketplaceFlow });

        return navigate(paths.order.index);
      }}
      size={size}
      {...props}
    >
      {t('common:navbar.orderProxy')}
    </Button>
  );
}
