import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flex: '1 1 auto',
  height: '100%',
  position: 'relative',
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

export default { LayoutRoot, LogoWrapper };
