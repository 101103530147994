/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import commonEn from './en/common.json';
import countriesregionsEn from './en/countriesregions.json';
import errorsEn from './en/errors.json';
import headersEn from './en/headers.json';
import pagesEn from './en/pages.json';

const resources = {
  en: {
    common: commonEn,
    countriesregions: countriesregionsEn,
    errors: errorsEn,
    headers: headersEn,
    pages: pagesEn,
  },
};

if (process.env.REACT_APP_ENV === 'development') {
  i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'dev',
    defaultNS: 'pages',
    ns: ['common', 'countriesregions', 'errors', 'headers', 'pages'],
    load: 'all',
    debug: true,
  });
} else {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: '/resources/locales/{{lng}}/{{ns}}.json',
      },
      detection: {
        lookupLocalStorage: 'language',
      },
      returnEmptyString: false,
      fallbackLng: 'en',
      defaultNS: 'pages',
      ns: ['common', 'countriesregions', 'errors', 'headers', 'pages'],
      supportedLngs: ['en', 'es', 'vi', 'ru', 'zh_CN'],
      load: 'languageOnly',
    });
}

export default i18n;
