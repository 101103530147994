import { useEffect } from 'react';

import ButtonBase from '@mui/material/ButtonBase';

import { Icon } from 'components/Icon';

import { useCannyContext } from './CannyProvider';
import Styled from './styled';

export function CannyChangelogButton() {
  const { isLoaded, appID, canny } = useCannyContext();

  useEffect(() => {
    if (!isLoaded || !appID) {
      return;
    }

    canny.initChangelog({ appID, align: 'right', position: 'bottom' });

    return () => canny.closeChangelog();
  }, [appID, canny, isLoaded]);

  return (
    <Styled.CannyButtonWrapper component={ButtonBase} data-canny-changelog>
      <Icon name="notification" iconStroke={(theme) => theme.palette.grey['600']} />
    </Styled.CannyButtonWrapper>
  );
}
