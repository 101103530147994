import type { AppEndpointBuilder } from 'store/base';

import type {
  OpenVPNDownloadConfigurationParams,
  VPNExtendPeriodParams,
  VPNChangelogItemDTO,
  VPNEventDTO,
  WireGuardDownloadConfigurationParams,
} from './dtos';
import { toVPNAdminModel, toVPNEvent, toVPNHistory, toVPNModel } from './helpers';
import type { VPNAdminModel, VPNChangelogItem, VPNEvent, VPNLocation, VPNModel, VPNPort } from './models';

export const vpnEndpoints = (builder: AppEndpointBuilder) => ({
  getUserVPN: builder.query<VPNModel, number | void>({
    query: (vpnId) => ({
      url: `vpn${vpnId ? `/${vpnId}` : ''}`,
      method: 'GET',
    }),
    transformResponse: toVPNModel,
    providesTags: ['VPN'],
  }),
  getSupportedVPNLocations: builder.query<VPNLocation[], void>({
    query: () => ({
      url: 'vpn/locations',
      method: 'GET',
    }),
    providesTags: ['VPN-Locations'],
  }),
  getSupportedVPNPorts: builder.query<VPNPort[], void>({
    query: () => ({
      url: 'vpn/ports',
      method: 'GET',
    }),
    providesTags: ['VPN-Ports'],
  }),
  downloadOpenVPNConfiguration: builder.mutation<string, OpenVPNDownloadConfigurationParams>({
    query: ({ vpnId, ...data }) => ({
      url: `vpn/${vpnId}/download/openvpn`,
      method: 'POST',
      data,
    }),
  }),
  downloadWireGuardConfiguration: builder.mutation<string, WireGuardDownloadConfigurationParams>({
    query: ({ vpnId, ...data }) => ({
      url: `vpn/${vpnId}/download/wireguard`,
      method: 'POST',
      data,
    }),
  }),

  // ! ADMIN PANEL FOR VPNS
  getVPNEvents: builder.query<VPNEvent[], number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/events`,
      method: 'GET',
    }),
    keepUnusedDataFor: 1,
    transformResponse: (data: VPNEventDTO[]) => data.map(toVPNEvent),
    providesTags: ['VPN-Admin-Events'],
  }),

  getVPNChangelog: builder.query<VPNChangelogItem[], number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/change-history`,
      method: 'GET',
    }),
    keepUnusedDataFor: 1,
    transformResponse: (data: VPNChangelogItemDTO[]) => data.map(toVPNHistory),
    providesTags: ['VPN-Admin-Change-History'],
  }),

  getVPNAdminDetails: builder.query<VPNAdminModel, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/admin/details`,
      method: 'GET',
    }),
    transformResponse: toVPNAdminModel,
    providesTags: ['VPN-Admin-Details'],
  }),

  reactivateVPN: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/activate`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => ['VPN-Admin-Details', { type: 'VPN', id: params }],
  }),

  cancelVPN: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/cancel`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => ['VPN-Admin-Details', { type: 'VPN', id: params }],
  }),

  expireVPN: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/expire`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => ['VPN-Admin-Details', { type: 'VPN', id: params }],
  }),

  enableVPNAutoExtend: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/auto-extend/enable`,
      method: 'POST',
    }),
    invalidatesTags: () => ['VPN', 'VPN-Admin-Details'],
  }),
  disableVPNAutoExtend: builder.mutation<void, number>({
    query: (vpnId) => ({
      url: `vpn/${vpnId}/auto-extend/disable`,
      method: 'POST',
    }),
    invalidatesTags: () => ['VPN', 'VPN-Admin-Details'],
  }),
  extendVPNPeriod: builder.mutation<void, VPNExtendPeriodParams>({
    query: ({ vpnId, ...data }) => ({
      url: `vpn/${vpnId}/extend-period`,
      method: 'POST',
      data,
    }),
    invalidatesTags: () => ['VPN', 'VPN-Admin-Details', 'Account-Balance'],
  }),
});
