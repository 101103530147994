import { ReactComponent as EyeSlashIcon } from 'assets/icons/security/eye-slash.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/security/eye.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/security/key.svg';
import { ReactComponent as LockIcon } from 'assets/icons/security/lock.svg';
import { ReactComponent as SecuritySafeIcon } from 'assets/icons/security/security-safe.svg';
import { ReactComponent as ShieldTickIcon } from 'assets/icons/security/shield-tick.svg';

export const security = {
  'eye-slash': EyeSlashIcon,
  eye: EyeIcon,
  lock: LockIcon,
  'security-safe': SecuritySafeIcon,
  key: KeyIcon,
  'shield-tick': ShieldTickIcon,
};
