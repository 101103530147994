import { styled } from '@mui/material/styles';

import { Card } from 'components/Card';

const Container = styled(Card)(({ theme }) => ({
  width: 'auto',
  backgroundColor: theme.palette.error.light,
}));

export default { Container };
