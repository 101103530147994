import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useFreshchatAPI } from 'components/Freshchat/hooks/useFreshchatAPI';
import { useListenFreshchatUnreadCount } from 'components/Freshchat/hooks/useListenFreshchatUnreadCount';
import { Icon } from 'components/Icon';
import { useSignOut } from 'hooks/useSignOut';
import { paths } from 'paths';
import { useGetUserSessionQuery } from 'store/api';
import { buildLegacyAppURL } from 'utils/url';

type AccountMenuProps = Omit<MenuProps, 'onClose'> & {
  onClose: () => void;
};

export function AccountMenu(props: AccountMenuProps) {
  const { t } = useTranslation();
  const { openFreshchat } = useFreshchatAPI();
  const { hasUnreadMessages } = useListenFreshchatUnreadCount();

  const { data } = useGetUserSessionQuery();

  const signOut = useSignOut();

  return (
    <Menu {...props} onClick={props.onClose}>
      <MenuItem to={paths.account.index} component={Link}>
        <Icon name="user" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Grid container direction="column" ml={1}>
          <Grid item component={Typography} variant="body-2" color="grey.500">
            {t('common:popovers.account.my-account')}
          </Grid>
          <Grid item component={Typography} variant="caption" color="grey.500">
            {data?.profileDetails?.email}
          </Grid>
        </Grid>
        <Typography variant="body-2" color="grey.500"></Typography>
      </MenuItem>

      <MenuItem to={paths.account.payments} component={Link}>
        <Icon name="card" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Typography variant="body-2" color="grey.500">
          {t('common:popovers.account.billing')}
        </Typography>
      </MenuItem>

      <MenuItem to={paths.featureRequest.index} component={Link}>
        <Icon name="message-add" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Typography variant="body-2" color="grey.500">
          {t('common:popovers.account.featureRequest')}
        </Typography>
      </MenuItem>

      <MenuItem
        to=""
        component={Link}
        onClick={(e) => {
          e.preventDefault();
          openFreshchat();
        }}
      >
        <Icon name="question" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Badge variant="dot" color="error" invisible={!hasUnreadMessages}>
          <Typography variant="body-2" color="grey.500">
            {t('common:popovers.account.contactSupport')}
          </Typography>
        </Badge>
      </MenuItem>

      <MenuItem to={paths.external.termsAndConditions} component={Link} target="_blank">
        <Icon name="note-text" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Typography variant="body-2" color="grey.500">
          {t('common:popovers.account.terms')}
        </Typography>
      </MenuItem>

      <MenuItem
        to=""
        component={Link}
        onClick={(e) => {
          e.preventDefault();
          document.querySelector('.cky-btn-revisit-wrapper')?.dispatchEvent(new Event('click'));
        }}
      >
        <Icon name="shield-tick" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Typography variant="body-2" color="grey.500">
          {t('common:popovers.account.cookiePolicy')}
        </Typography>
      </MenuItem>

      <MenuItem onClick={signOut} to={buildLegacyAppURL('logout')} component={Link}>
        <Icon name="logout" size="small" iconStroke={(theme) => theme.palette.text.secondary} />
        <Typography variant="body-2" color="grey.500">
          {t('common:popovers.account.logout')}
        </Typography>
      </MenuItem>
    </Menu>
  );
}
