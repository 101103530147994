import { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Autocomplete } from 'components/form-partials/Autocomplete';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useGetReactivateProxyPriceQuery } from 'store/api';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

import { BalanceSummary } from '../BalanceSummary';
import ModalStyled from '../styled';

export type ReactivateProxyFormProps = {
  proxyId: number;
};

type ReactivateProxyValues = {
  periodInMonths: number;
};

export function ReactivateProxyForm({ proxyId }: ReactivateProxyFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { values, handleSubmit, isSubmitting, isValid } = useFormikContext<ReactivateProxyValues>();

  const { data: reactivationPrice, isFetching: isPriceFetching } = useGetReactivateProxyPriceQuery({
    proxyId,
    periodInMonths: values.periodInMonths,
  });

  const periodOptions = useMemo<Option[]>(
    () =>
      [1, 2, 3, 6, 12].map((count) => ({
        value: count,
        label: t('common:form.month_other', { count }),
      })),
    [t],
  );

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.reactivate')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, isPriceFetching, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.reactivate.title')}
      subtitle={t('proxies.modals.reactivate.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        <Autocomplete
          size="small"
          disableClearable
          options={periodOptions}
          label={t('common:form.timePeriod')}
          name="periodInMonths"
        />

        <Stack direction="column" rowGap={2}>
          <BalanceSummary isLoading={isPriceFetching} price={reactivationPrice?.finalPrice} />
          <Divider />
          <ModalStyled.PriceContainer>
            <Typography variant="body-1" color="grey.500">
              {t('proxies.modals.reactivate.proxy')}
            </Typography>
            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="body-1">{CurrencyFormatter.format(reactivationPrice?.unitPrice || 0)}</Typography>
            )}
          </ModalStyled.PriceContainer>

          <ModalStyled.PriceContainer>
            <Typography variant="body-1" color="grey.500">
              {t('proxies.modals.reactivate.fee')}
            </Typography>
            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="body-1">
                {CurrencyFormatter.format(reactivationPrice?.additionalAmountAfterDiscount || 0)}
              </Typography>
            )}
          </ModalStyled.PriceContainer>

          <Divider />

          <ModalStyled.PriceContainer>
            <Typography variant="title" color="grey.500">
              {t('common:form.total')}
            </Typography>
            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">
                {CurrencyFormatter.format(reactivationPrice?.finalPrice || 0)}
              </Typography>
            )}
          </ModalStyled.PriceContainer>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
