import AppBar from '@mui/material/AppBar';
import Box, { type BoxProps } from '@mui/material/Box';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import type { MUIStyledCommonProps } from '@mui/system/createStyled';

import { Link, type To } from 'react-router-dom';

import { TextButton, type TextButtonProps } from 'components/TextButton';
import type { ITheme } from 'theme';
import type { TestVariants } from 'types';

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.background.paper,
  },
}));

type OrderLayoutContainerProps = BoxProps & {
  isOrderingEnabled?: boolean;
  hasOffset?: boolean;
  testVariant?: TestVariants['AB'];
};

const OrderLayoutContainer = styled(
  ({ isOrderingEnabled, testVariant, hasOffset, ...props }: OrderLayoutContainerProps) => <Box {...props} />,
)<MUIStyledCommonProps<ITheme>>(({ isOrderingEnabled, hasOffset, testVariant = 'control', theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  minHeight: '100%',
  paddingTop: theme.appBar.height,

  marginTop: theme.spacing(isOrderingEnabled ? 8 : 0),

  ...(testVariant === 'test' && {
    flexDirection: 'row',
    marginTop: 0,
    paddingRight: theme.spacing(hasOffset ? 50 : 0),
  }),

  [theme.breakpoints.down('lg')]: {
    ...(testVariant === 'test' && {
      paddingRight: 0,
    }),
  },

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    paddingTop: `calc(${theme.appBar.height}px + ${theme.spacing(4)})`,
  },
}));

const OrderLayoutLoadingContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,

  width: '100%',
  height: '100%',
});

const OrderLayoutAppBarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'fixed',
  boxShadow: `0px 1px 0px ${theme.palette.divider}`,
}));

const OrderLayoutToolbar = styled(Toolbar)<MUIStyledCommonProps<ITheme>>(({ theme }) => ({
  height: theme.appBar.height,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
}));

const NavigationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: theme.zIndex.appBar + 100,

  'a + a': { marginLeft: theme.spacing(2) },
}));

const OrderLayoutNavigationItemRoot = styled(
  ({ $isActive, to, ...props }: TextButtonProps & { $isActive: boolean; to: To }) => (
    <TextButton component={Link} to={to} {...props} />
  ),
)(({ theme, $isActive }) => ({
  '&:hover': {
    '& span': {
      textDecoration: 'none',
    },
  },
  borderBottom: $isActive ? `2px solid ${theme.palette.primary.main}` : 'unset',
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
  maxHeight: 64,

  zIndex: theme.zIndex.appBar + 200,
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 200,
}));

export default {
  LayoutRoot,
  OrderLayoutContainer,
  OrderLayoutLoadingContainer,
  OrderLayoutAppBarRoot,
  OrderLayoutToolbar,
  NavigationWrapper,
  OrderLayoutNavigationItemRoot,
  LogoWrapper,
  IconButton,
};
