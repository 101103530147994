import { useMemo } from 'react';

import { type NavigateOptions, type To, useNavigate } from 'react-router-dom';

export function useRouter() {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      refresh: () => navigate(0),
      push: (to: To, options?: NavigateOptions) => navigate(to, options),
      replace: (to: To, options?: NavigateOptions) => navigate(to, { ...options, replace: true }),
    }),
    [navigate],
  );
}
