import { ConfirmationModal, type ConfirmationModalProps } from 'components/modals/ConfirmationModal';
import { createModalManager } from 'components/modals/context';
import type { ModalStateType } from 'components/modals/types';
import { DownloadInvoicesModal, type DownloadInvoicesModalProps } from 'modules/accounts/modals/DownloadInvoicesModal';
import { ManageCardModal, type ManageCardModalProps } from 'modules/accounts/modals/ManageCardModal';
import { PasswordModal, type PasswordModalProps } from 'modules/accounts/modals/PasswordModal';
import { ToTPModal, type ToTPModalProps } from 'modules/accounts/modals/ToTPModal';
import { VerifyEmailModal } from 'modules/accounts/modals/VerifyEmailModal';
import { AddCreditCardModal, type AddCreditCardModalProps } from 'modules/billing/modals/AddCreditCardModal';
import { TopUpModal, type TopUpModalProps } from 'modules/billing/modals/TopUpModal';
import { CouponCodeModal, type CouponCodeModalProps } from 'modules/orders/modals/CouponCodeModal';
import { ExperienceCheckModal } from 'modules/orders/modals/ExperienceCheckModal';
import {
  OrderCheckoutStatusModal,
  type OrderCheckoutStatusModalProps,
} from 'modules/orders/modals/OrderCheckoutStatusModal';
import { AdminAddRouteModal, type AdminAddRouteModalProps } from 'modules/proxies/modals/AdminAddRouteModal';
import { AdminChangelogModal, type AdminChangelogModalProps } from 'modules/proxies/modals/AdminChangelogModal';
import { AdminChangePortModal, type AdminChangePortModalProps } from 'modules/proxies/modals/AdminChangePortModal';
import { AdminDiagnosticModal, type AdminDiagnosticModalProps } from 'modules/proxies/modals/AdminDiagnosticModal';
import { AdminEventsModal, type AdminEventsModalProps } from 'modules/proxies/modals/AdminEventsModal';
import { BulkAutoExtendModal, type BulkAutoExtendModalProps } from 'modules/proxies/modals/BulkAutoExtendModal';
import { ChangeAuthMethodModal, type ChangeAuthMethodModalProps } from 'modules/proxies/modals/ChangeAuthMethodModal';
import {
  ChangeProxyProtocolModal,
  type ChangeProxyProtocolModalProps,
} from 'modules/proxies/modals/ChangeProxyProtocolModal';
import { ChangeThreadsModal, type ChangeThreadsModalProps } from 'modules/proxies/modals/ChangeThreadsModal';
import {
  ChangeUplinkSpeedModal,
  type ChangeUplinkSpeedModalProps,
} from 'modules/proxies/modals/ChangeUplinkSpeedModal';
import {
  DownloadCredentialsModal,
  type DownloadCredentialsModalProps,
} from 'modules/proxies/modals/DownloadCredentialsModal';
import { ExtendBandwidthModal, type ExtendBandwidthModalProps } from 'modules/proxies/modals/ExtendBandwidthModal';
import {
  ExtendProxyPeriodModal,
  type ExtendProxyPeriodModalProps,
} from 'modules/proxies/modals/ExtendProxyPeriodModal';
import {
  GlobalIPWhitelistModal,
  type GlobalIPWhitelistModalProps,
} from 'modules/proxies/modals/GlobalIPWhitelistModal';
import { IPWhitelistModal, type IPWhitelistModalProps } from 'modules/proxies/modals/IPWhitelistModal';
import {
  ProxyAutoExtendBandwidthModal,
  type ProxyAutoExtendBandwidthModalProps,
} from 'modules/proxies/modals/ProxyAutoExtendBandwidthModal';
import { ProxyAutoExtendModal, type ProxyAutoExtendModalProps } from 'modules/proxies/modals/ProxyAutoExtendModal';
import { ProxyNoteModal, type ProxyNoteModalProps } from 'modules/proxies/modals/ProxyNoteModal';
import { ReactivateProxyModal, type ReactivateProxyModalProps } from 'modules/proxies/modals/ReactivateProxyModal';
import {
  UpgradeBandwidthSpeedModal,
  type UpgradeBandwidthSpeedModalProps,
} from 'modules/proxies/modals/UpgradeBandwidthSpeedModal';
import { UpgradeThreadsModal, type UpgradeThreadsModalProps } from 'modules/proxies/modals/UpgradeThreadsModal';
import { PayoutModal } from 'modules/referrals/modals/PayoutModal';
import { PayoutNotPossibleModal } from 'modules/referrals/modals/PayoutNotPossibileModal';
import { PayoutSetupModal, type PayoutSetupModalProps } from 'modules/referrals/modals/PayoutSetupModal';
import { ExtendVPNPeriodModal, type ExtendVPNPeriodModalProps } from 'modules/vpns/modals/ExtendVPNPeriodModal';
import { VPNAdminEventsModal, type VPNAdminEventsModalProps } from 'modules/vpns/modals/VPNAdminEventsModal';
import { VPNAutoExtendModal, type VPNAutoExtendModalProps } from 'modules/vpns/modals/VPNAutoExtendModal';
import { VPNChangelogModal, type VPNChangelogModalProps } from 'modules/vpns/modals/VPNChangelogModal';

type ModalMapType =
  | ModalStateType<'credit.card.add', AddCreditCardModalProps>
  | ModalStateType<'credit.card.remove', ConfirmationModalProps>
  | ModalStateType<'coupon', CouponCodeModalProps>
  | ModalStateType<'orders.cancel', ConfirmationModalProps>
  | ModalStateType<'proxies.admin.https.toggle', ConfirmationModalProps>
  | ModalStateType<'orders.experience.check'>
  | ModalStateType<'account.totp', ToTPModalProps>
  | ModalStateType<'account.password', PasswordModalProps>
  | ModalStateType<'account.id.verification', ConfirmationModalProps>
  | ModalStateType<'account.email.verification'>
  | ModalStateType<'proxies.extend.period', ExtendProxyPeriodModalProps>
  | ModalStateType<'proxies.extend.bandwidth', ExtendProxyPeriodModalProps>
  | ModalStateType<'proxies.upgrade.bandwidth-speed', UpgradeBandwidthSpeedModalProps>
  | ModalStateType<'proxies.upgrade.threads', UpgradeThreadsModalProps>
  | ModalStateType<'proxies.auto.extend', ProxyAutoExtendModalProps>
  | ModalStateType<'proxies.auto.extend.bandwidth', ProxyAutoExtendBandwidthModalProps>
  | ModalStateType<'proxies.ip.whitelist.global', GlobalIPWhitelistModalProps>
  | ModalStateType<'proxies.ip.whitelist.proxy', IPWhitelistModalProps>
  | ModalStateType<'proxies.credentials.download', DownloadCredentialsModalProps>
  | ModalStateType<'proxies.reactivate', ReactivateProxyModalProps>
  | ModalStateType<'proxies.change-protocol', ChangeProxyProtocolModalProps>
  | ModalStateType<'proxies.change.auth-method', ChangeAuthMethodModalProps>
  | ModalStateType<'proxies.change.uplink-speed', ChangeUplinkSpeedModalProps>
  | ModalStateType<'proxies.change.threads', ChangeThreadsModalProps>
  | ModalStateType<'proxies.note', ProxyNoteModalProps>
  | ModalStateType<'vpns.auto.extend', VPNAutoExtendModalProps>
  | ModalStateType<'vpns.admin.changelog', VPNChangelogModalProps>
  | ModalStateType<'vpns.admin.events', VPNAdminEventsModalProps>
  | ModalStateType<'vpn.extend.period', ExtendVPNPeriodModalProps>
  | ModalStateType<'credit.card.manage', ManageCardModalProps>
  | ModalStateType<'account.billing.topUp', TopUpModalProps>
  | ModalStateType<'referrals.payout'>
  | ModalStateType<'referrals.payout-not-possible'>
  | ModalStateType<'referrals.payout-setup', PayoutSetupModalProps>
  | ModalStateType<'account.billing.topUp.confirmation', ConfirmationModalProps>
  | ModalStateType<'proxies.cancel.confirmation', ConfirmationModalProps>
  | ModalStateType<'api-keys.delete', ConfirmationModalProps>
  | ModalStateType<'order.status', OrderCheckoutStatusModalProps>
  | ModalStateType<'order.no-deals', ConfirmationModalProps>
  | ModalStateType<'admin.proxies.events', AdminEventsModalProps>
  | ModalStateType<'admin.proxies.changelog', AdminChangelogModalProps>
  | ModalStateType<'admin.proxies.port.change', AdminChangePortModalProps>
  | ModalStateType<'account.invoices.download', DownloadInvoicesModalProps>
  | ModalStateType<'proxies.auto.extend.bulk', BulkAutoExtendModalProps>
  | ModalStateType<'proxies.admin.route.add', AdminAddRouteModalProps>
  | ModalStateType<'proxies.admin.route.delete', ConfirmationModalProps>
  | ModalStateType<'admin.proxies.diagnostic', AdminDiagnosticModalProps>;

const { Provider, useShowModal } = createModalManager(({ id, params }: ModalMapType) => {
  switch (id) {
    case 'credit.card.add':
      return <AddCreditCardModal {...params} />;

    case 'account.billing.topUp':
      return <TopUpModal {...params} />;

    case 'credit.card.manage':
      return <ManageCardModal {...params} />;

    case 'coupon':
      return <CouponCodeModal {...params} />;

    case 'admin.proxies.diagnostic':
      return <AdminDiagnosticModal {...params} />;

    case 'orders.cancel':
    case 'order.no-deals':
    case 'credit.card.remove':
    case 'account.id.verification':
    case 'account.billing.topUp.confirmation':
    case 'api-keys.delete':
    case 'proxies.cancel.confirmation':
    case 'proxies.admin.https.toggle':
    case 'proxies.admin.route.delete':
      return <ConfirmationModal {...params} />;

    case 'proxies.admin.route.add':
      return <AdminAddRouteModal {...params} />;

    case 'account.totp':
      return <ToTPModal {...params} />;

    case 'account.password':
      return <PasswordModal {...params} />;

    case 'proxies.extend.period':
      return <ExtendProxyPeriodModal {...params} />;

    case 'proxies.upgrade.bandwidth-speed':
      return <UpgradeBandwidthSpeedModal {...params} />;

    case 'proxies.upgrade.threads':
      return <UpgradeThreadsModal {...params} />;

    case 'proxies.extend.bandwidth':
      return <ExtendBandwidthModal {...(params as ExtendBandwidthModalProps)} />;

    case 'proxies.auto.extend':
      return <ProxyAutoExtendModal {...params} />;

    case 'proxies.auto.extend.bandwidth':
      return <ProxyAutoExtendBandwidthModal {...params} />;

    case 'proxies.ip.whitelist.global':
      return <GlobalIPWhitelistModal {...params} />;

    case 'proxies.ip.whitelist.proxy':
      return <IPWhitelistModal {...params} />;

    case 'proxies.credentials.download':
      return <DownloadCredentialsModal {...params} />;

    case 'proxies.note':
      return <ProxyNoteModal {...params} />;

    case 'proxies.reactivate':
      return <ReactivateProxyModal {...params} />;

    case 'proxies.change-protocol':
      return <ChangeProxyProtocolModal {...params} />;

    case 'proxies.change.auth-method':
      return <ChangeAuthMethodModal {...params} />;

    case 'proxies.change.uplink-speed':
      return <ChangeUplinkSpeedModal {...params} />;

    case 'proxies.auto.extend.bulk':
      return <BulkAutoExtendModal {...params} />;

    case 'proxies.change.threads':
      return <ChangeThreadsModal {...params} />;

    case 'vpns.auto.extend':
      return <VPNAutoExtendModal {...params} />;

    case 'vpns.admin.changelog':
      return <VPNChangelogModal {...params} />;

    case 'vpns.admin.events':
      return <VPNAdminEventsModal {...params} />;

    case 'vpn.extend.period':
      return <ExtendVPNPeriodModal {...params} />;

    case 'referrals.payout':
      return <PayoutModal />;

    case 'referrals.payout-not-possible':
      return <PayoutNotPossibleModal />;

    case 'referrals.payout-setup':
      return <PayoutSetupModal {...params} />;

    case 'orders.experience.check':
      return <ExperienceCheckModal />;

    case 'order.status':
      return <OrderCheckoutStatusModal {...params} />;

    case 'account.email.verification':
      return <VerifyEmailModal />;

    case 'admin.proxies.events':
      return <AdminEventsModal {...params} />;

    case 'admin.proxies.changelog':
      return <AdminChangelogModal {...params} />;

    case 'admin.proxies.port.change':
      return <AdminChangePortModal {...params} />;

    case 'account.invoices.download':
      return <DownloadInvoicesModal {...params} />;

    default:
      return null;
  }
});

export { useShowModal };

export default { Provider };
