import type { LanguageDTO } from 'store/accounts/dtos';

import type { MultiFactorHardwareOptions, MultiFactorProvider, UserRole } from './types';

/**
 * @GET /captcha
 */
export type CaptchaDTO = {
  captchaEnabled: boolean;
  captchaSiteKey: string | null;
};

/**
 * @POST /login-check
 */
export type SignInPayload = {
  username: string;
  password: string;
  recaptchaToken?: string;
};

/**
 * @POST /registration
 */
export type SignUpPayload = {
  email: string;
  plainPassword: string;
  recaptchaToken?: string;
  locale: string;
};

/**
 * @POST /login-check
 * @POST /registration
 */
export type TokenDTO = {
  token: string;
  refreshToken: string;
};

/**
 * @POST /login-check
 * @GET /2fa-status
 */
export type MultiFactorDTO = {
  providers: MultiFactorProvider[];
  preferredProvider: MultiFactorProvider;
  options: MultiFactorHardwareOptions | null;
};

/**
 * @POST /login-check
 */
export type AuthenticationDTO = TokenDTO & {
  mfa: MultiFactorDTO | null;
};

/**
 * @POST /forgot-password-post
 */
export type ForgotPasswordPayload = {
  email: string;
};

/**
 * @POST /reset-password-post
 */
export type ResetPasswordPayload = {
  code: string;
  passwd: string;
  passwd2: string;
};

/**
 * @POST /2fa-check-api
 */
export type MultiFactorPayload = {
  code: string;
  preferProvider: MultiFactorDTO['preferredProvider'];
};

/**
 * @GET /session
 */
export type SessionDTO = {
  userId: number;
  userIp: string | null;
  language: LanguageDTO | null;
  profileDetails?: ProfileDetailsDTO;
  roles?: UserRole[];
};

export enum VerificationStatusDTO {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED',
  VERIFICATION_SUCCESSFUL = 'VERIFICATION_SUCCESSFUL',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
}

export type VerificationRequestDTO = {
  status: VerificationStatusDTO;
  reason: string | null;
};

export type ProfileDetailsDTO = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  valid: boolean;
  isSuspended: boolean;
  suspensionReason?: string | null;
  suspensionReasonExplanation?: string | null;
  verificationRequest: VerificationRequestDTO;
  isProfileSkipped: boolean;
};
