import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Container = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),

  '& *': {
    maxWidth: 480,
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
  color: theme.palette.text.secondary,
}));

export default { Container, Subtitle };
