import { type ReactNode, useEffect, useMemo, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs from 'dayjs';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { CannyProvider } from 'components/Canny/CannyProvider';
import { FloatingButton } from 'components/FloatingButton';
import { Icon } from 'components/Icon';
import { paths } from 'paths';
import { useGetMaintenanceStatusQuery, useGetUserSessionQuery } from 'store/api';
import type { ITheme } from 'theme';

import { AppMaintenance } from './AppMaintenance';
import { AppNavbar } from './AppNavbar';
import { Sidebar } from './Sidebar';
import Styled from './styled';

type DashboardLayoutProps = {
  children?: ReactNode;
};

export function AppLayout({ children }: DashboardLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data: session } = useGetUserSessionQuery();
  const { pathname } = useLocation();

  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));

  const { data: maintenance, fulfilledTimeStamp, refetch } = useGetMaintenanceStatusQuery();

  const isMaintenanceVisible = useMemo(() => {
    if (!maintenance) return false;

    if (!maintenance.end || !maintenance.start) return false;

    return true;
  }, [maintenance]);

  const shouldRefetch = useMemo(() => {
    const fiveMinutesAhead = dayjs(fulfilledTimeStamp).add(5, 'minutes');

    const isTimeElapsed = dayjs().isAfter(fiveMinutesAhead);

    return (
      isTimeElapsed &&
      [paths.proxies.index, `${paths.proxies.index}/:proxyId`, paths.index].some((value) => matchPath(value, pathname))
    );
  }, [fulfilledTimeStamp, pathname]);

  useEffect(() => {
    if (!shouldRefetch) {
      return;
    }

    refetch();
  }, [refetch, shouldRefetch]);

  return (
    <CannyProvider>
      <Styled.DashboardLayoutRoot isMaintenanceVisible={isMaintenanceVisible}>
        <Styled.DashboardLayoutContentRoot>{children || <Outlet />}</Styled.DashboardLayoutContentRoot>
      </Styled.DashboardLayoutRoot>

      <AppMaintenance isVisible={isMaintenanceVisible} maintenance={maintenance} />

      <AppNavbar isMaintenanceVisible={isMaintenanceVisible} />

      <Sidebar
        isOpen={isSidebarOpen}
        isMaintenanceVisible={isMaintenanceVisible}
        onClose={() => setIsSidebarOpen(false)}
      />

      {isMobileDevice && (
        <FloatingButton onClick={() => setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen)}>
          <Styled.FloatingButtonBadge color="error" variant={session?.profileDetails?.isSuspended ? 'dot' : undefined}>
            <Icon name={isSidebarOpen ? 'cross' : 'menu'} iconStroke={(theme) => theme.palette.text.primary} />
          </Styled.FloatingButtonBadge>
        </FloatingButton>
      )}
    </CannyProvider>
  );
}
