import { type ReactNode, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import isObject from 'lodash/isObject';

import type { Option } from 'types';

import { Button } from './Button';
import Styled from './styled';

type SelectionBoxProps<T extends Option> = {
  data: T[];
  value: T['value'] | null;

  onItemClick: (item: T) => void;
  getOptionLabel: (option: T) => string;
  getOptionSubLabel?: (option: T) => string | ReactNode;
  size?: 'small' | 'medium';
};

export function SelectionBox<T extends Option>({
  data,
  value,
  onItemClick,
  getOptionLabel,
  getOptionSubLabel,
  size = 'medium',
}: SelectionBoxProps<T>) {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const isSelected = useCallback((item: T) => item.value === value, [value]);

  const getSubLabel = useCallback(
    (item: T) => {
      if (!item.subLabel) return null;

      if (!getOptionSubLabel && !isObject(item.subLabel)) {
        return (
          <Typography variant="caption" color="grey.500">
            {item.subLabel}
          </Typography>
        );
      }

      return getOptionSubLabel?.(item);
    },
    [getOptionSubLabel],
  );

  const handleChange = useCallback(
    (item: T) => {
      if (isSelected(item)) return;

      return onItemClick(item);
    },
    [isSelected, onItemClick],
  );

  return (
    <Grid container rowGap={2} columnGap={2} flexWrap={size === 'small' ? 'nowrap' : undefined}>
      {data.map((item, i) => (
        <Styled.Item key={`${item.label}-${i}`} xs={isMobileDevice ? 6 : 4} $size={size}>
          <Button fullWidth isSelected={isSelected(item)} onClick={() => handleChange(item)}>
            <Typography variant="subtitle" color={isSelected(item) ? 'text.primary' : 'grey.500'}>
              {getOptionLabel(item)}
            </Typography>

            {getSubLabel(item)}
          </Button>
        </Styled.Item>
      ))}
    </Grid>
  );
}
