import { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useAdminModal } from 'hooks/useAdminModal';
import { useGetVPNEventsQuery } from 'store/api';
import type { VPNModel } from 'store/vpns/models';

import Styled from './styled';

export type VPNAdminEventsModalProps = {
  vpnId: VPNModel['id'];
};

export function VPNAdminEventsModal({ vpnId }: VPNAdminEventsModalProps) {
  const { t } = useTranslation();
  const { maxModalWidth, onClose } = useAdminModal();

  const { data = [], isLoading } = useGetVPNEventsQuery(vpnId, { skip: !vpnId });

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={onClose}>
        {t('common:buttons.close')}
      </Button>,
    ],
    [onClose, t],
  );

  return (
    <ModalContainer
      actions={actions}
      maxWidth={maxModalWidth}
      title={t('vpns.modals.eventlog.title')}
      onClose={onClose}
    >
      <Grid container direction="column">
        {isLoading ? (
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={80} />
          </Grid>
        ) : (
          data?.map(({ createdAt, message }, i) => (
            <Styled.EventItem key={`proxy-event-${i}`} withDivider={i !== data.length - 1} rowGap={1}>
              <Grid container alignItems="center">
                <Grid item xs={9} component={Typography} variant="body-2">
                  {message}
                </Grid>

                <Grid item xs={3} component={Typography} variant="caption" textAlign="end">
                  {dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </Grid>
              </Grid>
            </Styled.EventItem>
          ))
        )}
      </Grid>
    </ModalContainer>
  );
}
