import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { TabProps as MuiTabProps, TabTypeMap } from '@mui/material/Tab';

import Styled from './styled';

export type TabProps = MuiTabProps;

export const Tab: OverridableComponent<TabTypeMap<TabProps>> = ({ ...props }: TabProps) => {
  return <Styled.Tab {...props} />;
};
