import { useMemo } from 'react';

import { paths } from 'paths';
import { useGetUserSessionQuery } from 'store/api';

import { useOrderFlow } from './useOrderFlow';

const NAV_ITEMS = [
  { label: 'common:order.steps.type', path: paths.order.index },
  { label: 'common:order.steps.setup', path: paths.order.proxy },
  { label: 'common:order.steps.vpnPlan', path: paths.order.vpn },
  { label: 'common:order.steps.details', path: paths.order.details },
  { label: 'common:order.steps.checkout', path: paths.order.checkout },
];

export function useOrderNavigationItems() {
  const { isVPNFlow, isMarketplaceFlow } = useOrderFlow();

  const { data: session, isLoading } = useGetUserSessionQuery();

  const isProfileDetailsValid = useMemo(
    () => session?.profileDetails?.valid || session?.profileDetails?.isProfileSkipped,
    [session?.profileDetails],
  );

  return useMemo(() => {
    return (
      NAV_ITEMS
        // by flow
        .filter(({ path }) =>
          isVPNFlow ? ![paths.order.index, paths.order.proxy].includes(path) : path !== paths.order.vpn,
        )
        .filter(({ path }) => (isMarketplaceFlow ? ![paths.order.index].includes(path) : true))
        // by profile details
        .filter(({ path }) => path !== paths.order.details || (!isProfileDetailsValid && !isLoading))
    );
  }, [isLoading, isMarketplaceFlow, isProfileDetailsValid, isVPNFlow]);
}
