import { useCallback, useRef } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import type { ITheme } from 'theme';

import { useFreshchatAPI } from './useFreshchatAPI';

export function useListenFreshchatClose() {
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));
  const closureIsMobile = useRef(isMobileDevice);
  closureIsMobile.current = isMobileDevice;

  const { hideFreshchat } = useFreshchatAPI();

  const listenFreshchatClose = useCallback(() => {
    window.fcWidget?.on('widget:closed', () => {
      // hide on close for mobile devices
      if (closureIsMobile.current) {
        hideFreshchat();
      }
    });
  }, [hideFreshchat]);

  return listenFreshchatClose;
}
