import { useMemo } from 'react';

import type { SerializedError } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { type AxiosBaseQueryError, type ValidationError, isAxiosBaseQueryError } from 'store/base';
import { getIndexFromKey, truncateArrayPath } from 'utils/stringHelpers';

/**
 * Transforms error response into Formik-friendly object. For example:
 * { ips[0]: [{ "code": "INVALID_IP" }] } will be { ipWhitelist: ["INVALID_IP"] }
 *
 * @param error - Validation error response
 * @param keyMapperFn - Function to tranform reponse keys into form keys
 * @returns - Formik-friendly validation error object
 */
export function getValidationErrors<T>(error: unknown, keyMapperFn?: (responseKey: keyof T) => string) {
  if (!error || !isAxiosBaseQueryError(error) || typeof error.errors !== 'object') return;

  return Object.keys(error.errors).reduce<Record<string, string | string[]>>((memo, nextKey) => {
    const indexInArray = getIndexFromKey(nextKey);
    const keyValue = (error.errors as Required<ValidationError>['errors'])[nextKey]
      .map(({ code, parameters }) => t(`errors:${code}`, parameters))
      .join(' ');

    // if not array - transform normally
    if (indexInArray === -1) {
      const mappedKey = keyMapperFn?.(nextKey as keyof T) || nextKey;

      // dont let string keys override existing array keys
      if (!memo[mappedKey]) {
        memo[mappedKey] = keyValue;
      }
    }
    // transform array
    else {
      const truncatedKey = truncateArrayPath(nextKey);
      const mappedKey = keyMapperFn?.(truncatedKey as keyof T) || truncatedKey;

      if (!memo[mappedKey] || typeof memo[mappedKey] === 'string') {
        memo[mappedKey] = [];
      }

      memo[mappedKey][indexInArray] = keyValue;
    }

    return memo;
  }, {});
}

export function useRequestError(error: AxiosBaseQueryError | SerializedError | undefined) {
  const validationErrors = useMemo(() => getValidationErrors(error), [error]);

  return { validationErrors };
}
