import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const PriceContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export default { PriceContainer };
