import { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import { Input, type InputProps } from 'components/form-partials/Input';

export type NumberInputProps = Omit<InputProps, 'defaultValue'> & {
  defaultValue?: string | number | null;
  unit?: string | null;
};

export function NumberInput({ id, value: propValue, type, size = 'medium', unit, ...props }: NumberInputProps) {
  const { setFieldValue, values, handleBlur } = useFormikContext<Record<string, unknown>>();

  const handleChange = useCallback(
    ({ value }: NumberFormatValues) => {
      const parsedValue = parseFloat(value);

      setFieldValue(props.name, Number.isNaN(parsedValue) ? 0 : parsedValue);
    },
    [props.name, setFieldValue],
  );

  return (
    <NumericFormat
      fullWidth
      value={getIn(values, props.name)}
      suffix={unit ? ` ${unit}` : ''}
      onValueChange={handleChange}
      onBlur={handleBlur}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      customInput={Input}
      size={size}
      {...props}
    />
  );
}
