import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiTab, { tabClasses } from '@mui/material/Tab';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  flexWrap: 'wrap',
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
  lineHeight: '48px',
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  padding: 0,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  textTransform: 'none',
  color: theme.palette.text.secondary,
  minHeight: 'auto',
  minWidth: 'auto',
  paddingTop: 8,
  paddingBottom: 20,

  [`&.${tabClasses.disabled}`]: {
    color: theme.palette.text.secondary,
  },

  '&:hover': {
    textDecoration: 'none',
  },

  '& + &': {
    marginLeft: 16,
  },

  [`&.${tabClasses.selected}`]: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
}));

export default { Container, Tab };
