import { forwardRef } from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

type ModalContainerProps = BoxProps & {
  contentScrollable?: boolean;
};

const ModalContainer = styled(
  forwardRef<HTMLDivElement, ModalContainerProps>(({ contentScrollable, ...props }, ref) => (
    <Box ref={ref} {...props} />
  )),
)(({ contentScrollable, theme }) => ({
  maxWidth: 420,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 16px 24px rgba(16, 26, 51, 0.16)',
  borderRadius: theme.spacing(4),
  padding: theme.spacing(4),
  outline: 'none',

  display: 'grid',
  gridTemplateRows: 'repeat(1, 1fr)',
  maxHeight: '80%',
  rowGap: theme.spacing(4),

  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),

  ...(!contentScrollable && {
    overflowX: 'hidden',
    overflowY: 'auto',
  }),
}));

const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1),
  gridArea: 'header',
}));

type ModalContentProps = BoxProps & {
  scrollable?: boolean;
};

const ModalContent = styled(({ scrollable, ...props }: ModalContentProps) => <Box {...props} />)(({ scrollable }) => ({
  maxHeight: '100%',
  gridArea: 'content',

  ...(scrollable && {
    overflowX: 'hidden',
    overflowY: 'auto',
  }),
}));

const ModalFooter = styled(Box)({
  gridArea: 'footer',
});

export default { ModalContainer, ModalHeader, ModalContent, ModalFooter };
