import { toast, type ToastOptions } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export class ToastManager {
  static success(text: string, short = false) {
    toast.success(text, { toastId: uuidv4(), ...(short && { autoClose: 2000 }) });
  }

  static error(text: string, options?: ToastOptions) {
    toast.error(text, { toastId: uuidv4(), ...options });
  }
}
