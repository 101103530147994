import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetUserSessionQuery } from 'store/api';
import { ToastManager } from 'utils/toast';

import { useSignOut } from './useSignOut';

export function useTerminatedCheck() {
  const { t } = useTranslation();
  const { isLoading, error } = useGetUserSessionQuery();

  const signOut = useSignOut();

  const check = useCallback(() => {
    if (isLoading) return;

    if (!error) return;

    if (error.message !== 'ACCOUNT_HAS_BEEN_TERMINATED') return;

    ToastManager.error(t('errors:ACCOUNT_HAS_BEEN_TERMINATED'), { toastId: 'ACCOUNT_TERMINATION' });

    signOut();
  }, [error, isLoading, signOut, t]);

  useEffect(() => {
    check();
  }, [check]);
}
