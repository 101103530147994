import { Outlet } from 'react-router-dom';

import { CannyProvider } from 'components/Canny/CannyProvider';

import { FeatureRequestLayoutAppBar } from './FeatureRequestLayoutAppBar';
import Styled from './styled';

export function FeatureRequestLayout() {
  return (
    <CannyProvider>
      <Styled.LayoutRoot>
        <FeatureRequestLayoutAppBar />

        <Styled.FeatureRequestContainer>
          <Outlet />
        </Styled.FeatureRequestContainer>
      </Styled.LayoutRoot>
    </CannyProvider>
  );
}
