import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import { styled } from '@mui/material/styles';

const IconWrapper = styled(
  ({ size, ...props }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { size: number }) => (
    <div {...props} />
  ),
)(({ size }) => ({
  width: size,
  height: size,

  svg: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

export default { IconWrapper };
