import type { VPNAdminDetailsDto, VPNChangelogItemDTO, VPNDetailsResponse, VPNEventDTO } from './dtos';
import type { VPNAdminModel, VPNChangelogItem, VPNEvent, VPNModel } from './models';

export function toVPNModel({
  id,
  expiresAt,
  createdAt,
  autoExtendEnabled,
  status,
  authentication,
}: VPNDetailsResponse): VPNModel {
  return {
    id,
    status,
    ordered: createdAt,
    expires: expiresAt,
    isAutoExtend: autoExtendEnabled,
    authentication,
  };
}

export function toVPNAdminModel({
  id,
  status,
  createdAt,
  expiresAt,
  autoExtendEnabled,
  userId,
  userName,
  proposition,
}: VPNAdminDetailsDto): VPNAdminModel {
  return {
    id,
    status,
    ordered: createdAt,
    expires: expiresAt,
    isAutoExtend: autoExtendEnabled,
    userName,
    userId,
    proposition,
  };
}

export function toVPNHistory({
  id,
  fieldName,
  oldValue,
  newValue,
  createdAt,
  changedBy,
}: VPNChangelogItemDTO): VPNChangelogItem {
  return {
    id,
    fieldName,
    oldValue,
    newValue,
    createdAt,
    changedBy,
  };
}

export function toVPNEvent({ createdAt, message }: VPNEventDTO): VPNEvent {
  return {
    createdAt,
    message,
  };
}
