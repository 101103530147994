import { useMemo } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { Result } from 'components/Result';
import { Table, type TableColumn } from 'components/Table';
import { useGetProxyChangelogQuery } from 'store/api';
import type { ProxyChangelogItemModel, ProxyModel } from 'store/proxies/models';

export type AdminChangelogModalProps = {
  proxyId: ProxyModel['id'];
};

export function AdminChangelogModal({ proxyId }: AdminChangelogModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { data, isLoading, isFetching } = useGetProxyChangelogQuery(proxyId, { skip: !proxyId });

  const columns = useMemo<Array<TableColumn<ProxyChangelogItemModel>>>(
    () => [
      {
        key: 'fieldName',
        title: t('proxies.modals.changelog.columns.fieldName'),
        renderCell: (item) => item.fieldName,
      },
      {
        key: 'oldValue',
        title: t('proxies.modals.changelog.columns.oldValue'),
        renderCell: (item) => item.oldValue,
      },
      {
        key: 'newValue',
        title: t('proxies.modals.changelog.columns.newValue'),
        renderCell: (item) => item.newValue,
      },
      {
        key: 'createdAt',
        title: t('proxies.modals.changelog.columns.changedAt'),
        renderCell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        key: 'changedBy',
        title: t('proxies.modals.changelog.columns.changedBy'),
        renderCell: (item) => item.changedBy,
      },
    ],
    [t],
  );

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.close')}
      </Button>,
    ],
    [hideModal, t],
  );

  return (
    <ModalContainer
      actions={actions}
      title={t('proxies.modals.changelog.title')}
      onClose={hideModal}
      scrollable
      sx={{ '> .MuiBox-root': { maxWidth: '80%', width: 'auto' } }}
    >
      <Table
        data={data ?? []}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        keyExtractor={(item) => `${item.fieldName}-${item.createdAt}`}
        EmptyComponent={<Result iconName="document-text" title={t('proxies.modals.changelog.empty.title')} />}
      />
    </ModalContainer>
  );
}
