import { useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';
import type { MaintenanceStatus } from 'store/proxies/models';

import Styled from './styled';

type AppMaintenanceProps = {
  isVisible: boolean;
  maintenance?: MaintenanceStatus | null;
};

export function AppMaintenance({ isVisible, maintenance }: AppMaintenanceProps) {
  const { t } = useTranslation();

  const date = useMemo(() => {
    if (!maintenance) return '';

    const start = dayjs(maintenance.start);
    const end = dayjs(maintenance.end);

    return `${start.format('YYYY-MM-DD HH:mm')} - ${end.format('YYYY-MM-DD HH:mm')}`;
  }, [maintenance]);

  return (
    <Collapse in={isVisible} unmountOnExit>
      <Styled.MaintenanceContainer>
        <Stack>
          <Icon name="danger" size={16} />

          <Typography variant="subtitle" color="grey.500">
            {t('common:maintenance.title')}
          </Typography>
        </Stack>

        <Typography variant="body-2" color="grey.500">
          {t('common:maintenance.subtitle', { date })}
        </Typography>
      </Styled.MaintenanceContainer>
    </Collapse>
  );
}
