import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { Field, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useDisableVPNAutoExtendMutation, useEnableVPNAutoExtendMutation, useGetUserVPNQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { VPNModel } from 'store/vpns/models';
import { ToastManager } from 'utils/toast';

import Styled from './styled';

type AutoExtendValues = {
  isAutoExtendEnabled: boolean;
};

export type VPNAutoExtendModalProps = {
  vpnId: VPNModel['id'];
};

export function VPNAutoExtendModal({ vpnId }: VPNAutoExtendModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const hideModal = useHideModal();
  const { data: vpn, isLoading } = useGetUserVPNQuery(vpnId);

  const [enableAutoExtend] = useEnableVPNAutoExtendMutation();
  const [disableAutoExtend] = useDisableVPNAutoExtendMutation();

  return (
    <Formik<AutoExtendValues>
      initialValues={{ isAutoExtendEnabled: vpn?.isAutoExtend || false }}
      enableReinitialize
      onSubmit={async ({ isAutoExtendEnabled }) => {
        try {
          if (vpn) {
            if (isAutoExtendEnabled) {
              await enableAutoExtend(vpnId).unwrap();
              posthog?.capture(posthogEvents.vpnAutoExtendEnabled);

              ToastManager.success(t('vpns.modals.autoExtend.success.enabled'));
            } else {
              await disableAutoExtend(vpnId).unwrap();
              posthog?.capture(posthogEvents.vpnAutoExtendDisabled);

              ToastManager.success(t('vpns.modals.autoExtend.success.disabled'));
            }

            hideModal();

            return;
          }

          ToastManager.error(t('vpns.modals.extendPeriod.failure'));
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('vpns.modals.extendPeriod.failure'));
          }
        }
      }}
    >
      {({ isSubmitting, handleSubmit, dirty }) => (
        <ModalContainer
          component="form"
          containerProps={{ onSubmit: handleSubmit, noValidate: true }}
          title={t('vpns.modals.autoExtend.title')}
          subtitle={t('vpns.modals.autoExtend.subtitle')}
          actions={[
            <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
              {t('common:buttons.cancel')}
            </Button>,
            <Button key="verify" type="submit" fullWidth disabled={isLoading || !dirty} loading={isSubmitting}>
              {t('common:buttons.confirm')}
            </Button>,
          ]}
        >
          <Styled.PriceContainer>
            <Typography variant="title">{t('common:form.isAutoExtendEnabled')}</Typography>
            {isLoading ? (
              <Skeleton width={40} height={24} />
            ) : (
              <Field component={Switch} disabled={isSubmitting} type="checkbox" name="isAutoExtendEnabled" />
            )}
          </Styled.PriceContainer>
        </ModalContainer>
      )}
    </Formik>
  );
}
