import { type ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';

import Box, { boxClasses } from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';

import Styled from './styled';
import type { NavItemConfig } from '../types';

type NavItemProps = NavItemConfig & {
  onClose?: () => void;
};

export const NavItem = forwardRef<HTMLLIElement, NavItemProps>(function NavItemComponent(
  { title, disabled, external, href, icon, label, labelColor, onClick, onClose },
  ref,
) {
  const location = useLocation();

  const isRoot = useMemo(() => href === paths.index, [href]);

  const isLinkActive = useMemo(() => {
    return isRoot || !href ? location.pathname === href : location.pathname.includes(href);
  }, [href, isRoot, location.pathname]);

  const props = useMemo<ComponentPropsWithoutRef<typeof Box>>(() => {
    if (href) {
      return {
        component: RouterLink,
        href,
        target: external ? '_blank' : undefined,
        rel: external ? 'noreferrer' : undefined,
        onClick: (e) => {
          onClose?.();
          onClick?.(e);
        },
      };
    }

    return {
      role: 'button',
      onClick: (e) => {
        onClose?.();

        onClick?.(e);
      },
    };
  }, [external, href, onClick, onClose]);

  return (
    <Collapse in={!disabled} unmountOnExit>
      <Box ref={ref} component="li" sx={{ userSelect: 'none' }}>
        <Box
          {...props}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: '0 0 auto',
            gap: '16px',

            py: '8px',
            position: 'relative',

            '&:hover': { textDecoration: 'none', [`& .${boxClasses.root}`]: { opacity: 0.8 } },
          }}
          tabIndex={0}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flex="0 0 auto">
            {icon && <Styled.Icon name={icon} size="small" isActive={isLinkActive} />}
          </Box>

          <Box display="inline-flex" flex="1 1 auto">
            <Typography
              component="span"
              variant="body-2"
              color={isLinkActive ? 'text.primary' : 'grey.500'}
              fontWeight={isLinkActive ? '700' : '400'}
            >
              {t(`common:sidebar.${title}`)}
            </Typography>
          </Box>

          {label && <Chip color={labelColor} label={label} size="small" />}
        </Box>
      </Box>
    </Collapse>
  );
});
