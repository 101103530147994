import { useCallback } from 'react';

export function useFreshchatAPI() {
  const openFreshchat = useCallback(() => {
    window.fcWidget?.show();
    window.fcWidget?.open();
  }, []);

  const hideFreshchat = useCallback(() => {
    window.fcWidget?.hide();
  }, []);

  return {
    openFreshchat,
    hideFreshchat,
  };
}
