import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow/arrow-down.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow/arrow-right.svg';
import { ReactComponent as ArrowUpDownIcon } from 'assets/icons/arrow/arrow-up-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow/arrow-up.svg';
import { ReactComponent as CircleArrowUp } from 'assets/icons/arrow/circle-arrow-up.svg';
import { ReactComponent as ForwardSquareIcon } from 'assets/icons/arrow/forward-square.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/arrow/logout.svg';
import { ReactComponent as ReceiveSquareIcon } from 'assets/icons/arrow/receive-square.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/arrow/refresh-2.svg';

export const arrow = {
  'arrow-down': ArrowDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-up-down': ArrowUpDownIcon,
  'circle-arrow-up': CircleArrowUp,
  'forward-square': ForwardSquareIcon,
  logout: LogoutIcon,
  'receive-square': ReceiveSquareIcon,
  refresh: RefreshIcon,
};
