import type { PaymentMetadata, PaymentOption } from 'modules/billing/types';

import type { TopUpStatus } from './types';

export type AccountBalanceResponse = {
  balance: number;
};

export type SaveProfilePayload = {
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2?: string;
  city: string;
  countryCode?: string;
  postcode: string;
  companyName?: string;
  vatCode?: string;
};

export type EmailNotificationsPayload = {
  isEmailCommunicationEnabled: boolean;
};

export type EmailNotificationsResponse = EmailNotificationsPayload;

export type ChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export type AccountProfileResponse = {
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  countryCode: string | null;
  postcode: string | null;
  companyName: string | null;
  vatCode: string | null;
};

export type AccountAuthenticationMethods = {
  totp: boolean;
  webauthn: boolean;
};

export type AccountEnableTOTPPayload = {
  code: string | null;
};

export type AccountEnableTOTPResponse = {
  secret: string;
  dataUri: string;
};

export type AccountDisable2FAPayload = {
  password: string;
};

export type AccountEnableWebAuthnPayload = {
  attestation: string | null;
};

export type Invoice = {
  id: number;
  createdAt: string;
  invoiceNumber: string;
  amount: number;
  url: string;
};

export type Transaction = {
  description: string;
  amount: number;
  type: string;
  createdAt: string;
  paymentFee: number;
  subtotal: number;
};

export type BillingPreferences = {
  autoTopUp: {
    enabled: boolean;
    value: number;
    threshold: number;
  };
};

export enum LanguageDTO {
  CN = 'CN',
  EN = 'EN',
  ES = 'ES',
  RU = 'RU',
  VI = 'VI',
}

export type ChangeLanguagePayload = {
  language: LanguageDTO;
};

export type APIKeyDTO = {
  apiKey: string;
  apiSecret: string;
  createdAt: string;
};

// #region Payments
/**
 * @response Credit card details
 *
 * @endpoint GET /account/billing/card-details
 */
export type CreditCardDTO = {
  paymentMethodId: string;
  addedAt: string;
  brand: string;
  expMonth: number;
  expYear: number;
  lastFour: string;
};

/**
 * @response Add credit card
 *
 * @endpoint POST /account/billing/card/authorize
 */
export type AddCreditCardDTO = {
  authKey: string;
  authToken: string;
};

/**
 * @payload Auto TopUp
 *
 * @endpoint POST /account/billing/auto-top-up
 */
export type AutoTopUpPayload = {
  isEnabled: boolean;
  amount?: number;
  threshold?: number;
};

/**
 * @payload TopUp
 *
 * @endpoint POST /account/billing/top-up
 */
export type TopUpPayload = {
  amount: number;
  paymentOption: PaymentOption;
  paymentMethodMetadata?: string;
};

/**
 * @response TopUp
 *
 * @endpoint POST /account/billing/top-up
 */
export type TopUpDTO = PaymentMetadata & {
  id: string;
};

/**
 * @payload TopUp Status
 *
 * @endpoint GET /account/billing/top-up/{id}
 */
export type TopUpStatusPayload = {
  id: string;
};

/**
 * @response TopUp Status
 *
 * @endpoint GET /account/billing/top-up/{id}
 */
export type TopUpStatusDTO = {
  status: TopUpStatus;
};

/**
 * @payload TopUp Fees
 *
 * @endpoint POST /account/billing/top-up/fees
 */
export type TopUpFeesPayload = {
  paymentOption: PaymentOption;
  amount: number;
};

/**
 * @response TopUp Fees
 *
 * @endpoint POST /account/billing/top-up/fees
 */
export type PaymentFeeDTO = {
  finalPrice: number;
  finalPriceInCurrency: number;
  currency: 'EUR' | 'CNY' | 'PLN' | 'USD';
  paymentFee: number;
  subtotal: number;
};

// #endregion
