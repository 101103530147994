import { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Autocomplete } from 'components/form-partials/Autocomplete';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { SUPPORTED_VPN_MONTHS } from 'modules/orders/OrderVPNPage/consts';
import { BalanceSummary } from 'modules/proxies/modals/BalanceSummary';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

import ModalStyled from '../styled';

export type ExtendVPNPeriodFormProps = {
  price?: number;
  isLoading: boolean;
};

type ExtendVPNPeriodValues = {
  periodInMonths: number;
};

export function ExtendVPNPeriodForm({ price = 0, isLoading }: ExtendVPNPeriodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<ExtendVPNPeriodValues>();

  const periodOptions = useMemo<Option[]>(
    () => [
      {
        value: SUPPORTED_VPN_MONTHS[0],
        label: t('common:form.month_one', { count: 1 }),
      },
      ...SUPPORTED_VPN_MONTHS.slice(1).map((count) => ({
        value: count,
        label: t('common:form.month_other', { count }),
      })),
    ],
    [t],
  );

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isLoading}>
        {t('common:buttons.extend')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, isLoading, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendPeriod.title')}
      subtitle={t('proxies.modals.extendPeriod.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        <Autocomplete
          size="small"
          disableClearable
          options={periodOptions}
          label={t('common:form.timePeriod')}
          name="periodInMonths"
        />

        <Stack direction="column" rowGap={2}>
          <BalanceSummary isLoading={isLoading} price={price} />
          <Divider />

          <ModalStyled.PriceContainer>
            <Typography variant="title" color="grey.500">
              {t('common:form.total')}
            </Typography>
            {isLoading ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(price)}</Typography>
            )}
          </ModalStyled.PriceContainer>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
