import type {
  MaintenanceWindowDTO,
  ProxyAdminDetailsResponse,
  ProxyChangelogItemDTO,
  ProxyEventDTO,
  ProxyResponseAuthentication,
  ProxyResponseBandwidth,
  ProxyResponseConnection,
  ProxyResponseLocation,
  ProxyResponseMetadata,
  ProxyRoute,
} from './dtos';
import type { NetworkType, Protocol, ProxyStatus } from './types';

/**
 * @model
 *
 * GET /proxies/summary
 */
export type ProxySummaryModel = {
  active: number;
  inactive: number;
  expiring: number;
};

/**
 * @model
 *
 * GET /services/proxies
 */

type ProxyModelAuthentication = ProxyResponseAuthentication;

type ProxyModelBandwidth = ProxyResponseBandwidth;

type ProxyModelConnection = ProxyResponseConnection;

type ProxyModelLocation = ProxyResponseLocation;

type ProxyModelMetadata = ProxyResponseMetadata;

export enum ProxyModelAllowedAction {
  // Additional action to display menu item on Proxy List
  SHOW_DETAILS = 'SHOW_DETAILS',
  EXTEND_PERIOD = 'EXTEND_PERIOD',
  REACTIVATE = 'REACTIVATE',
  BUY_TRAFFIC = 'BUY_TRAFFIC',
  BUY_UPLINK_SPEED = 'BUY_UPLINK_SPEED',
  BUY_THREADS = 'BUY_THREADS',
  CHANGE_UPLINK_SPEED = 'CHANGE_UPLINK_SPEED',
  CHANGE_THREADS = 'CHANGE_THREADS',
  GENERATE_CREDENTIALS = 'GENERATE_CREDENTIALS',
  REGENERATE_CREDENTIALS = 'REGENERATE_CREDENTIALS',
  CHANGE_PROTOCOL = 'CHANGE_PROTOCOL',
  CHANGE_AUTHENTICATION_METHOD = 'CHANGE_AUTHENTICATION_METHOD',
  CHANGE_WHITELIST_IP = 'CHANGE_WHITELIST_IP',
  AUTO_EXTEND = 'AUTO_EXTEND',
  DOWNLOAD_CREDENTIALS = 'DOWNLOAD_CREDENTIALS',

  // Admin actions
  INITIALIZE = 'INITIALIZE',
  ACTIVATE = 'ACTIVATE',
  CANCEL = 'CANCEL',
  EXPIRE = 'EXPIRE',
  LIST_EVENTS = 'LIST_EVENTS',
  LIST_HISTORY_CHANGES = 'LIST_HISTORY_CHANGES',
  CHANGE_PORT = 'CHANGE_PORT',
  RUN_DIAGNOSTIC_ROUTINE = 'RUN_DIAGNOSTIC_ROUTINE',

  // Responsible for displaying Admin block
  ADMIN_DETAILS = 'ADMIN_DETAILS',
}

export type ProxyModel = {
  id: number;
  status: ProxyStatus;
  networkType: NetworkType;
  authentication: ProxyModelAuthentication;
  connection: ProxyModelConnection;
  proxyType: Protocol;
  createdAt: string;
  expiresAt: string | null;
  metadata: ProxyModelMetadata;
  bandwidth: ProxyModelBandwidth;
  location: ProxyModelLocation;
  note: string | null;
  activatedAt: string;
  updatedAt: string;
  autoExtendEnabled: boolean;
  autoExtendBandwidthAmount: number | null;
  actions: ProxyModelAllowedAction[];
  uplinkSpeed?: number;
  threads?: number;
  maintenanceWindows: MaintenanceWindowDTO[];
  routes: ProxyRoute[];
};

export type BandwidthSpeedPrice = {
  mbit: number;
  price: number;
};

export type BandwidthSpeedOptions = {
  currentUplinkSpeed: number;
  options: number[];
};

export type ThreadsUpgradePrice = {
  thread: number;
  price: number;
};

export type ThreadsChangeOptions = {
  currentThreads: number;
  options: number[];
};

export type ProxyListType = 'active' | 'inactive' | 'expiring';

export type ProxyISP = {
  id: string;
  name: string;
};

/**
 * @model
 *
 * GET /proxies/{id}/events
 */
export type ProxyEventModel = ProxyEventDTO;

/**
 * @model
 *
 * GET /proxies/{id}/change-history
 */
export type ProxyChangelogItemModel = ProxyChangelogItemDTO;

export type ProxyChangelogModel = ProxyChangelogItemModel[];

/**
 * @model
 *
 * GET /proxies/{id}/admin-details
 */
export type ProxyAdminDetailsModel = ProxyAdminDetailsResponse;

export type MaintenanceStatus = {
  start: string;
  end: string;
  note: string | null;
};

/**
 * GET /proxies/bulk/auto-extend
 */
export type BulkAutoExtendSettingsModel = {
  isBandwidthRequired: boolean;
  isAutoExtendEnabled: boolean;
};

/**
 * GET /proxies/{id}/admin/routes/details
 */
export type ProxySubnet = {
  id: string;
  label: string;
  countryCode: string;
};

/**
 * POST /proxies/{id}/admin/diagnostic-routine
 */
export type DiagnosticRoutineModel = {
  url: string;
  statusCode: number;
  statusMessage: string;
  time: number;
  metadata: Record<string, string>;
};
