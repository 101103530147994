import Drawer, { drawerClasses, type DrawerProps } from '@mui/material/Drawer';
import { type Breakpoint, styled } from '@mui/material/styles';

const MAINTENANCE_OFFSET: Record<Breakpoint, number> = {
  xs: 113,
  sm: 81,
  md: 65,
  lg: 57,
  xl: 37,
};

type SidebarProps = DrawerProps & {
  isMaintenanceVisible?: boolean;
};

const Sidebar = styled(({ isMaintenanceVisible, ...props }: SidebarProps) => <Drawer {...props} />)(
  ({ isMaintenanceVisible, theme }) => ({
    zIndex: theme.zIndex.appBar + 100,

    [`& .${drawerClasses.paper}`]: {
      backgroundColor: theme.palette.background.paper,
      width: 280,

      ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.xl, transition: theme.transitions.create('top') }),

      [theme.breakpoints.between('md', 'lg')]: { ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.md }) },

      [theme.breakpoints.between('lg', 'xl')]: { ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.lg }) },

      [theme.breakpoints.down('md')]: { width: 296, ...(isMaintenanceVisible && { top: 0 }) },
    },
  }),
);

export default { Sidebar };
