import { useEffect } from 'react';

import nprogress from 'nprogress';
import { useLocation } from 'react-router-dom';

import { useMounted } from './useMounted';

export function useNprogress() {
  const isMounted = useMounted();
  const { pathname } = useLocation();

  useEffect(() => {
    nprogress.start();

    if (isMounted()) {
      nprogress.done();
    }

    return () => {
      nprogress.done();
    };
  }, [pathname, isMounted]);
}
