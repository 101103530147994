import { type DetailedHTMLProps, type LiHTMLAttributes, useCallback, useMemo } from 'react';

import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Field, getIn, useFormikContext } from 'formik';
import type { AutocompleteProps as MuiAutocompleteProps } from 'formik-mui';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import type { Option } from 'types';
import { getFieldError } from 'utils/form';

import Styled from './styled';

// This is the new version of the Autocomplete for forms, that is going to replace existing one
export type AutocompleteProps<T extends Option, Multi extends boolean | undefined> = Omit<
  MuiAutocompleteProps<T, Multi, true | false, true | false>,
  'field' | 'form' | 'meta' | 'renderInput' | 'placeholder' | 'onChange'
> & {
  name: string;
  label?: string | null;
  placeholder?: string | null;
  value?: T | T[] | null;
  onChange?: (name: string, value?: string | number | Array<string | number | null> | undefined | null) => void;
  getListItemLabel?: (option: T) => string;
  shouldUpdateFormikValue?: boolean;
};

export function Autocomplete<T extends Option, Multi extends boolean | undefined>({
  getListItemLabel,
  shouldUpdateFormikValue = true,
  ...props
}: AutocompleteProps<T, Multi>) {
  const { t } = useTranslation();
  const { values, setFieldValue, touched, errors } = useFormikContext<Record<string, unknown>>();

  const error = useMemo(() => {
    const { hasError, isTouched, errorMessage } = getFieldError({ errors, touched, fieldName: props.name });

    return hasError && isTouched ? errorMessage : undefined;
  }, [errors, touched, props.name]);

  const valueOption = useMemo(() => {
    if (props.multiple) {
      return props.options.filter((o) => {
        const currentValue = getIn(values, props.name) as Array<string | number | object>;

        return includes(currentValue, o.value);
      });
    }

    return props.options.find((o) => isEqual(o.value, getIn(values, props.name))) ?? null;
  }, [props.options, props.multiple, props.name, values]);

  const renderList = useCallback(
    (option: T) => {
      if (getListItemLabel) {
        return getListItemLabel(option);
      }

      if (typeof option.value === 'number') {
        return t(option.label, { count: option.value });
      }

      return t(option.label);
    },
    [getListItemLabel, t],
  );

  const renderSubList = useCallback(
    (option: T) => {
      if (option.subLabel === undefined) {
        return null;
      }

      if (typeof option.subLabel !== 'string') return null;

      return (
        <Typography
          variant="caption"
          color={option.subLabel.toLowerCase().includes('outofstock') ? 'error.main' : 'text.secondary'}
        >
          {t(option.subLabel)}
        </Typography>
      );
    },
    [t],
  );

  return (
    <Styled.Container fullWidth={props.fullWidth}>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <Field
        getOptionLabel={(option: T) => t(option.label)}
        {...props}
        value={valueOption}
        component={Styled.Autocomplete}
        disableCloseOnSelect={props.multiple}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            name={props.name}
            placeholder={props.placeholder ? t(props.placeholder) || '' : ''}
            error={!!error}
            helperText={
              error
                ? t(error, {
                    field: t(`common:form.${props.name}`),
                  })
                : undefined
            }
          />
        )}
        onChange={(_: unknown, option?: T | T[]) => {
          if (!shouldUpdateFormikValue) {
            return props.onChange?.(
              props.name,
              Array.isArray(option) ? option.map(({ value }) => value) : option?.value,
            );
          }

          if (Array.isArray(option)) {
            setFieldValue(
              props.name,
              option.map(({ value }) => value),
            );
          } else {
            setFieldValue(props.name, option?.value);
          }

          return props.onChange?.(props.name, Array.isArray(option) ? option.map(({ value }) => value) : option?.value);
        }}
        renderOption={(liProps: DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>, option: T) => {
          const showSelected = Array.isArray(valueOption)
            ? valueOption.some(({ value: propOptionValue }) => propOptionValue === option.value)
            : option.value === valueOption?.value;

          return (
            <li {...liProps}>
              {option.renderLeftAdornment && <Styled.Adornment>{option.renderLeftAdornment()}</Styled.Adornment>}

              <Stack direction="column">
                {renderList(option)}
                {renderSubList(option)}
              </Stack>

              {option.renderRightAdornment ? (
                <Styled.Adornment adornmentPosition="right">
                  {option.renderRightAdornment()}

                  {showSelected && <Styled.SelectedIcon name="check" size="small" color="primary" />}
                </Styled.Adornment>
              ) : (
                showSelected && <Styled.SelectedIcon name="check" size="small" color="primary" />
              )}
            </li>
          );
        }}
        isOptionEqualToValue={(option: T, currentOption: T) => option.value === currentOption.value}
      />
    </Styled.Container>
  );
}
