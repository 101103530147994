import { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useGetUserSessionQuery } from 'store/api';
import type { ITheme } from 'theme';

import Styled from './styled';

type AccountSuspendedBannerProps = {
  horizontalLayout?: boolean;
};

export function AccountSuspendedBanner({ horizontalLayout }: AccountSuspendedBannerProps) {
  const { t } = useTranslation();
  const { data: session } = useGetUserSessionQuery();
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('sm'));

  const shouldApplyMobileStyles = useMemo(
    () => isMobileDevice || !horizontalLayout,
    [isMobileDevice, horizontalLayout],
  );

  const button = useMemo(
    () => (
      <Button fullWidth color="error" onClick={() => window.fcWidget?.open()}>
        {t('common:buttons.contactSupport')}
      </Button>
    ),
    [t],
  );

  return (
    <Styled.Container
      size={shouldApplyMobileStyles ? 'small' : 'large'}
      direction={shouldApplyMobileStyles ? 'vertical' : 'horizontal'}
      actions={isMobileDevice ? [button] : undefined}
    >
      <Grid
        container
        rowSpacing={2}
        justifyContent="space-between"
        alignItems={shouldApplyMobileStyles ? 'flex-start' : 'center'}
        direction={horizontalLayout ? 'row' : 'column'}
      >
        <Grid item>
          <Grid container rowSpacing={0.5} direction="column">
            <Grid item>
              <Typography variant="title" color="error.main">
                {t('common:accountSuspended.title')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body-2">{t('common:accountSuspended.subtitle')}</Typography>
              {session?.profileDetails?.suspensionReason && (
                <Typography variant="body-2" component="p">
                  {`${t('common:accountSuspended.reason')}: ${t(
                    `common:suspensionReasons.${session.profileDetails.suspensionReason}`,
                  )}`}
                </Typography>
              )}
            </Grid>

            {session?.profileDetails?.suspensionReasonExplanation && (
              <Grid item>
                <Typography variant="body-2" component="p">
                  {t('common:accountSuspended.reasonDetails')}: {session.profileDetails.suspensionReasonExplanation}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isMobileDevice && <Grid item>{button}</Grid>}
      </Grid>
    </Styled.Container>
  );
}
