import { ReactComponent as AddIcon } from 'assets/icons/essetional/add.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/essetional/check.svg';
import { ReactComponent as CloseCircleIcon } from 'assets/icons/essetional/close-circle.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/essetional/danger.svg';
import { ReactComponent as InformationCircleIcon } from 'assets/icons/essetional/information-circle.svg';
import { ReactComponent as MinusCircleIcon } from 'assets/icons/essetional/minus-circle.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/essetional/question.svg';
import { ReactComponent as TickCircleIcon } from 'assets/icons/essetional/tick-circle.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/essetional/trash.svg';
import { ReactComponent as VerifyIcon } from 'assets/icons/essetional/verify.svg';

export const essetional = {
  add: AddIcon,
  check: CheckIcon,
  'close-circle': CloseCircleIcon,
  danger: DangerIcon,
  'information-circle': InformationCircleIcon,
  'minus-circle': MinusCircleIcon,
  question: QuestionIcon,
  'tick-circle': TickCircleIcon,
  trash: TrashIcon,
  verify: VerifyIcon,
};
