import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { TopUpButton } from 'modules/billing/TopUpButton';
import { useGetUserAccountBalanceQuery } from 'store/api';

import Styled from './styled';

export function Balance() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetUserAccountBalanceQuery();

  return (
    <Styled.Container direction="row" alignItems="center" spacing={2}>
      <Stack flex="1 1 auto">
        {isLoading ? (
          <Skeleton variant="rounded" height={38} />
        ) : (
          <>
            <Typography variant="badge">{t('common:sidebar.balance')}</Typography>

            <Typography variant="title">{t('common:balance', { balance: data?.balance.toFixed(2) })}</Typography>
          </>
        )}
      </Stack>

      <TopUpButton color="success" />
    </Styled.Container>
  );
}
