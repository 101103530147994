import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.success.main, 0.16),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

export default { Container };
