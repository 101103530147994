import Grid, { type GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const Container = styled((props: Omit<GridProps, 'container'>) => <Grid container {...props} />)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'block',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

export default { Container };
