import { useState } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useBuyTrafficBulkMutation, useExtendProxyBandwidthMutation, useGetUserSessionQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { ProxyModel } from 'store/proxies/models';
import { ToastManager } from 'utils/toast';

import { ExtendBandwidthForm } from './ExtendBandwidthForm';

export type ExtendBandwidthModalProps = {
  proxies: ProxyModel[];
};

export function ExtendBandwidthModal({ proxies }: ExtendBandwidthModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [buyTrafficSingle] = useExtendProxyBandwidthMutation();
  const [buyTrafficBulk] = useBuyTrafficBulkMutation();
  const hideModal = useHideModal();
  const { data: session } = useGetUserSessionQuery();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  return (
    <Formik
      initialValues={{ bandwidth: 1 }}
      onSubmit={async ({ bandwidth }) => {
        try {
          const proxyIds = proxies.map(({ id }) => id);

          if (proxyIds.length > 1) {
            await buyTrafficBulk({ ids: proxyIds, trafficInGB: bandwidth }).unwrap();

            proxyIds.forEach((proxyId) => {
              const foundProxy = proxies.find(({ id }) => id === proxyId);

              GA_EVENTS.proxyBandwidthPurchased({
                userId: session?.userId,
                value: singleItemPrice,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                networkType: foundProxy!.networkType,
                proxy_id: proxyId,
                bandwidth,
              });
            });

            posthog?.capture(posthogEvents.proxyExtendBandwidthBulk, {
              proxyIds,
              total: proxyIds.length,
            });
          } else {
            await buyTrafficSingle({ proxyId: proxyIds[0], bandwidth }).unwrap();

            GA_EVENTS.proxyBandwidthPurchased({
              userId: session?.userId,
              value: singleItemPrice,
              networkType: proxies[0].networkType,
              proxy_id: proxyIds[0],
              bandwidth,
            });

            posthog?.capture(posthogEvents.proxyExtendBandwidth);
          }

          ToastManager.success(t('proxies.modals.extendBandwidth.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('proxies.modals.extendPeriod.failure'));
          }
        }
      }}
    >
      <ExtendBandwidthForm proxies={proxies} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
