import { useEffect } from 'react';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Outlet, useSearchParams } from 'react-router-dom';

import { Logo } from 'components/Logo';
import { LanguageDropdown } from 'modules/common/Language/LanguageDropdown';
import type { ITheme } from 'theme';
import { PersistService } from 'utils/persist';

import Styled from './styled';

export function AuthLayout() {
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();

  const renderAdornment = () => {
    return isMobileDevice ? (
      <Styled.MobileLayoutAdornment>
        <Logo />

        <Box>
          <LanguageDropdown iconOnly />
        </Box>
      </Styled.MobileLayoutAdornment>
    ) : (
      <Styled.DesktopLayoutAdornment>
        <Logo white />
        <Styled.AuthVectorWrapper />
      </Styled.DesktopLayoutAdornment>
    );
  };

  useEffect(() => {
    const returnTo = searchParams.get('returnTo');

    if (returnTo) {
      PersistService.set('redirect.url', returnTo);
    }
  }, [searchParams]);

  return (
    <Styled.LayoutRoot $isMobile={isMobileDevice}>
      {renderAdornment()}

      {!isMobileDevice && <LanguageDropdown floating iconOnly />}

      <Styled.RightSideWrapper>
        <Outlet />
      </Styled.RightSideWrapper>
    </Styled.LayoutRoot>
  );
}
