import { useEffect, useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { TextButton } from 'components/TextButton';
import { useEmailVerification } from 'hooks/useEmailVerification';
import { ToastManager } from 'utils/toast';

import Styled from './styled';

export function VerifyEmailModal() {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { session, handleResendVerificationEmail } = useEmailVerification(true);

  const actions = useMemo(
    () => [
      <Button fullWidth onClick={hideModal}>
        {t('common:buttons.gotIt')}
      </Button>,
    ],
    [hideModal, t],
  );

  useEffect(() => {
    if (!session?.profileDetails?.isActive) return;

    ToastManager.success(t('account.modals.emailVerification.success'));
    hideModal();
  }, [hideModal, session?.profileDetails?.isActive, t]);

  return (
    <ModalContainer
      title={t('account.modals.emailVerification.title')}
      subtitle={
        <>
          <Trans i18nKey="account.modals.emailVerification.subtitle">We've sent a verification link to</Trans>
          <Typography component="span" variant="body-1" color="text.primary">
            {session?.profileDetails?.email}
          </Typography>
        </>
      }
      actions={actions}
    >
      <Styled.Container>
        <Typography variant="body-1" color="grey.500">
          <Trans i18nKey="account.modals.emailVerification.content">
            Didn't receive an email?
            <TextButton
              onClick={handleResendVerificationEmail}
              labelVariant="body-1"
              labelProps={{ color: 'primary.main' }}
            >
              Resend
            </TextButton>
          </Trans>
        </Typography>
      </Styled.Container>
    </ModalContainer>
  );
}
