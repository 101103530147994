import { forwardRef } from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import MuiCard, { type CardProps as MuiCardProps } from '@mui/material/Card';
import Grid, { type GridProps } from '@mui/material/Grid';
import { styled, alpha } from '@mui/material/styles';

export type CardType = 'light' | 'light-elevated' | 'grey' | 'dark' | 'bordered' | 'warning';

export type Direction = 'horizontal' | 'vertical';

type CardProps = MuiCardProps & {
  type: CardType;
  size?: 'large' | 'small';
};

const Card = styled(
  forwardRef<HTMLDivElement, CardProps>(({ size, type, ...props }, ref) => <MuiCard ref={ref} {...props} />),
)(({ size = 'large', type, theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),

  ...(size === 'small' && {
    padding: theme.spacing(2),
  }),

  ...(type === 'grey' && {
    backgroundColor: theme.palette.background.default,
  }),

  ...(type === 'dark' && {
    backgroundColor: theme.palette.text.primary,
  }),

  ...(type === 'light' && {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  }),

  ...(type === 'warning' && {
    backgroundColor: alpha(theme.palette.warning.main, 0.48),
  }),

  ...(type === 'light-elevated' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 0px 0px 1px ${theme.palette.divider}, 0px 8px 16px ${theme.palette.divider}`,
  }),
}));

type CardContentProps = BoxProps & {
  direction: Direction;
  centered?: boolean;
};

const CardContent = styled(({ direction, centered, ...props }: CardContentProps) => <Box {...props} />)(
  ({ direction, centered }) => ({
    display: 'flex',
    position: 'relative',
    width: '100%',

    ...(direction === 'horizontal' && {
      justifyContent: 'space-between',
      ...(centered && { alignItems: 'center' }),
    }),

    ...(direction === 'vertical' && {
      flexDirection: 'column',
    }),
  }),
);

const CardActionsContainer = styled(
  ({
    direction,
    size,
    ...props
  }: Omit<GridProps, 'container' | 'direction'> & { direction: Direction; size: CardProps['size'] }) => (
    <Grid container {...props} />
  ),
)(({ theme, size, direction }) => ({
  justifyContent: 'flex-end',

  ...(direction === 'vertical' && {
    marginTop: 0,
  }),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: theme.spacing(size === 'small' ? 1 : 2),
  },
}));

export default {
  Card,
  CardContent,
  CardActionsContainer,
};
