import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import SvgIcon, { type SvgIconProps as MuiSvgIconProps, type SvgIconTypeMap } from '@mui/material/SvgIcon';

import type { IconProps } from './types';

type SvgIconProps = MuiSvgIconProps & {
  $pathColors: { stroke?: string; fill?: string };
};

const MuiSvgIcon = styled(({ $pathColors, ...props }: SvgIconProps) => <SvgIcon {...props} />)(({ $pathColors }) => ({
  display: 'block',
  width: '100%',
  height: 'auto',
  '& path': $pathColors,
})) as OverridableComponent<SvgIconTypeMap<{ $pathColors: { stroke?: string; fill?: string } }>>;

const IconWrapper = styled(
  ({
    $size,
    ...props
  }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { $size: IconProps['size'] }) => (
    <div {...props} />
  ),
)(({ $size }) => {
  if (typeof $size === 'number') return { width: $size, height: $size, minWidth: $size };

  if ($size === 'large') return { width: 24, height: 24, minWidth: 24 };

  return { width: 16, height: 16, minWidth: 16 };
});

export default { MuiSvgIcon, IconWrapper };
