import { useEffect, useRef } from 'react';

import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import { Input } from 'components/form-partials/Input';
import { CurrencyFormatter } from 'utils/currency';

import { useTopUpModalContext } from '../context';
import type { TopUpValues } from '../types';

export function AmountField() {
  const { t } = useTranslation();
  const { values, handleBlur, setFieldValue } = useFormikContext<TopUpValues>();
  const { handleUpdateFee } = useTopUpModalContext();

  const debouncedChange = useRef(
    debounce(({ value }: NumberFormatValues) => {
      const parsedValue = parseFloat(value);

      const amount = Number.isNaN(parsedValue) ? 0 : parsedValue;

      setFieldValue('amount', amount, true);
    }, 300),
  ).current;

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  }, [debouncedChange]);

  useEffect(() => {
    handleUpdateFee(values.amount, values.type);
  }, [handleUpdateFee, values.amount, values.type]);

  return (
    <NumericFormat
      fullWidth
      name="amount"
      prefix="$ "
      label={t('common:form.currencyAmount', { currency: 'USD' })}
      placeholder={CurrencyFormatter.format(9)}
      value={values.amount}
      onValueChange={debouncedChange}
      onBlur={handleBlur}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      customInput={Input}
      size="medium"
    />
  );
}
