import { loadStripe } from '@stripe/stripe-js';

import { getStripeKey } from './helpers';
import type { PaymentOptionType } from '../types';

/**
 * Main stripe instance used by <Elements /> provider
 */
export const stripePromise = loadStripe(getStripeKey());

/**
 * List of payment options that cannot be handled by <PaymentElement />.
 * Those payments need to be handled separately because of the for example redirection logic,
 * and providing information related to that payment.
 */
export const PAYMENT_TYPES_WITH_CUSTOM_PAYMENT_AND_REDIRECT: PaymentOptionType[] = ['alipay', 'paypal'];
