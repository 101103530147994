import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { typographyClasses } from '@mui/material/Typography';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  [`& > .${typographyClasses.root}`]: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
  },
}));

export default { Container };
