import { useCallback, useMemo } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { getValidationErrors } from 'hooks/useRequestError';
import { useUpdateAuthenticationTypeBulkMutation, useUpdateAuthenticationTypeMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { ChangeAuthenticationTypePayload } from 'store/proxies/dtos';
import type { ProxyModel } from 'store/proxies/models';
import { AuthenticationType } from 'store/proxies/types';
import type { FormErrors } from 'utils/form';
import { ToastManager } from 'utils/toast';

import { ChangeAuthMethodForm, type ChangeAuthMethodValues } from './ChangeAuthMethodForm';

export type ChangeAuthMethodModalProps = {
  proxies: ProxyModel[];
};

export function ChangeAuthMethodModal({ proxies }: ChangeAuthMethodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [updateAuthTypeSingle] = useUpdateAuthenticationTypeMutation();
  const [updateAuthTypeBulk] = useUpdateAuthenticationTypeBulkMutation();
  const hideModal = useHideModal();
  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const initialValues = useMemo<ChangeAuthMethodValues>(() => {
    if (proxies.length > 1)
      return {
        type: AuthenticationType.USERNAME_PASSWORD,
        ipWhitelist: [''],
      };

    const [{ authentication }] = proxies;

    return {
      type:
        authentication.username && authentication.password
          ? AuthenticationType.USERNAME_PASSWORD
          : AuthenticationType.IP_WHITELIST,

      ipWhitelist: authentication.whitelistedIps?.length > 0 ? authentication.whitelistedIps : [''],
    };
  }, [proxies]);

  const mapResponseKeyIntoFormKey = useCallback((key: keyof ChangeAuthenticationTypePayload) => {
    if (key === 'ips') return 'ipWhitelist';
    if (key === 'newAuthType') return 'type';

    throw new Error('Unsupported key');
  }, []);

  const validate = useCallback(
    ({ ipWhitelist, type }: ChangeAuthMethodValues) => {
      const errors: FormErrors = {};

      const filteredWhitelist = ipWhitelist.filter((i) => Boolean(i.length));

      if (type === AuthenticationType.IP_WHITELIST && filteredWhitelist.length === 0) {
        errors.globalError = t('errors:minItems', { field: t('common:form.ipWhitelist'), min: 1 }) ?? '';
      }

      return errors;
    },
    [t],
  );

  return (
    <Formik<ChangeAuthMethodValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async ({ type, ipWhitelist }, { setErrors, setSubmitting }) => {
        try {
          const ips = type === AuthenticationType.IP_WHITELIST ? ipWhitelist.filter((v) => v.length) : [];

          if (proxies.length > 1) {
            await updateAuthTypeBulk({ ids: proxyIds, authType: type, ips }).unwrap();

            posthog?.capture(posthogEvents.proxyAuthenticationTypeModifiedBulk, {
              proxyIds,
              total: proxyIds.length,
            });
          } else {
            await updateAuthTypeSingle({ proxyId: proxies[0].id, newAuthType: type, ips }).unwrap();

            posthog?.capture(posthogEvents.proxyAuthenticationTypeModified);
          }

          ToastManager.success(t('proxies.modals.changeAuthMethod.success'));
          hideModal();
        } catch (error) {
          const validationErrors = getValidationErrors(error as AxiosBaseQueryError, mapResponseKeyIntoFormKey);

          if (validationErrors && Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
          }

          setSubmitting(false);
        }
      }}
      validate={validate}
    >
      <ChangeAuthMethodForm proxyIds={proxyIds} />
    </Formik>
  );
}
