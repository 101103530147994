export type RenderOptions = {
  boardToken: string;
  basePath?: string;
  ssoToken?: string;
  onLoadCallback?: () => void;
};

export type ChangeLogOptions = {
  appID: string;
  align: 'top' | 'bottom' | 'left' | 'right';
  position: 'top' | 'bottom' | 'left' | 'right';
  labelIDs?: string[];
};

export type IdentifyOptions = {
  appID: string;
  user: {
    id: string | number;
    email?: string | null;
    name: string;
  };
};

export class Canny {
  canny: typeof window.Canny | null;

  constructor(canny: typeof window.Canny | null) {
    this.canny = canny;
  }

  render(options: RenderOptions, element?: HTMLElement) {
    if (this.canny) {
      this.canny('render', options, element);
    }
  }

  initChangelog(options: ChangeLogOptions) {
    if (this.canny) {
      this.canny('initChangelog', options);
    }
  }

  closeChangelog() {
    if (this.canny) {
      this.canny('closeChangelog');
    }
  }

  identify(options: IdentifyOptions) {
    if (this.canny) {
      this.canny('identify', options);
    }
  }
}
