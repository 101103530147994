import type { AppEndpointBuilder } from 'store/base';

import type { ExperimentsDTO, FeaturesDTO } from './dtos';

export const commonEndpoints = (builder: AppEndpointBuilder) => ({
  getEnabledFeatures: builder.query<FeaturesDTO, void>({
    query: () => ({
      url: 'features',
      method: 'GET',
    }),
  }),
  getEnabledExperiments: builder.query<ExperimentsDTO, void>({
    query: () => ({
      url: 'experiments',
      method: 'GET',
    }),
  }),
});
