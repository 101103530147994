import Grid, { type GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

type ItemProps = Omit<GridProps, 'item' | 'key'> & { $size: 'small' | 'medium' };

const Item = styled(({ $size, ...props }: ItemProps) => <Grid item {...props} />)(({ theme, $size }) => ({
  maxWidth: `calc(100% / 3 - ${theme.spacing(2)}) !important`,

  [theme.breakpoints.down('md')]: {
    maxWidth: `calc(100% / 2 - ${theme.spacing(2)}) !important`,
  },

  ...($size === 'small' && {
    button: {
      padding: theme.spacing(1),
    },
  }),
}));

export default { Item };
