import Divider from '@mui/material/Divider';
import Grid, { type GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const EventItemDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type EventItemProps = Omit<GridProps, 'container' | 'item'> & {
  withDivider?: boolean;
};

const EventItem = styled(({ children, withDivider, ...props }: EventItemProps) => (
  <Grid item {...props}>
    {children}

    {withDivider && <EventItemDivider />}
  </Grid>
))(({ theme }) => ({
  '&:not(:first-of-type)': {
    paddingTop: theme.spacing(2),
  },
}));

export default { EventItem };
