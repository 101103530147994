import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { api } from 'store/api';
import type { NetworkType, ProxyNetworkType } from 'store/proxies/types';
import { PersistService } from 'utils/persist';

import type {
  OrderPriceModel,
  ProxySetupEnabledFields,
  ProxySetupFormConfigurations,
  ProxySetupFormErrors,
  ProxySetupModel,
} from './models';
import type { OrderState, OrderStep, ResetOrderFlowPayload } from './types';

// Coupon code provided by user
const couponCode = PersistService.get<string | null>('order-proxy-discount-code');

// Network type selected by user
const selectedNetworkType = PersistService.get<ProxyNetworkType | null>('order-proxy-network-type');

// Current order step
const currentStep = PersistService.get<OrderStep | null>('order-proxy-current-step');

// User's selected proxy configuration
const proxySettings = PersistService.get<ProxySetupModel>('order-proxy-configuration');

// Proxy configurations for User's selected proxy configuration
const proxyFieldsConfigurations = PersistService.get<ProxySetupFormConfigurations>('order-proxy-fields-configurations');

/**
 * Reducer initial state
 */
const initialState: OrderState = {
  couponCode,
  selectedNetworkType,
  currentStep,

  price: null,
  isPriceLoading: false,
  proxySettings,
  proxyFieldsConfigurations,
  proxyFieldsEnabled: null,
  proxyFieldsErrors: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCouponCode: (state, { payload }: PayloadAction<string | null>) => {
      PersistService.set('order-proxy-discount-code', payload);

      state.couponCode = payload;
    },

    setCurrentStep: (state, { payload }: PayloadAction<OrderState['currentStep']>) => {
      PersistService.set('order-proxy-current-step', payload);

      state.currentStep = payload;
    },

    setSelectedNetworkType: (state, { payload }: PayloadAction<NetworkType | null>) => {
      PersistService.set('order-proxy-network-type', payload);
      PersistService.set('order-proxy-configuration', null);
      PersistService.set('order-proxy-fields-configurations', null);

      state.proxySettings = null;
      state.proxyFieldsConfigurations = null;

      state.selectedNetworkType = payload;
    },

    resetOrderFlow: (
      state,
      {
        payload: { shouldResetCouponCode, shouldResetFlow } = { shouldResetCouponCode: true, shouldResetFlow: true },
      }: PayloadAction<ResetOrderFlowPayload | undefined>,
    ) => {
      PersistService.set('order-proxy-network-type', null);
      PersistService.set('order-proxy-configuration', null);
      PersistService.set('order-proxy-current-step', null);
      PersistService.set('order-proxy-fields-configurations', null);

      if (shouldResetFlow) {
        PersistService.set('vpn-flow', null);
        PersistService.set('marketplace-flow', null);

        state.selectedNetworkType = null;
        state.currentStep = null;
        state.proxySettings = null;
        state.proxyFieldsConfigurations = null;
        state.proxyFieldsEnabled = null;
        state.price = null;
        state.isPriceLoading = false;
      }

      if (shouldResetCouponCode) {
        PersistService.set('order-proxy-discount-code', null);
      }

      if (shouldResetCouponCode) {
        state.couponCode = null;
      }
    },

    setOrderPrice: (state, { payload }: PayloadAction<OrderPriceModel | null>) => {
      state.price = payload;
    },

    setIsPriceLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPriceLoading = payload;
    },

    setServiceSettings: (state, { payload }: PayloadAction<ProxySetupModel | null>) => {
      PersistService.set('order-proxy-configuration', payload);

      if (payload?.networkType) {
        PersistService.set('order-proxy-network-type', payload.networkType);
        state.selectedNetworkType = payload.networkType;
      }

      state.proxySettings = payload;
    },

    setProxyFieldsConfigurations: (state, { payload }: PayloadAction<ProxySetupFormConfigurations | null>) => {
      state.proxyFieldsConfigurations = payload;
    },

    updateProxyFieldsConfigurations: (state, { payload }: PayloadAction<ProxySetupFormConfigurations>) => {
      state.proxyFieldsConfigurations = { ...state.proxyFieldsConfigurations, ...payload };
    },

    setProxyFieldsEnabled: (state, { payload }: PayloadAction<ProxySetupEnabledFields | null>) => {
      state.proxyFieldsEnabled = payload;
    },

    updateProxyFieldsEnabled: (state, { payload }: PayloadAction<ProxySetupEnabledFields>) => {
      state.proxyFieldsEnabled = { ...state.proxyFieldsEnabled, ...payload };
    },

    setProxySetupFormErrors: (state, { payload }: PayloadAction<ProxySetupFormErrors | null>) => {
      state.proxyFieldsErrors = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
      PersistService.set('order-proxy-current-step', null);
      PersistService.set('order-proxy-network-type', null);
      PersistService.set('order-proxy-configuration', null);
      PersistService.set('order-proxy-discount-code', null);
      PersistService.set('order-proxy-fields-configurations', null);

      state.proxyFieldsConfigurations = null;
      state.proxySettings = null;
      state.couponCode = null;
      state.selectedNetworkType = null;
      state.currentStep = null;
      state.price = null;
      state.proxyFieldsEnabled = null;
      state.proxyFieldsErrors = null;
    });
  },
});

export const {
  resetOrderFlow,
  setCouponCode,
  setSelectedNetworkType,
  setCurrentStep,

  setOrderPrice,
  setIsPriceLoading,
  setServiceSettings,
  setProxyFieldsConfigurations,
  updateProxyFieldsConfigurations,
  setProxySetupFormErrors,
  setProxyFieldsEnabled,
  updateProxyFieldsEnabled,
} = slice.actions;

export const { reducer: orderReducer } = slice;
