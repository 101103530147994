import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/Icon';

import Styled from './styled';

type SetupItemProps = {
  index: number;
  title: string;
  subtitle: string;
  checked?: boolean;
};

export function SetupItem({ index, subtitle, title, checked }: SetupItemProps) {
  return (
    <Grid container>
      <Styled.TitleWrapper item>
        <Styled.Chip checked={checked}>
          {checked ? (
            <Icon name="check" size={16} iconStroke={(theme) => theme.palette.background.paper} />
          ) : (
            <Typography variant="body-2" color="grey.600">
              {index}
            </Typography>
          )}
        </Styled.Chip>
        <Typography variant="title">{title}</Typography>
      </Styled.TitleWrapper>

      <Styled.SubtitleWrapper>
        <Typography variant="body-2" color="grey.500">
          {subtitle}
        </Typography>
      </Styled.SubtitleWrapper>
    </Grid>
  );
}
