/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { parseError } from 'utils/error';
import { ToastManager } from 'utils/toast';

// Mutations that are used instead of query. Should be muted
const whitelistedMutations = ['getOrderConfiguration'];

/**
 * Show a snackbar
 */
export const errorMiddleware: Middleware = () => (next) => (action) => {
  // show only for mutations
  if (
    isRejectedWithValue(action) &&
    action.meta.arg.type === 'mutation' &&
    !whitelistedMutations.includes(action.meta.arg.endpointName)
  ) {
    // A list with filtered error codes that should not appear as a global snackbar error
    const isNotValid = [422].includes(action.payload.status);

    if (isNotValid) {
      return next(action);
    }

    // Rate limit error that needs to display a proper message
    const isRateLimitError = action.payload.status === 429;

    if (isRateLimitError) {
      ToastManager.error(t(`errors:RATE_LIMIT_EXCEEDED`));
    } else {
      const parsedError = parseError(action.payload);

      if (!parsedError) return next(action);

      ToastManager.error(parsedError);
    }
  }

  return next(action);
};
