import { useCallback, useEffect, useMemo } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useAutoExtendProxyBulkMutation, useGetBulkAutoExtendSettingsQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { ProxyAutoExtendBulkParams } from 'store/proxies/dtos';
import type { ProxyModel } from 'store/proxies/models';
import { ToastManager } from 'utils/toast';

import { BulkAutoExtendForm, type BulkAutoExtendValues } from './BulkAutoExtendForm';

export type BulkAutoExtendModalProps = {
  isAllSelected: boolean;
  proxies: ProxyModel[];
};

export function BulkAutoExtendModal({ isAllSelected, proxies }: BulkAutoExtendModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const hideModal = useHideModal();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const { data, isLoading, isUninitialized, refetch } = useGetBulkAutoExtendSettingsQuery(
    isAllSelected ? [] : proxyIds,
  );
  const [autoExtendBulk] = useAutoExtendProxyBulkMutation();

  const isBandwidthRequired = useMemo(() => !!data?.isBandwidthRequired, [data]);

  const initialValues = useMemo<BulkAutoExtendValues>(() => {
    return {
      isEnabled: !!data?.isAutoExtendEnabled,
      bandwidth: 0,
    };
  }, [data]);

  const getPayload = useCallback(
    ({ bandwidth, isEnabled }: BulkAutoExtendValues): ProxyAutoExtendBulkParams => {
      if (!isEnabled) {
        return {
          isEnabled,
          ids: isAllSelected ? [] : proxyIds,
        };
      }

      return {
        ids: isAllSelected ? [] : proxyIds,
        isEnabled,
        bandwidth: isBandwidthRequired ? bandwidth : 0,
      };
    },
    [isAllSelected, isBandwidthRequired, proxyIds],
  );

  const onSubmit = useCallback(
    async (values: BulkAutoExtendValues) => {
      try {
        await autoExtendBulk(getPayload(values)).unwrap();

        posthog?.capture(posthogEvents.proxyAutoExtendBulk, {
          proxyIds,
          isEnabled: values.isEnabled,
          bandwidth: isBandwidthRequired ? values.bandwidth : undefined,
        });

        ToastManager.success(t(`proxies.modals.autoExtend.success.${values.isEnabled ? 'enabled' : 'disabled'}`));
        hideModal();
      } catch (error) {
        const err = error as AxiosBaseQueryError;

        if (err.errors && typeof err.errors === 'string') {
          ToastManager.error(t(`errors:${err.errors}`));
        } else {
          ToastManager.error(t('proxies.modals.extendPeriod.failure'));
        }
      }
    },
    [autoExtendBulk, getPayload, hideModal, isBandwidthRequired, posthog, proxyIds, t],
  );

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [isUninitialized, refetch]);

  return (
    <Formik<BulkAutoExtendValues> initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
      <BulkAutoExtendForm
        proxyIds={proxyIds}
        isAllSelected={isAllSelected}
        isBandwidthRequired={isBandwidthRequired}
        isLoading={isLoading}
      />
    </Formik>
  );
}
