import Styled from './styled';

type FloatingButtonProps = {
  children: JSX.Element;
  onClick: () => void;
  bgColor?: string;
  size?: 'small' | 'medium' | 'large';
};

// Do we really need this defaults? Why not set via theme?
export const FloatingButton = ({ children, onClick, bgColor = 'white', size = 'medium' }: FloatingButtonProps) => (
  <Styled.Container onClick={onClick} $bgColor={bgColor} size={size}>
    {children}
  </Styled.Container>
);
