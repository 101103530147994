import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ModalContainer } from 'components/modals/ModalContainer';
import { shouldRenderPaymentElement } from 'modules/billing/helpers';
import { Stripe } from 'modules/billing/Stripe';

import { useTopUpModalContext, withTopUpModalContextProvider } from './context';
import { StatusStep } from './StatusStep';
import { StripeStep } from './StripeStep';
import { TopUpStep } from './TopUpStep';
import type { TopUpValues } from './types';

export type TopUpModalProps = {
  isOrderFlow: boolean;
  initialValues?: Partial<TopUpValues>;
};

function TopUpModalComponent({ isOrderFlow, initialValues }: TopUpModalProps) {
  const { t } = useTranslation();
  const { fee, step, values, handleCancelModal } = useTopUpModalContext();

  const { title, subtitle } = useMemo(() => {
    if (step === 'top-up-form') {
      return { title: t('order.modals.topUp.title'), subtitle: t('order.modals.topUp.subtitle') };
    }

    if (step === 'stripe' && values && shouldRenderPaymentElement(values.type)) {
      return { title: t('order.modals.stripe.title'), subtitle: t('order.modals.stripe.subtitle') };
    }

    return { title: null, subtitle: null };
  }, [step, t, values]);

  const onModalClose = useCallback(
    (_: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') return;

      return handleCancelModal();
    },
    [handleCancelModal],
  );

  return (
    <ModalContainer title={title} subtitle={subtitle} disableEscapeKeyDown onClose={onModalClose}>
      {step === 'top-up-form' && <TopUpStep initialValues={initialValues} />}

      {step === 'stripe' && fee && values && (
        <Stripe mode="payment" amount={fee.finalPriceInCurrency} currency={fee.currency} paymentMethod={values.type}>
          <StripeStep />
        </Stripe>
      )}

      {step === 'status' && <StatusStep isOrderFlow={isOrderFlow} />}
    </ModalContainer>
  );
}

export const TopUpModal = withTopUpModalContextProvider(TopUpModalComponent);
