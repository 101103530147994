import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

export function Loader() {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item>
        <Grid container direction="column" rowSpacing={1.5} alignItems="center">
          <Grid item>
            <CircularProgress size={64} />
          </Grid>

          <Grid item>
            <Grid container direction="column" textAlign="center" rowSpacing={1}>
              <Grid item component={Typography} variant="body-1" color="grey.500">
                {t('account.modals.status.topUp.pending.subtitle')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
