import { lazy, memo, Suspense } from 'react';

import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { Seo } from 'components/Seo';
import { AccountLayout } from 'modules/accounts/AccountLayout';
import { APIKeysLayout } from 'modules/api-keys/APIKeysLayout';
import { AuthLayout } from 'modules/auth/AuthLayout';
import { AppLayout } from 'modules/common/AppLayout';
import { BasicLayout } from 'modules/common/BasicLayout';
import GlobalModals from 'modules/common/GlobalModals';
import { AuthGuard } from 'modules/common/guards/AuthGuard';
import { FeatureFlagGuard } from 'modules/common/guards/FeatureFlagGuard';
import { GuestGuard } from 'modules/common/guards/GuestGuard';
import { VPNGuard } from 'modules/common/guards/VPNGuard';
import { FeatureRequestLayout } from 'modules/featureRequest/FeatureRequestLayout';
import { OrderLayout } from 'modules/orders/OrderLayout';
import { ReferralsLayout } from 'modules/referrals/ReferralsLayout';
import { ResellerLayout } from 'modules/resellers/ResellerLayout';
import { paths } from 'paths';
import { retry } from 'utils/routes';

type DynamicLoaderProps = {
  path: string;
};

/**
 * ! Due to this issue - https://github.com/facebook/react/issues/14299
 *
 * React remounts lazy-loaded components without memoization so each component was initially loaded with an initial state.
 * This component memoizes dynamically imported components to prevent unwanted behaviors.
 */
const DynamicLoader = memo(function DynamicLoaderInner(props: DynamicLoaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-template-expression
  const LazyComponent = lazy(() => retry(() => import(`${props.path}`)));

  return (
    <Suspense fallback={null}>
      <LazyComponent />
    </Suspense>
  );
});

export const router = createBrowserRouter(
  [
    {
      path: paths.index,
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <DynamicLoader path="modules/dashboard/OverviewPage" /> },
        {
          path: paths.proxies.index,
          children: [
            { index: true, element: <DynamicLoader path="modules/proxies/MyProxiesPage" /> },
            {
              path: `${paths.proxies.index}/:proxyId`,
              element: <DynamicLoader path="modules/proxies/ProxyOverviewPage" />,
            },
          ],
        },
        {
          path: paths.vpns.index,
          children: [
            {
              index: true,
              element: (
                <VPNGuard>
                  <DynamicLoader path="modules/vpns/VPNOverviewPage" />
                </VPNGuard>
              ),
            },
            {
              path: `${paths.vpns.index}/:vpnId`,
              element: (
                <VPNGuard>
                  <DynamicLoader path="modules/vpns/VPNOverviewPage" />
                </VPNGuard>
              ),
            },
          ],
        },
        {
          path: paths.marketplace.index,
          element: <FeatureFlagGuard name="isDealsInventoryEnabled" />,
          children: [{ index: true, element: <DynamicLoader path="modules/marketplace/MarketplaceOverviewPage" /> }],
        },
        {
          path: paths.account.index,
          element: <AccountLayout />,
          children: [
            { index: true, element: <DynamicLoader path="modules/accounts/AccountGeneralPage" /> },
            { path: paths.account.general, element: <DynamicLoader path="modules/accounts/AccountGeneralPage" /> },
            {
              path: paths.account.notifications,
              element: <DynamicLoader path="modules/accounts/AccountNotificationsPage" />,
            },
            { path: paths.account.security, element: <DynamicLoader path="modules/accounts/AccountSecurityPage" /> },
            {
              path: paths.account.identityVerification,
              element: <DynamicLoader path="modules/accounts/AccountIdentityVerificationPage" />,
            },
            { path: paths.account.payments, element: <DynamicLoader path="modules/accounts/AccountPaymentsPage" /> },
          ],
        },
        {
          path: paths.referrals.index,
          element: <ReferralsLayout />,
          children: [{ index: true, element: <DynamicLoader path="modules/referrals/ReferralsPage" /> }],
        },
        {
          path: paths.reseller.index,
          element: <ResellerLayout />,
          children: [{ index: true, element: <DynamicLoader path="modules/resellers/ResellerPage" /> }],
        },
        {
          path: paths.apiKeys.index,
          element: <APIKeysLayout />,
          children: [{ index: true, element: <DynamicLoader path="modules/api-keys/APIKeysPage" /> }],
        },
      ],
    },
    {
      path: paths.auth.signIn,
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [{ index: true, element: <DynamicLoader path="modules/auth/SignInPage" /> }],
    },
    {
      path: paths.auth.signUp,
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [{ index: true, element: <DynamicLoader path="modules/auth/SignUpPage" /> }],
    },
    { path: paths.auth.logout, element: <DynamicLoader path="modules/auth/LogoutPage" /> },
    {
      path: paths.auth.forgotPassword,
      element: (
        <GuestGuard>
          <BasicLayout />
        </GuestGuard>
      ),
      children: [{ index: true, element: <DynamicLoader path="modules/auth/ForgotPasswordPage" /> }],
    },
    {
      path: paths.auth.resetPassword,
      element: (
        <GuestGuard>
          <BasicLayout />
        </GuestGuard>
      ),
      children: [
        { index: true, element: <Navigate replace to={paths.auth.signIn} /> },
        { path: ':code', element: <DynamicLoader path="modules/auth/ResetPasswordPage" /> },
      ],
    },
    {
      path: paths.auth.mfa,
      element: (
        <GuestGuard>
          <BasicLayout />
        </GuestGuard>
      ),
      children: [{ index: true, element: <DynamicLoader path="modules/auth/MultiFactorAuthPage" /> }],
    },
    {
      path: paths.order.index,
      element: (
        <AuthGuard>
          <OrderLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <DynamicLoader path="modules/orders/OrderProxyPage" /> },
        { path: paths.order.vpn, element: <DynamicLoader path="modules/orders/OrderVPNPage" /> },
        { path: paths.order.proxy, element: <DynamicLoader path="modules/orders/OrderProxyStepPage" /> },
        { path: paths.order.details, element: <DynamicLoader path="modules/orders/OrderDetailsStepPage" /> },
        { path: paths.order.checkout, element: <DynamicLoader path="modules/orders/OrderCheckoutStepPage" /> },
      ],
    },
    {
      path: paths.payments.callback,
      element: (
        <AuthGuard>
          <DynamicLoader path="modules/common/PaymentsCallbackPage" />
        </AuthGuard>
      ),
    },
    {
      path: paths.featureRequest.index,
      element: (
        <AuthGuard>
          <FeatureRequestLayout />
        </AuthGuard>
      ),
      children: [{ index: true, element: <DynamicLoader path="modules/featureRequest/FeatureRequestPage" /> }],
    },
    {
      path: paths.quiz.index,
      element: <Outlet />,
      children: [{ index: true, element: <DynamicLoader path="modules/orders/QuizPage" /> }],
    },
    {
      path: paths.notFound,
      element: <BasicLayout />,
      children: [{ index: true, element: <DynamicLoader path="modules/common/NotFoundPage" /> }],
    },
    {
      path: `${paths.auth.switch}/:email`,
      element: <DynamicLoader path="modules/auth/ImpersonateUserPage" />,
    },
    { path: '*', element: <Navigate to={paths.notFound} /> },
  ].map((item) => ({
    ...item,
    // modals should have access to routes context in order to use Link component inside
    element: (
      <>
        <Seo />
        <GlobalModals.Provider>{item.element}</GlobalModals.Provider>
      </>
    ),
  })),
);
