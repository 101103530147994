import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import type { CreditCardType } from 'modules/billing/types';
import type { CreditCardModel } from 'store/accounts/models';

type CreditCardLabelProps = {
  type: CreditCardType;
  creditCard?: CreditCardModel;
};

export function CreditCardLabel({ type, creditCard }: CreditCardLabelProps) {
  const { t } = useTranslation();

  if (type === 'another-card') {
    return <>{t('order.modals.topUp.useAnotherCard')}</>;
  }

  if (type !== 'existing-card') {
    return <>{t('common:paymentOptions.STRIPE')}</>;
  }

  return (
    <Typography variant="title" display="flex" alignItems="center" columnGap={0.4}>
      {t(`common:paymentVendors.${creditCard?.brand}`)}

      <Typography variant="body-1">{t('account.payments.creditCard.endingWith')}</Typography>
      {creditCard?.lastFour}
    </Typography>
  );
}
