import { type ReactNode, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';
import { type PaymentVendor, PaymentVendorIcon } from 'components/PaymentVendorIcon';
import { PaymentOption, type TopUpPaymentOptionType } from 'modules/billing/types';

import { CreditCardLabel } from './CreditCardLabel';
import { useTopUpModalContext } from '../context';

type PaymentMethodAttributes = {
  label: ReactNode;
  icon: PaymentVendor;
};

type Options = {
  [K in TopUpPaymentOptionType]: PaymentMethodAttributes;
};

type PaymentMethodProps = {
  type: TopUpPaymentOptionType;
  paymentFee: string;
  isSelected?: boolean;
};

export function PaymentMethod({ type, paymentFee, isSelected }: PaymentMethodProps) {
  const { creditCard } = useTopUpModalContext();
  const { t } = useTranslation();

  const { icon, label } = useMemo<PaymentMethodAttributes>(() => {
    const options: Options = {
      alipay: { icon: 'ali-pay', label: `common:paymentOptions.${PaymentOption.ALIPAY}` },
      crypto: { icon: 'coingate', label: `common:paymentOptions.${PaymentOption.CRYPTO}` },
      paypal: { icon: 'pay-pal', label: `common:paymentOptions.${PaymentOption.PAYPAL}` },
      'another-card': { icon: 'another-card', label: <CreditCardLabel type="another-card" /> },
      'new-card': { icon: 'default', label: <CreditCardLabel type="new-card" /> },
      'existing-card': {
        icon: (creditCard?.brand as PaymentVendor) ?? 'default',
        label: <CreditCardLabel type="existing-card" creditCard={creditCard} />,
      },
      blik: { icon: 'blik', label: `common:paymentOptions.${PaymentOption.BLIK}` },
      giropay: { icon: 'giropay', label: `common:paymentOptions.${PaymentOption.GIROPAY}` },
      ideal: { icon: 'ideal', label: `common:paymentOptions.${PaymentOption.IDEAL}` },
      sofort: { icon: 'klarna', label: `common:paymentOptions.${PaymentOption.SOFORT}` },
      p24: { icon: 'p24', label: `common:paymentOptions.${PaymentOption.P24}` },
    };

    return options[type];
  }, [creditCard, type]);

  return (
    <Grid container width="100%" gap={1}>
      <Grid item display="flex" alignItems="center">
        <PaymentVendorIcon type={icon} size={32} />
      </Grid>

      <Grid item display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="body-2">{typeof label === 'string' ? t(label) : label}</Typography>

        {type !== 'another-card' && (
          <Typography variant="caption" color="text.secondary">
            {t('common:form.paymentFee', { paymentFee })}
          </Typography>
        )}
      </Grid>

      {isSelected && (
        <Grid item display="flex" alignItems="center" marginLeft="auto">
          <Icon name="check" size="small" color="primary" />
        </Grid>
      )}
    </Grid>
  );
}
