import { useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Autocomplete } from 'components/form-partials/Autocomplete';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import type { Option } from 'types';

type ChangeUplinkSpeedFormProps = {
  options: Option[];
  isLoading?: boolean;
};

export type ChangeUplinkSpeedValues = {
  bandwidthSpeed: number;
};

export function ChangeUplinkSpeedForm({ options, isLoading }: ChangeUplinkSpeedFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<ChangeUplinkSpeedValues>();

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isLoading}>
        {t('common:buttons.change')}
      </Button>,
    ],
    [hideModal, isLoading, isSubmitting, isValid, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeBandwidthSpeed.title')}
      subtitle={t('proxies.modals.upgradeBandwidthSpeed.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        <Autocomplete size="small" disableClearable options={options} name="bandwidthSpeed" />
      </Stack>
    </ModalContainer>
  );
}
