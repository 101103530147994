import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useRouter } from 'hooks/useRouter';
import { paths } from 'paths';
import { useGetUserVPNQuery } from 'store/api';
import { VPNStatus } from 'store/vpns/dtos';
import { isAdminUser } from 'utils/user';

type VPNGuardProps = {
  children: ReactNode;
};

export function VPNGuard({ children }: VPNGuardProps) {
  const [checked, setChecked] = useState(false);
  const { data, isLoading } = useGetUserVPNQuery();
  const router = useRouter();
  const { isLegacyVpnSupportEnabled } = useFeatureFlag();

  const check = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (isAdminUser()) {
      setChecked(true);

      return;
    }

    if (data?.status !== VPNStatus.ACTIVE) {
      setChecked(true);

      return router.replace({ pathname: paths.index });
    }

    if (!isLegacyVpnSupportEnabled) {
      setChecked(true);

      return router.replace({ pathname: paths.index });
    }

    setChecked(true);
  }, [data?.status, isLegacyVpnSupportEnabled, isLoading, router]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) return null;

  return <>{children}</>;
}
