import Button, { type ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Container = styled((props: Omit<ButtonProps, 'variant'>) => <Button {...props} disableRipple variant="text" />)({
  padding: 0,
  borderRadius: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export default { Container };
