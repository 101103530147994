/**
 * Removes array index like characters from string
 *
 * @param key - Initial string
 * @returns - String with removed index related characters
 */
export function truncateArrayPath(key: string) {
  if (!key.includes('[')) return key;

  return key.substring(0, key.indexOf('['));
}

/**
 * Gets index from array-like annotated string
 *
 * @param key - Initial string
 * @returns - Index of the item of key, -1 if not found
 */
export function getIndexFromKey(key: string) {
  const startIndex = key.indexOf('[');
  const endIndex = key.indexOf(']');
  if (startIndex === -1) return -1;

  return Number(key.substring(startIndex + 1, endIndex));
}
