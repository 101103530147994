import { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useIDVerificationStatus } from 'hooks/useIDVerificationStatus';
import { useGetBankDetailsQuery } from 'store/api';

import { SetupItem } from './SetupItem';

export type PayoutSetupModalProps = {
  onContinue: () => void;
};

export function PayoutSetupModal({ onContinue }: PayoutSetupModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { isLoading: isSessionLoading, shouldDisplayIDNotification } = useIDVerificationStatus();
  const { data: bankDetails, isLoading: isBankDetailsLoading } = useGetBankDetailsQuery();

  const actions = useMemo(
    () => [
      <Button key="cancel" fullWidth color="secondary" onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="continue" fullWidth onClick={onContinue}>
        {t('common:buttons.continue')}
      </Button>,
    ],
    [hideModal, onContinue, t],
  );

  const items = useMemo(
    () => [
      {
        title: t('referrals.modals.payoutSetup.identity.title'),
        subtitle: t('referrals.modals.payoutSetup.identity.subtitle'),
        checked: !shouldDisplayIDNotification,
      },
      {
        title: t('referrals.modals.payoutSetup.bankDetails.title'),
        subtitle: t('referrals.modals.payoutSetup.bankDetails.subtitle'),
        checked: !!bankDetails?.accountNumber || false,
      },
    ],
    [bankDetails?.accountNumber, shouldDisplayIDNotification, t],
  );

  return (
    <ModalContainer
      title={t('referrals.modals.payoutSetup.title')}
      subtitle={t('referrals.modals.payoutSetup.subtitle')}
      actions={actions}
    >
      <Grid container direction="column" rowGap={4}>
        {isSessionLoading || isBankDetailsLoading ? (
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          items.map((item, i) => <SetupItem key={`setup-item-${i}`} {...item} index={i + 1} />)
        )}
      </Grid>
    </ModalContainer>
  );
}
