import { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useDownloadCredentialsMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { ProxyCredentialsFormat } from 'store/proxies/types';
import { downloadConfigFile } from 'utils/values';

import Styled from './styled';
import { MultiUpdatesAccordion } from '../MultiUpdatesAccordion';

type CredentialOption = {
  label: string;
  format: ProxyCredentialsFormat;
};

export type DownloadCredentialsModalProps = {
  proxies: ProxyModel[];
  isAllSelected: boolean;
};

const OPTIONS: CredentialOption[] = [
  { label: 'IP:PORT', format: 'IP_PORT' },
  { label: 'IP:PORT:USERNAME:PASSWORD', format: 'IP_PORT_USERNAME_PASSWORD' },
  { label: 'USERNAME:PASSWORD@IP:PORT', format: 'USERNAME_PASSWORD_IP_PORT' },
];

export function DownloadCredentialsModal({ proxies = [], isAllSelected }: DownloadCredentialsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const [downloadCredentials] = useDownloadCredentialsMutation();

  const footer = useMemo(
    () => (
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>
    ),
    [hideModal, t],
  );

  const handleClick = useCallback(
    async (format: ProxyCredentialsFormat) => {
      try {
        const response = await downloadCredentials({
          format,
          ids: isAllSelected ? [] : proxyIds,
        }).unwrap();

        posthog?.capture(posthogEvents.proxyCredentialsDownloaded);

        downloadConfigFile(response, 'proxy_list.txt');
      } catch {
        // no-op
      }
    },
    [downloadCredentials, isAllSelected, posthog, proxyIds],
  );

  return (
    <ModalContainer
      title={t('proxies.modals.downloadCredentials.title')}
      subtitle={t('proxies.modals.downloadCredentials.subtitle')}
      footer={footer}
    >
      <Grid container spacing={4}>
        {proxyIds.length > 1 && !isAllSelected && (
          <Grid item xs={12}>
            <MultiUpdatesAccordion
              proxyIds={proxyIds}
              customLabel={t('proxies.my-proxies.multiaction.download', { count: proxyIds.length })}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {OPTIONS.map(({ label, format }) => (
            <Styled.CredentialsItem key={label} onClick={() => handleClick(format)}>
              <Icon name="receive-square" iconStroke={(theme) => theme.palette.text.secondary} />
              <Typography variant="body-1">{label}</Typography>
            </Styled.CredentialsItem>
          ))}
        </Grid>
      </Grid>
    </ModalContainer>
  );
}
