import Box from '@mui/material/Box';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const IconButton = styled(MuiIconButton)({
  padding: 0,
});

export default { Container, IconButton };
