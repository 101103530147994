import { type MouseEvent, type MutableRefObject, useCallback, useState } from 'react';

import Menu, { type MenuProps as MuiMenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import type { Action } from 'components/Dropdown';
import { Icon } from 'components/Icon';

type IndicatorProps = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

type BulkActionsMenuProps<T> = {
  actions: Action[];
  anchorRef: MutableRefObject<HTMLDivElement | null>;
  renderIndicator: (props: IndicatorProps) => T;
  MenuProps?: Partial<MuiMenuProps>;
};

export function BulkActionsMenu<T>({ actions, anchorRef, MenuProps, renderIndicator }: BulkActionsMenuProps<T>) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleOpenMenu = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsMenuVisible((prev) => !prev);
  }, []);

  const handleCloseMenu = useCallback((e: MouseEvent<HTMLDivElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') e.stopPropagation();

    setIsMenuVisible(false);
  }, []);

  return (
    <>
      {renderIndicator({ onClick: handleOpenMenu })}

      <Menu
        anchorEl={anchorRef.current}
        {...MenuProps}
        open={isMenuVisible}
        onClose={handleCloseMenu}
        MenuListProps={{
          sx: { width: anchorRef.current?.offsetWidth },
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuVisible(false);
              action.onClick(e);
            }}
          >
            {action.icon && <Icon size="small" name={action.icon} />}

            <Typography variant="body-2" color="text.secondary">
              {action.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
