import { Component } from 'react';

import type { ModalStateType, ModalManagerRenderFunctionType } from './types';

interface ModalHostProps<S extends ModalStateType = ModalStateType> {
  renderFn: ModalManagerRenderFunctionType<S>;
}

export default class ModalHost<S extends ModalStateType = ModalStateType> extends Component<
  ModalHostProps<S>,
  ModalStateType<S['id'], S['params']>
> {
  state: ModalStateType<S['id'], S['params']> = {};

  render() {
    return this.props.renderFn(this.state as S);
  }
}
