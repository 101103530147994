import FormGroup, { type FormGroupProps } from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { TextField } from 'formik-mui';

const Container = styled(({ fullWidth, ...props }: FormGroupProps & { fullWidth?: boolean }) => (
  <FormGroup {...props} />
))(({ fullWidth }) => ({
  ...(fullWidth && {
    width: '100%',
  }),
}));

const Input = styled(TextField)(({ size, theme }) => ({
  'label + &': {
    marginTop: size === 'medium' ? theme.spacing(4) : '28px',
  },
}));

const AdornmentIcon = styled(IconButton)({
  marginRight: -10,
});

const InputHelperText = styled(FormHelperText)({
  marginTop: 2,
});

export default { Container, Input, AdornmentIcon, InputHelperText };
