import type { AppEndpointBuilder } from 'store/base';
import { buildLegacyAppURL } from 'utils/url';

import type {
  ForgotPasswordPayload,
  MultiFactorPayload,
  ResetPasswordPayload,
  SignInPayload,
  SignUpPayload,
} from './dtos';
import { toAuthenticationModel, toCaptchaModel, toMultiFactorModel, toSessionModel, toTokenModel } from './helpers';
import type { AuthenticationModel, CaptchaModel, MultiFactorModel, SessionModel, TokenModel } from './models';

export const authEndpoints = (builder: AppEndpointBuilder) => ({
  getCaptcha: builder.query<CaptchaModel, void>({
    query: () => ({
      url: 'captcha',
      method: 'GET',
    }),
    transformResponse: toCaptchaModel,
  }),

  getUserSession: builder.query<SessionModel, void>({
    query: () => ({
      url: 'session',
      method: 'GET',
      maxRedirects: 0,
    }),
    transformResponse: toSessionModel,
    providesTags: ['Session'],
  }),

  getMultiFactorStatus: builder.query<MultiFactorModel, MultiFactorModel['preferredProvider'] | void>({
    query: (provider) => ({
      url: buildLegacyAppURL('2fa-status'),
      method: 'GET',
      params: { preferProvider: provider },
    }),
    transformResponse: toMultiFactorModel,
  }),

  login: builder.mutation<AuthenticationModel, SignInPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('login-check'),
      method: 'POST',
      data,
    }),
    transformResponse: toAuthenticationModel,
  }),

  register: builder.mutation<TokenModel, SignUpPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('registration'),
      method: 'POST',
      data,
    }),
    transformResponse: toTokenModel,
  }),

  forgotPassword: builder.mutation<void, ForgotPasswordPayload>({
    query: (data) => ({
      url: 'forgot-password-post',
      method: 'POST',
      data,
    }),
  }),

  resetPassword: builder.mutation<void, ResetPasswordPayload>({
    query: ({ code, ...data }) => ({
      url: `reset-password-post/${code}`,
      method: 'POST',
      data,
    }),
  }),

  multiFactorVerification: builder.mutation<TokenModel, MultiFactorPayload>({
    query: ({ preferProvider, ...data }) => ({
      url: buildLegacyAppURL('2fa-check-api'),
      method: 'POST',
      data,
      params: { preferProvider },
    }),
    transformResponse: toTokenModel,
  }),

  logout: builder.mutation<void, void>({
    query: () => ({
      url: 'logout',
      method: 'POST',
    }),
  }),
});
