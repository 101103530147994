import { type MouseEvent, useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { Formik, type FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { NumberInput } from 'components/form-partials/NumberInput';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { getValidationErrors } from 'hooks/useRequestError';
import { useShowModal } from 'modules/common/GlobalModals';
import type { CreditCardModel } from 'store/accounts/models';
import { useRemoveCreditCardMutation, useUpdateAutoTopUpPreferencesMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { CurrencyFormatter } from 'utils/currency';
import { ToastManager } from 'utils/toast';

import Styled from './styled';

export type ManageCardModalProps = {
  initiallyEnabled: boolean;
  initialAmount: number;
  initialThreshold: number;
  card: CreditCardModel;
};

type AutoTopUpValues = {
  isEnabled?: boolean;
  amount?: number;
  threshold?: number;
};

const schema: Yup.Schema<AutoTopUpValues> = Yup.object({
  isEnabled: Yup.boolean(),
  amount: Yup.number().when('isEnabled', (isEnabled, field) =>
    isEnabled.every(Boolean) ? field.required().min(5) : field,
  ),
  threshold: Yup.number().when('isEnabled', (isEnabled, field) =>
    isEnabled.every(Boolean) ? field.required().min(5) : field,
  ),
});

export function ManageCardModal({ initiallyEnabled, initialAmount, initialThreshold, card }: ManageCardModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const showModal = useShowModal();

  const [removeCreditCard, { isLoading: isCCRemoving }] = useRemoveCreditCardMutation();
  const [updateAutoTopUpPreferences] = useUpdateAutoTopUpPreferencesMutation();

  const onConfirm = useCallback(async () => {
    await removeCreditCard().unwrap();
    ToastManager.success(t('account.modals.cardRemoval.success'));

    hideModal();
  }, [hideModal, removeCreditCard, t]);

  const handleCardRemove = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      showModal('credit.card.remove', {
        title: t('account.modals.cardRemoval.title'),
        subtitle: t('account.modals.cardRemoval.subtitle'),
        onConfirm,
        confirmLabel: t('common:buttons.remove'),
        confirmDanger: true,
        onCancel: () => showModal('credit.card.manage', { initiallyEnabled, initialAmount, initialThreshold, card }),
        isLoading: isCCRemoving,
      });
    },
    [showModal, t, onConfirm, isCCRemoving, initiallyEnabled, initialAmount, initialThreshold, card],
  );

  const onSubmit = useCallback(
    async (
      { amount, isEnabled, threshold }: AutoTopUpValues,
      { setErrors, setSubmitting }: FormikHelpers<AutoTopUpValues>,
    ) => {
      try {
        await updateAutoTopUpPreferences({
          isEnabled: !!isEnabled,
          ...(!!isEnabled && { amount, threshold }),
        }).unwrap();

        hideModal();
      } catch (ex) {
        const validationErrors = getValidationErrors(ex as AxiosBaseQueryError);

        if (validationErrors && Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
        }

        setSubmitting(false);
      }
    },
    [hideModal, updateAutoTopUpPreferences],
  );

  const initialValues = useMemo<AutoTopUpValues>(() => {
    return {
      isEnabled: initiallyEnabled,
      amount: initialAmount,
      threshold: initialThreshold,
    };
  }, [initialAmount, initialThreshold, initiallyEnabled]);

  return (
    <Formik<AutoTopUpValues>
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      validateOnMount
      validateOnChange
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, isValid, isSubmitting, setFieldValue }) => {
        const actions = [
          <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
            {t('common:buttons.cancel')}
          </Button>,
          <Button key="confirm" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
            {t('common:buttons.save')}
          </Button>,
        ];

        return (
          <ModalContainer
            component="form"
            containerProps={{ onSubmit: handleSubmit, noValidate: true }}
            title={t('account.modals.manage.title')}
            subtitle={t('account.modals.manage.subtitle')}
            actions={actions}
          >
            <Grid container direction="column" rowSpacing={4}>
              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="title">{t(`common:paymentVendors.${card.brand}`)}</Typography>
                        <Typography variant="body-1">{t('account.payments.creditCard.endingWith')}</Typography>
                        <Typography variant="title">{card.lastFour}</Typography>
                      </Grid>

                      <Grid item component={Typography} variant="body-2" color="text.secondary">
                        {t('account.payments.creditCard.added', {
                          date: dayjs.utc(card.addedAt).format('YYYY-MM-DD HH:mm:ss'),
                        })}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Styled.RemoveButton onClick={handleCardRemove}>{t('common:buttons.remove')}</Styled.RemoveButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item component={Divider} />

              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item display="flex" justifyContent="space-between">
                    <Typography variant="title">{t('account.payments.creditCard.autoTopUp')}</Typography>
                    <Switch checked={values.isEnabled} onChange={(_, checked) => setFieldValue('isEnabled', checked)} />
                  </Grid>

                  <Grid item>
                    <Styled.Collapse in={values.isEnabled}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <NumberInput
                            name="amount"
                            prefix="$ "
                            label={t('common:form.currencyAmount', { currency: 'USD' })}
                            placeholder={CurrencyFormatter.format(5)}
                            helperText={t('account.modals.manage.amountHelperText')}
                          />
                        </Grid>

                        <Grid item>
                          <NumberInput
                            name="threshold"
                            prefix="$ "
                            label={t('common:form.threshold')}
                            placeholder={CurrencyFormatter.format(5)}
                            helperText={t('account.modals.manage.thresholdHelperText')}
                          />
                        </Grid>
                      </Grid>
                    </Styled.Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ModalContainer>
        );
      }}
    </Formik>
  );
}
