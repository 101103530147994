import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import type { ITheme } from 'theme';

import { icons } from './icons';
import Styled from './styled';
import type { IconProps } from './types';

export function Icon({
  name,
  size = 'large',
  width = 24,
  height = 24,
  iconStroke,
  iconFill,
  className,
  wrapperSx,
  ...props
}: IconProps) {
  const theme = useTheme<ITheme>();
  const IconComponent = useMemo(() => icons[name], [name]);

  const iconProps = useMemo(() => {
    if (size === 'large' || typeof size === 'number') {
      return { width, height };
    }

    return { width: 16 / Number(width), height: 16 / Number(height) };
  }, [size, width, height]);

  const iconColor = useMemo(() => {
    const $iconFill = typeof iconFill === 'string' ? iconFill : iconFill?.(theme);
    const $iconStroke = typeof iconStroke === 'string' ? iconStroke : iconStroke?.(theme);

    return iconFill ? { stroke: $iconFill, fill: $iconFill } : { stroke: $iconStroke };
  }, [iconFill, iconStroke, theme]);

  return (
    <Styled.IconWrapper $size={size} className={className} sx={wrapperSx}>
      <Styled.MuiSvgIcon
        data-testid="SvgIcon-Test"
        component={IconComponent}
        {...props}
        {...iconProps}
        $pathColors={iconColor}
      />
    </Styled.IconWrapper>
  );
}
