import { ReactComponent as CardIcon } from 'assets/icons/money/card.svg';
import { ReactComponent as DiscountIcon } from 'assets/icons/money/discount.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/money/dollar.svg';
import { ReactComponent as MoneySendIcon } from 'assets/icons/money/money-send.svg';
import { ReactComponent as ReceiptIcon } from 'assets/icons/money/receipt.svg';
import { ReactComponent as TagIcon } from 'assets/icons/money/tag.svg';

export const money = {
  card: CardIcon,
  dollar: DollarIcon,
  discount: DiscountIcon,
  'money-send': MoneySendIcon,
  receipt: ReceiptIcon,
  tag: TagIcon,
};
