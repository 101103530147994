import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

export function LoadingStep() {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} alignItems="center" justifyContent="center">
      <Typography variant="title">{t('proxies.overview.admin.actions.diagnostic.loading.title')}</Typography>

      <CircularProgress size={64} />
    </Stack>
  );
}
