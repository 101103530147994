import { loadingButtonClasses } from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { checkboxClasses } from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { iconButtonClasses } from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { alpha, styled } from '@mui/material/styles';
import MuiTable from '@mui/material/Table';
import { tableBodyClasses } from '@mui/material/TableBody';
import MuiTableCell, { tableCellClasses } from '@mui/material/TableCell';
import MuiTableRow, { tableRowClasses, type TableRowProps as MuiTableRowProps } from '@mui/material/TableRow';

import { Link } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { Z_INDEXES } from 'constants/elevations';

const TableWrapper = styled(Box)({
  overflowX: 'auto',
});

const Table = styled(MuiTable)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
  borderRadius: theme.spacing(2),
  borderCollapse: 'separate',

  [`& .${tableBodyClasses.root} > .${tableRowClasses.root} .${tableCellClasses.root}`]: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.08)`,
  },

  [`& .${tableBodyClasses.root} > .${tableRowClasses.root}:last-of-type .${tableCellClasses.root}`]: {
    border: 'none',
  },

  [`& .${tableBodyClasses.root} > .${tableRowClasses.root}:last-of-type:hover`]: {
    [`& .${tableCellClasses.root}:first-of-type`]: {
      borderBottomLeftRadius: theme.spacing(2),
    },

    [`& .${tableCellClasses.root}:last-of-type`]: {
      borderBottomRightRadius: theme.spacing(2),
    },
  },
}));

type TableRowProps = MuiTableRowProps & {
  $clickable?: boolean;
};

const TableRow = styled(({ $clickable, ...props }: TableRowProps) => <MuiTableRow {...props} />)(
  ({ $clickable, theme }) => ({
    ...(!!$clickable && {
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.grey['100'],
      },
    }),

    [`& .${tableCellClasses.root}`]: {
      whiteSpace: 'nowrap',
    },
  }),
);

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`& .${checkboxClasses.root}`]: {
    padding: 0,
  },

  [`&.${tableCellClasses.head}`]: {
    padding: theme.spacing(1),

    '&:first-of-type': {
      paddingLeft: theme.spacing(2),

      [`&:has(.${checkboxClasses.root})`]: {
        width: 16,
        height: 16,
        paddingRight: theme.spacing(1),

        [`& .${checkboxClasses.root}`]: {
          maxWidth: 16,
          maxHeight: 16,
        },
      },
    },

    '&:last-of-type': {
      paddingRight: theme.spacing(2),
    },
  },

  [`&.${tableCellClasses.body}`]: {
    padding: theme.spacing(2, 1, 2, 1),

    '&:first-of-type': {
      padding: theme.spacing(2, 1, 2, 2),

      [`&:has(.${checkboxClasses.root})`]: {
        width: 16,
        height: 16,
        padding: theme.spacing(2, 1, 2, 2),

        [`& .${checkboxClasses.root}`]: {
          maxWidth: 16,
          maxHeight: 16,
        },
      },
    },

    '&:last-of-type': {
      padding: theme.spacing(2, 2, 2, 1),

      [`&:has(.${iconButtonClasses.root})`]: {
        padding: theme.spacing(1.5, 1.5, 1.5, 1),

        [`& .${iconButtonClasses.root}`]: {
          padding: theme.spacing(0.5),
        },
      },

      [`&:has(.${loadingButtonClasses.root})`]: {
        padding: theme.spacing(1, 0.5, 1, 1.5),

        [`& .${loadingButtonClasses.root}`]: {
          padding: theme.spacing(1, 1.5, 1, 1.5),
        },
      },
    },
  },
}));

const CellLink = styled(Link)({
  display: 'block',
  color: 'inherit',

  '&:hover': {
    textDecoration: 'none',
  },
});

const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  marginTop: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const PerPageSwitchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  'span + div': {
    marginLeft: theme.spacing(2),
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}));

const SortableIcon = styled(Icon)(({ theme, name }) => ({
  marginLeft: theme.spacing(0.5),

  // issue with specific SVG
  ...(name === 'arrow-up-down' && {
    color: 'transparent',
  }),
}));

const PaginationFiller = styled(Box)({ flexGrow: 1 });

const SpinnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 200,
  maxWidth: `calc(100vw - ${theme.spacing(4)})`,
}));

const BulkActionsContainer = styled(Snackbar)(({ theme }) => ({
  background: theme.palette.primary.darkest,
  bottom: `${theme.spacing(4)} !important`,
  padding: theme.spacing(2, 1, 2, 3),
  borderRadius: theme.spacing(2),
  minWidth: 360,
  zIndex: Z_INDEXES.BULK_ACTIONS,
}));

const BulkActionsContent = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  flexWrap: 'nowrap',

  '& .MuiChip-root': {
    height: 18,
    borderRadius: 10,

    '& .MuiChip-label': {
      padding: theme.spacing(0, 1, 0, 1),
      color: theme.palette.primary.main,
    },
  },

  '& a, & a:hover': {
    textDecoration: 'underline',
  },

  '& .MuiGrid-root': {
    flexWrap: 'nowrap',
  },

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    justifyContent: 'center',

    '& .MuiGrid-root': {
      flexWrap: 'wrap',
    },
  },
}));

export default {
  TableWrapper,
  Table,
  TableRow,
  TableCell,

  PaginationWrapper,
  PerPageSwitchWrapper,
  PaginationFiller,
  SpinnerContainer,

  SortableIcon,
  BulkActionsContainer,
  BulkActionsContent,
  CellLink,
};
