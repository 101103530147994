import Fab, { type FabProps } from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import { Z_INDEXES } from 'constants/elevations';

const Container = styled(({ $bgColor, ...props }: FabProps & { $bgColor: string }) => <Fab {...props} />)(
  ({ $bgColor = 'white', theme }) => ({
    zIndex: Z_INDEXES.FLOATING_BUTTON,
    backgroundColor: $bgColor,
    boxShadow: `0 0 0 1px ${theme.palette.divider}, 0 2px 4px ${theme.palette.divider}`,
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  }),
);

export default { Container };
