import type { VPNLocation, VPNPort } from './models';

export enum VPNStatus {
  PENDING = 'PENDING',
  INITIATING = 'INITIATING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  EXPIRING_SOON = 'EXPIRING_SOON',
}

export type VPNDetailsResponse = {
  id: number;
  autoExtendEnabled: boolean;
  createdAt: string;
  expiresAt: string;
  status: VPNStatus;
  authentication?: {
    username?: string;
    password?: string;
  };
};

export type OpenVPNDownloadConfigurationParams = {
  vpnId: number;
  serverId: VPNLocation['id'];
  portId: VPNPort['id'];
};

export type WireGuardDownloadConfigurationParams = {
  vpnId: number;
  serverId: VPNLocation['id'];
};

export type VPNAdminDetailsDto = VPNDetailsResponse & {
  activatedAt?: string | null;
  externalId?: string | null;
  proposition?: string;
  userId: number;
  userName: string;
};

export type VPNEventDTO = {
  createdAt: string;
  message: string;
};

export type VPNChangelogItemDTO = {
  id: number;
  fieldName: string;
  oldValue: string;
  newValue: string;
  createdAt: string;
  changedBy: number;
};

export type VPNExtendPeriodParams = {
  vpnId: number;
  periodInMonths: number;
};
