import { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useIDVerificationStatus } from 'hooks/useIDVerificationStatus';
import { paths } from 'paths';
import { useGetReferralsDetailsQuery, useReferralsPayoutMutation } from 'store/api';
import { isAxiosBaseQueryError } from 'store/base';
import { CurrencyFormatter } from 'utils/currency';
import { ToastManager } from 'utils/toast';

import Styled from './styled';

export function PayoutModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hideModal = useHideModal();

  const { data, isLoading } = useGetReferralsDetailsQuery();

  const [payout, { isLoading: isPayoutLoading }] = useReferralsPayoutMutation();

  const { refetch } = useIDVerificationStatus();

  const onPayoutClick = useCallback(async () => {
    try {
      await payout().unwrap();

      ToastManager.success(t('referrals.modals.payout.success'));
    } catch (error) {
      if (!isAxiosBaseQueryError(error)) {
        return;
      }

      if (error.errors === 'VERIFICATION_REQUIRED') {
        await refetch();

        navigate(paths.account.identityVerification);
      }
    } finally {
      hideModal();
    }
  }, [hideModal, navigate, payout, refetch, t]);

  const actions = useMemo(
    () => [
      <Button fullWidth key="cancel" color="secondary" onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button
        fullWidth
        key="payout"
        color="success"
        disabled={(data && data.finalEarnings < 0) || isPayoutLoading}
        onClick={onPayoutClick}
      >
        {t('common:buttons.payout')}
      </Button>,
    ],
    [data, hideModal, isPayoutLoading, onPayoutClick, t],
  );

  const footer = useMemo(
    () => (
      <Grid container>
        <Grid item component={Typography} variant="caption" color="grey.500" textAlign="center">
          {t('referrals.modals.payout.footer')}
        </Grid>
      </Grid>
    ),
    [t],
  );

  return (
    <ModalContainer
      title={t('referrals.modals.payout.title')}
      subtitle={t('referrals.modals.payout.subtitle')}
      actions={actions}
      footer={footer}
    >
      <Grid container direction="column" rowSpacing={2}>
        <Grid item component={Divider} />

        <Styled.PayoutSummaryItem item>
          <Typography variant="body-1" color="grey.500">
            {t('referrals.modals.payout.earnings')}
          </Typography>

          <Typography variant="body-1">
            {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.earnings || 0)}
          </Typography>
        </Styled.PayoutSummaryItem>

        <Styled.PayoutSummaryItem item>
          <Typography variant="body-1" color="grey.500">
            {t('referrals.modals.payout.taxFee', { amount: data?.transactionTaxInPercent })}
          </Typography>
          <Typography variant="body-1">
            {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.earningsTax || 0)}
          </Typography>
        </Styled.PayoutSummaryItem>

        <Styled.PayoutSummaryItem item>
          <Typography variant="body-1" color="grey.500">
            {t('referrals.modals.payout.bankFee')}
          </Typography>
          <Typography variant="body-1">
            {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.transactionFee || 0)}
          </Typography>
        </Styled.PayoutSummaryItem>

        <Grid item component={Divider} />

        <Styled.PayoutSummaryItem item>
          <Typography variant="title" color="grey.500">
            {t('referrals.modals.payout.total')}
          </Typography>

          <Typography variant="headline-2">
            {isLoading ? <Skeleton /> : CurrencyFormatter.format(data?.finalEarnings || 0)}
          </Typography>
        </Styled.PayoutSummaryItem>
      </Grid>
    </ModalContainer>
  );
}
