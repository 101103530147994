import Box from '@mui/material/Box';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import { Icon } from 'components/Icon';

const PriceContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Select = styled(MuiSelect)({
  '& .MuiSelect-select > div': {
    display: 'none',
  },
});

const SelectedIcon = styled(Icon)({
  marginLeft: 'auto',
});

const TopUpButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  button: {
    padding: `0 0 0 ${theme.spacing(1)}`,
  },
}));

const CredentialsItem = styled('button')(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),

  width: '100%',
  border: 'none',
  appearance: 'none',
  backgroundColor: 'transparent',

  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,

  '&:hover': {
    cursor: 'pointer',
  },

  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default {
  PriceContainer,
  Select,
  SelectedIcon,
  TopUpButtonContainer,
  CredentialsItem,
};
