import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { FreshChat, type FreshChatProps } from 'components/Freshchat';
import { useAppSelector } from 'store';
import { useGetUserSessionQuery } from 'store/api';
import type { ITheme } from 'theme';

const isChatEnabled = () => {
  const isEnabled = process.env.REACT_APP_FRESH_CHAT_ENABLED;

  return isEnabled?.toLowerCase() !== 'false';
};

export function useFreshChat(onInit: () => void) {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { data: session } = useGetUserSessionQuery(undefined, { skip: !isAuthenticated });

  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));

  const freshChatProps = useMemo<FreshChatProps>(
    (): FreshChatProps => ({
      externalId: session?.userId ? session.userId.toString() : undefined,
      firstName: session?.profileDetails?.firstName || undefined,
      lastName: session?.profileDetails?.lastName || undefined,
      email: session?.profileDetails?.email || undefined,
      config: { headerProperty: { hideChatButton: isMobileDevice } },
      profileLink: session?.userId
        ? `https://app.proxy-cheap.com/admin/users/${session.userId.toString()}/overview`
        : undefined,
      isSuspended: session?.profileDetails?.isSuspended,
      onInit,
    }),
    [session, isMobileDevice, onInit],
  );

  return isChatEnabled() ? <FreshChat {...freshChatProps} /> : <></>;
}
