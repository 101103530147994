import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { api } from 'store/api';

import { onSignOut } from './auth/reducers';
import { errorMiddleware } from './errorMiddleware';
import { rootReducer } from './rootReducer';

export const store = configureStore({
  // @ts-expect-error - The reducer type is properly set
  reducer: (state: ReturnType<typeof rootReducer>, action) => {
    if (onSignOut.match(action) || api.endpoints.logout.matchFulfilled(action)) {
      return rootReducer(undefined, action);
    }

    return rootReducer(state, action);
  },
  preloadedState: {},
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat([errorMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
