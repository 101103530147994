import { type PaletteColor, type PaletteOptions, alpha } from '@mui/material/styles';

export const themePalette: PaletteOptions = {
  background: {
    default: '#F5F7FA',
    paper: '#FFF',
  },
  primary: {
    main: '#0452FB',
    dark: 'rgb(24, 96, 251)', // ! Reserved for hover button state
    darkest: '#101a33',
    contrastText: '#ffffff',
  } as PaletteColor,
  secondary: {
    // main: '#ECEDEF',
    main: alpha('#0452FB', 0.08),
    dark: alpha('#0452FB', 0.16),
    darkest: '#ECEDEF',
    contrastText: '#0452FB',
  } as PaletteColor,
  error: {
    light: '#FFE6E2',
    main: '#FF604A',
  },
  success: {
    light: '#f1fff8',
    main: '#4AFFAD',
    dark: 'rgb(71, 245, 166)', // ! Reserved for hover button state
  },
  warning: {
    main: '#FDE938',
    dark: 'rgb(245, 92, 71)', // ! Reserved for hover button state
  },
  grey: {
    '100': '#F5F7FA',
    '200': '#E9EDF7',
    '300': '#EEEEEE',
    '400': '#c6c8ce',
    '500': '#526373',
    '600': '#707D96',
    '700': 'rgba(255, 255, 255, 0.24)',
  },
  divider: `rgba(16, 26, 51, 0.08)`,
  mode: 'light',
  text: {
    primary: '#101A33',
    secondary: '#707D96',
  },
};
