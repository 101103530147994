import { v4 as uuid } from 'uuid';

import type {
  MarketplaceFiltersValues,
  MarketplaceSortOptions,
} from 'modules/marketplace/MarketplaceOverviewPage/types';
import { toNetworkType } from 'store/orders/helpers';
import type { NetworkType } from 'store/proxies/types';
import type { SortOrder } from 'types';

import type {
  AvailableDealDTO,
  MarketplaceParams,
  MarketplaceParamsSortBy,
  MarketplaceParamsSortByType,
  MarketplaceResponse,
} from './dtos';
import type { DealModel } from './models';

export function toDeal(from: AvailableDealDTO): DealModel {
  return {
    id: uuid(),

    isPremium: !!from.isUnusedOnly,
    networkType: toNetworkType(from.serviceType),
    ipVersion: from.ipVersion,
    country: from.country,
    isp: from.isp,
    couponCode: from.couponCode,
    finalPrice: from.finalPrice,
    priceNoDiscounts: from.priceNoDiscounts,

    expiresAt: from.expiresAt,
    discount: from.discount,
  };
}

export function toDeals(from: MarketplaceResponse | AvailableDealDTO[]): DealModel[] {
  if (Array.isArray(from)) {
    return from.map(toDeal);
  }

  return from.availableDeals.map(toDeal);
}

export function toMarketplaceCountries(from: AvailableDealDTO[]): string[] {
  return from.map(({ country }) => country);
}

export function toGroupedDeals(from: DealModel[] = []): Record<NetworkType, DealModel[]> {
  return from.reduce(
    (acc, deal) => {
      if (acc[deal.networkType]) {
        return { ...acc, [deal.networkType]: [...acc[deal.networkType], deal] };
      }

      return { ...acc, [deal.networkType]: [deal] };
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as Record<NetworkType, DealModel[]>,
  );
}

export function toMarketplaceParamsSortBy(from: MarketplaceSortOptions): MarketplaceParamsSortBy {
  const [type, order] = from.toLowerCase().split('_');

  return { type: type as MarketplaceParamsSortByType, order: order as SortOrder };
}

export function toMarketplaceParams(from: MarketplaceFiltersValues): MarketplaceParams {
  return { country: from.country.filter(Boolean) as string[], sortBy: toMarketplaceParamsSortBy(from.sortBy) };
}
