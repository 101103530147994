import { useCallback, useMemo } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useDisableProxyAutoExtendMutation, useEnableProxyAutoExtendMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { ToastManager } from 'utils/toast';

import { ProxyAutoExtendBandwidthForm } from './ProxyAutoExtendBandwidthForm';
import type { ProxyAutoExtendBandwidthValues } from './types';

export type ProxyAutoExtendBandwidthModalProps = {
  proxyId: number;
  autoExtendBandwidth?: number;
  isAutoExtendEnabled?: boolean;
};

export function ProxyAutoExtendBandwidthModal({
  proxyId,
  autoExtendBandwidth,
  isAutoExtendEnabled,
}: ProxyAutoExtendBandwidthModalProps) {
  const { t } = useTranslation();
  const [enableAutoExtend] = useEnableProxyAutoExtendMutation();
  const [disableAutoExtend] = useDisableProxyAutoExtendMutation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const initialValues = useMemo<ProxyAutoExtendBandwidthValues>(
    () => ({
      autoExtendBandwidth: autoExtendBandwidth ?? 1,
      isAutoExtendEnabled: !!isAutoExtendEnabled,
    }),
    [autoExtendBandwidth, isAutoExtendEnabled],
  );

  const onSubmit = useCallback(
    async (values: ProxyAutoExtendBandwidthValues) => {
      try {
        if (values.isAutoExtendEnabled) {
          await enableAutoExtend({ proxyId, bandwidth: values.autoExtendBandwidth }).unwrap();
          posthog?.capture(posthogEvents.proxyAutoExtendEnabled);

          ToastManager.success(t('proxies.modals.autoExtend.success.enabled'));
        } else {
          await disableAutoExtend({ proxyId }).unwrap();
          posthog?.capture(posthogEvents.proxyAutoExtendDisabled);

          ToastManager.success(t('proxies.modals.autoExtend.success.disabled'));
        }

        hideModal();
      } catch (error) {
        const err = error as AxiosBaseQueryError;

        if (err.errors && typeof err.errors === 'string') {
          ToastManager.error(t(`errors:${err.errors}`));
        } else {
          ToastManager.error(t('proxies.modals.autoExtendBandwidth.failure'));
        }
      }
    },
    [disableAutoExtend, enableAutoExtend, hideModal, posthog, proxyId, t],
  );

  return (
    <Formik<ProxyAutoExtendBandwidthValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ProxyAutoExtendBandwidthForm />
    </Formik>
  );
}
