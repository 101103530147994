import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';

export function PayoutNotPossibleModal() {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const actions = useMemo(
    () => [
      <Button fullWidth onClick={hideModal}>
        {t('common:buttons.okGotIt')}
      </Button>,
    ],
    [hideModal, t],
  );

  return (
    <ModalContainer
      title={t('referrals.modals.payoutNotPossible.title')}
      subtitle={t('referrals.modals.payoutNotPossible.subtitle')}
      actions={actions}
    />
  );
}
