import isEmpty from 'lodash/isEmpty';

function getDecimalCurrencyPart(value: string) {
  const parsed = `.${value.replace(/[^0-9]/g, '')}`;

  return parsed.length > 3 ? parsed.substring(0, 3) : parsed;
}

export function formatCurrency(value: string) {
  const numberValue = value.replace(/[^0-9.,]/g, '');

  const priceParts = numberValue.split('.');

  const integerPart = priceParts[0].replace(/\D/g, '');

  const decimalPart = priceParts.length > 1 ? getDecimalCurrencyPart(priceParts[1]) : '';

  return `${integerPart ? `$${Number(integerPart).toLocaleString()}` : ''}${decimalPart}`;
}

export const getKeys = Object.keys as <T>(object: T) => Array<keyof T>;

export async function copyToClipboard(value: string) {
  if (!navigator.clipboard) return;

  try {
    await navigator.clipboard.writeText(value);

    return true;
  } catch {
    return false;
  }
}

export function createBlobLink(data: string, fileName: string, type = 'text/plain') {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();
  link.remove();
}

export function downloadConfigFile(data: string, fileName: string) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(data)}`;

  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.setAttribute('download', fileName);
  downloadAnchorNode.setAttribute('href', dataStr);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

type ParsedTimePeriod = {
  value: number;
  format: 'days' | 'months';
};

export function parseTimePeriod(from?: string | null): ParsedTimePeriod | null {
  if (!from) return null;

  const match = /^(?<value>-?(?:\d+)?\.?\d+) *(?<format>m?|d?)?$/i.exec(from);

  const groups = match?.groups as { value: string; format: string } | undefined;

  if (!groups) return null;

  const { value, format } = groups;

  const parsedValue = Number.isNaN(parseFloat(value)) ? 1 : parseFloat(value);

  return {
    value: parsedValue,
    format: format === 'd' ? 'days' : 'months',
  };
}

export function formatBandwidth(bandwidth: number | null = 0) {
  if (!bandwidth) return Number(0).toFixed(4);

  return (Math.round(bandwidth * 100) / 100).toFixed(4);
}

export function downloadFile(data: string | ArrayBuffer | ArrayBufferView | Blob, fileName: string): void {
  const blob = new Blob([data]);

  const blobURL = window.URL.createObjectURL
    ? window.URL.createObjectURL(blob)
    : window.webkitURL.createObjectURL(blob);

  const tempLink = document.createElement('a');

  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  setTimeout(() => {
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(blobURL);
  }, 200);
}

export function isObjectDeepEmpty<T extends object>(value: T): boolean {
  return Object.values(value).every((val) => {
    if (typeof val === 'object') {
      return isObjectDeepEmpty(val);
    }

    return isEmpty(val);
  });
}
