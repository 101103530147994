import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import type { MUIStyledCommonProps } from '@mui/system/createStyled';

import type { ITheme } from 'theme';

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  height: '100%',
}));

const FeatureRequestContainer = styled('div')<MUIStyledCommonProps<ITheme>>(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  minHeight: '100%',
  paddingTop: theme.appBar.height,

  marginTop: theme.spacing(8),

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    paddingTop: `calc(${theme.appBar.height}px + ${theme.spacing(4)})`,
  },
}));

const AppBarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'fixed',
  boxShadow: `0px 1px 0px ${theme.palette.divider}`,
}));

const AppBarToolbar = styled(Toolbar)<MUIStyledCommonProps<ITheme>>(({ theme }) => ({
  height: theme.appBar.height,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
}));

const AppBarLogoWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
  maxHeight: 64,

  zIndex: theme.zIndex.appBar + 200,
}));

const AppBarIconButton = styled(IconButton)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 200,
}));

export default {
  LayoutRoot,
  FeatureRequestContainer,

  AppBarRoot,
  AppBarToolbar,
  AppBarLogoWrapper,
  AppBarIconButton,
};
