import { useMemo } from 'react';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals/ModalContainer';
import i18n from 'translations';

import { useHideModal } from '../context';

export type ConfirmationModalProps = {
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  subtitle?: string | null;
  cancelLabel?: string | null;
  confirmLabel?: string | null;
  confirmDanger?: boolean;
  isLoading?: boolean;
  closeDisabled?: boolean;
};

export const ConfirmationModal = ({
  title,
  subtitle,
  cancelLabel = i18n.t('common:buttons.cancel'),
  confirmLabel = i18n.t('common:buttons.confirm'),
  onConfirm,
  onCancel,
  confirmDanger = false,
  isLoading = false,
  closeDisabled = false,
}: ConfirmationModalProps) => {
  const hideModal = useHideModal();

  const actions = useMemo(() => {
    const supportedActions = [];

    if (!closeDisabled) {
      supportedActions.push(
        <Button
          key="cancel"
          color="secondary"
          fullWidth
          onClick={() => {
            if (onCancel) return onCancel();

            return hideModal();
          }}
        >
          {cancelLabel}
        </Button>,
      );
    }

    return supportedActions.concat([
      <Button
        key="confirm"
        color={confirmDanger ? 'error' : 'primary'}
        fullWidth
        loading={isLoading}
        onClick={onConfirm}
      >
        {confirmLabel}
      </Button>,
    ]);
  }, [closeDisabled, confirmDanger, isLoading, onConfirm, confirmLabel, cancelLabel, onCancel, hideModal]);

  return <ModalContainer title={title} subtitle={subtitle} actions={actions} />;
};
