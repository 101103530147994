import type { AppEndpointBuilder } from 'store/base';

import type { MarketplaceParams } from './dtos';
import { toDeals, toMarketplaceCountries } from './helpers';
import type { DealModel } from './models';

export const marketplaceEndpoints = (builder: AppEndpointBuilder) => ({
  getMarketplaceDeals: builder.query<DealModel[], MarketplaceParams>({
    query: (params) => ({
      url: 'marketplace',
      method: 'GET',
      params,
    }),
    transformResponse: toDeals,
  }),
  getMarketplaceAvailableCountries: builder.query<string[], void>({
    query: () => ({
      url: 'marketplace',
      method: 'GET',
    }),
    transformResponse: toMarketplaceCountries,
  }),
});
