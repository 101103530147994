import { useCallback, useMemo } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { shouldBeHandledByStripe } from 'modules/billing/helpers';
import type { TopUpPaymentOptionType } from 'modules/billing/types';

import { TopUpForm } from './TopUpForm';
import { useTopUpModalContext } from '../context';
import { buildTopUpPaymentOptions } from '../helpers';
import type { TopUpValues } from '../types';

const schema: Yup.Schema<TopUpValues> = Yup.object({
  paymentFee: Yup.string().required(),
  type: Yup.mixed<TopUpPaymentOptionType>()
    .oneOf([
      'alipay',
      'another-card',
      'blik',
      'crypto',
      'existing-card',
      'giropay',
      'ideal',
      'sofort',
      'new-card',
      'p24',
      'paypal',
    ])
    .required(),
  amount: Yup.number().minimumTopUpValue().required(),
});

type TopUpStepProps = {
  initialValues?: Partial<TopUpValues>;
};

export function TopUpStep({ initialValues: propInitialValues }: TopUpStepProps) {
  const { creditCard, values: topUpValues, handleTopUp, setStep, setValues } = useTopUpModalContext();
  const options = useMemo(() => buildTopUpPaymentOptions(creditCard), [creditCard]);

  const initialValues = useMemo<TopUpValues>(
    () => ({
      ...options[0],
      ...propInitialValues,
      ...topUpValues,
      amount: propInitialValues?.amount ?? topUpValues?.amount ?? 9,
    }),
    [options, propInitialValues, topUpValues],
  );

  const onFormSubmit = useCallback(
    async (values: TopUpValues) => {
      setValues(values);

      if (shouldBeHandledByStripe(values.type)) {
        return setStep('stripe');
      }

      await handleTopUp({ values, onSuccess: () => setStep('status') });
    },
    [handleTopUp, setStep, setValues],
  );

  return (
    <Formik<TopUpValues>
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={schema}
      enableReinitialize
      validateOnChange
      validateOnMount
    >
      <TopUpForm options={options} />
    </Formik>
  );
}
