import AppBar, { type AppBarProps } from '@mui/material/AppBar';
import Badge, { badgeClasses } from '@mui/material/Badge';
import Box, { type BoxProps } from '@mui/material/Box';
import { iconButtonClasses } from '@mui/material/IconButton';
import Stack, { stackClasses } from '@mui/material/Stack';
import { styled, type Breakpoint } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

export type ContainerProps = {
  isMaintenanceVisible?: boolean;
};

const MAINTENANCE_OFFSET: Record<Breakpoint, number> = {
  xs: 113,
  sm: 81,
  md: 65,
  lg: 57,
  xl: 37,
};

const APP_BAR_HEIGHT = 64;

const DashboardLayoutRoot = styled(({ isMaintenanceVisible, ...props }: BoxProps & ContainerProps) => (
  <Box {...props} />
))(({ isMaintenanceVisible, theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: APP_BAR_HEIGHT,

  ...(isMaintenanceVisible && {
    paddingTop: APP_BAR_HEIGHT + MAINTENANCE_OFFSET.xl,
    transition: theme.transitions.create('paddingTop'),
  }),

  [theme.breakpoints.up('md')]: {
    paddingLeft: 280,
  },

  [theme.breakpoints.between('xs', 'sm')]: {
    ...(isMaintenanceVisible && { paddingTop: APP_BAR_HEIGHT + MAINTENANCE_OFFSET.xs }),
  },

  [theme.breakpoints.between('sm', 'md')]: {
    ...(isMaintenanceVisible && { paddingTop: APP_BAR_HEIGHT + MAINTENANCE_OFFSET.sm }),
  },

  [theme.breakpoints.between('md', 'lg')]: {
    ...(isMaintenanceVisible && { paddingTop: APP_BAR_HEIGHT + MAINTENANCE_OFFSET.md }),
  },

  [theme.breakpoints.between('lg', 'xl')]: {
    ...(isMaintenanceVisible && { paddingTop: APP_BAR_HEIGHT + MAINTENANCE_OFFSET.lg }),
  },
}));

const DashboardLayoutContentRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
  position: 'relative',

  paddingBottom: theme.spacing(8),
}));

const AccountButtonWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),
}));

const DashboardNavbarRoot = styled(({ isMaintenanceVisible, ...props }: AppBarProps & ContainerProps) => (
  <AppBar {...props} />
))(({ isMaintenanceVisible, theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0px 1px 0px ${theme.palette.divider}`,

  ...(isMaintenanceVisible && {
    transition: theme.transitions.create('top'),
    top: MAINTENANCE_OFFSET.xl,
  }),

  [theme.breakpoints.up('md')]: {
    left: 280,
    width: 'calc(100% - 280px)',
  },

  [theme.breakpoints.between('xs', 'sm')]: {
    ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.xs }),
  },

  [theme.breakpoints.between('sm', 'md')]: {
    ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.sm }),
  },

  [theme.breakpoints.between('md', 'lg')]: {
    ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.md }),
  },

  [theme.breakpoints.between('lg', 'xl')]: {
    ...(isMaintenanceVisible && { top: MAINTENANCE_OFFSET.lg }),
  },
}));

const DashboardNavbarToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_HEIGHT,
  left: 0,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
  },
}));

const FloatingButtonBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    transform: 'none',
    top: 2,
  },
});

const MaintenanceContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,

  alignItems: 'center',
  flexDirection: 'row',
  columnGap: theme.spacing(0.4),
  boxShadow: `0px 1px 0px 0px ${theme.palette.divider}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  height: MAINTENANCE_OFFSET.xl - 1,
  transition: theme.transitions.create('height'),
  backgroundColor: theme.palette.common.white,

  [`& > .${stackClasses.root}`]: {
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
  },

  [`& > .${iconButtonClasses.root}`]: {
    padding: 0,
    marginLeft: 'auto',

    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(2),
    },
  },

  [theme.breakpoints.between('xs', 'md')]: {
    justifyContent: 'center',
    flexDirection: 'column',

    [`& > .${stackClasses.root}`]: {
      columnGap: theme.spacing(1),
    },
  },

  [theme.breakpoints.between('xs', 'sm')]: {
    height: MAINTENANCE_OFFSET.xs - 1,
  },

  [theme.breakpoints.between('sm', 'md')]: {
    height: MAINTENANCE_OFFSET.sm - 1,
  },

  [theme.breakpoints.between('md', 'lg')]: {
    height: MAINTENANCE_OFFSET.md - 1,
  },

  [theme.breakpoints.between('lg', 'xl')]: {
    height: MAINTENANCE_OFFSET.lg - 1,
  },
}));

export default {
  DashboardNavbarRoot,
  DashboardNavbarToolbar,
  DashboardLayoutRoot,
  DashboardLayoutContentRoot,
  AccountButtonWrapper,
  FloatingButtonBadge,
  MaintenanceContainer,
};
