import { autocompleteClasses } from '@mui/material/Autocomplete';
import Box, { type BoxProps } from '@mui/material/Box';
import FormGroup, { type FormGroupProps } from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';

import { Autocomplete as MuiAutocomplete, type AutocompleteProps as MuiAutocompleteProps } from 'formik-mui';

import { Icon } from 'components/Icon';

const Container = styled(({ fullWidth, ...props }: FormGroupProps & { fullWidth?: boolean }) => (
  <FormGroup {...props} />
))(({ fullWidth }) => ({
  ...(fullWidth && {
    width: '100%',
  }),
}));

const SelectedIcon = styled(Icon)({
  marginLeft: 'auto',
});

type AdornmentProps = BoxProps & {
  adornmentPosition?: 'left' | 'right';
};

const Adornment = styled(({ adornmentPosition, ...props }: AdornmentProps) => <Box {...props} />)(
  ({ adornmentPosition = 'left', theme }) => ({
    display: 'flex',
    columnGap: theme.spacing(0.5),

    ...(adornmentPosition === 'left' && {
      marginRight: theme.spacing(1),
    }),

    ...(adornmentPosition === 'right' && {
      marginLeft: 'auto',
    }),
  }),
);

const Autocomplete = styled(
  (props: MuiAutocompleteProps<string | string[], boolean, boolean, boolean> & { value: string | string[] }) => (
    <MuiAutocomplete {...props} />
  ),
)(({ theme }) => ({
  [`& .${autocompleteClasses.inputRoot}:has(.${autocompleteClasses.tagSizeSmall})`]: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },

  [`& .${autocompleteClasses.inputRoot}:not(:has(.${autocompleteClasses.tagSizeSmall}))`]: {
    margin: 0,
  },
}));

export default { Container, Autocomplete, Adornment, SelectedIcon };
