import { ReactComponent as ApiIcon } from 'assets/icons/navigation/api.svg';
import { ReactComponent as MyProxiesIcon } from 'assets/icons/navigation/my-proxies.svg';
import { ReactComponent as NeedHelpIcon } from 'assets/icons/navigation/need-help.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/navigation/overview.svg';
import { ReactComponent as ReferralsIcon } from 'assets/icons/navigation/referrals.svg';
import { ReactComponent as VPNsIcon } from 'assets/icons/navigation/vpns.svg';
import { ReactComponent as WhatIsMyIpIcon } from 'assets/icons/navigation/what-is-my-ip.svg';

export const navigation = {
  api: ApiIcon,
  'need-help': NeedHelpIcon,
  overview: OverviewIcon,
  referrals: ReferralsIcon,
  vpns: VPNsIcon,
  'what-is-my-ip': WhatIsMyIpIcon,
  'my-proxies': MyProxiesIcon,
};
