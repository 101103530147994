import type { CSSProperties } from 'react';

import { createTheme as createMuiTheme, type Theme } from '@mui/material/styles';

import { theme as themeOverrides, type ExtendedTheme } from './theme';

export interface ITheme extends Theme, ExtendedTheme {}

declare module '@mui/material/styles' {
  export interface TypographyVariants {
    hero: CSSProperties;
    'headline-1': CSSProperties;
    'headline-2': CSSProperties;
    'paragraph-1': CSSProperties;
    'paragraph-2': CSSProperties;
    title: CSSProperties;
    subtitle: CSSProperties;
    'body-1': CSSProperties;
    'body-2': CSSProperties;
    caption: CSSProperties;
    badge: CSSProperties;
  }

  export interface TypographyVariantsOptions {
    hero: CSSProperties;
    'headline-1': CSSProperties;
    'headline-2': CSSProperties;
    'paragraph-1': CSSProperties;
    'paragraph-2': CSSProperties;
    title: CSSProperties;
    subtitle: CSSProperties;
    'body-1': CSSProperties;
    'body-2': CSSProperties;
    caption: CSSProperties;
    badge: CSSProperties;
  }

  interface PaletteColor {
    main: string;
    dark: string;
    darkest?: string;
    contrastText: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hero: true;
    'headline-1': true;
    'headline-2': true;
    'paragraph-1': true;
    'paragraph-2': true;
    title: true;
    subtitle: true;
    'body-1': true;
    'body-2': true;
    caption: true;
    badge: true;
  }
}

function createTheme() {
  return createMuiTheme(themeOverrides) as ITheme;
}

export const theme = createTheme();
