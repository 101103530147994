import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { setTokens } from 'store/auth/reducers';

export function useAuthParamsGuard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    const refreshToken = searchParams.get('refresh_token');

    if (!accessToken || !refreshToken) {
      return;
    }

    dispatch(setTokens({ accessToken, refreshToken }));
    setSearchParams('');
  }, [dispatch, searchParams, setSearchParams]);
}
