import type { Currency } from 'modules/billing/types';
import { SupportedLanguage } from 'modules/common/Language/types';

import {
  type AccountEnableTOTPResponse,
  type AddCreditCardDTO,
  type CreditCardDTO,
  LanguageDTO,
  type PaymentFeeDTO,
  type TopUpDTO,
  type TopUpStatusDTO,
} from './dtos';
import type { AddCreditCardModel, CreditCardModel, PaymentFeeModel, TopUpModel, TopUpStatusModel } from './models';

export function isAccountEnableToTPResponse(
  response: AccountEnableTOTPResponse | Record<string, unknown>,
): response is AccountEnableTOTPResponse {
  return 'secret' in response;
}

export function toLanguagePayload(language: SupportedLanguage): LanguageDTO {
  switch (language) {
    case SupportedLanguage.CN:
      return LanguageDTO.CN;
    case SupportedLanguage.EN:
      return LanguageDTO.EN;
    case SupportedLanguage.ES:
      return LanguageDTO.ES;
    case SupportedLanguage.RU:
      return LanguageDTO.RU;
    case SupportedLanguage.VI:
      return LanguageDTO.VI;
    default:
      return LanguageDTO.EN;
  }
}

export function toSupportedLanguage(language: LanguageDTO): SupportedLanguage {
  switch (language) {
    case LanguageDTO.CN:
      return SupportedLanguage.CN;
    case LanguageDTO.EN:
      return SupportedLanguage.EN;
    case LanguageDTO.ES:
      return SupportedLanguage.ES;
    case LanguageDTO.RU:
      return SupportedLanguage.RU;
    case LanguageDTO.VI:
      return SupportedLanguage.VI;
    default:
      return SupportedLanguage.EN;
  }
}

// #region Response to Model mappers

/**
 * Converts credit card response to credit card model
 *
 * @param {CreditCardDTO} from
 * @returns {CreditCardModel}
 */
export function toCreditCardModel(from: CreditCardDTO): CreditCardModel {
  return from;
}

/**
 * Converts add credit card response to add credit card model
 *
 * @param {AddCreditCardDTO} from
 * @returns {AddCreditCardModel}
 */
export function toAddCreditCardModel(from: AddCreditCardDTO): AddCreditCardModel {
  return { clientSecret: from.authToken };
}

/**
 * Converts top up response to top up model
 *
 * @param {TopUpDTO} from
 * @returns {TopUpModel}
 */
export function toTopUpModel(from: TopUpDTO): TopUpModel {
  return from;
}

/**
 * Converts top up status response to top tup status model
 *
 * @param {TopUpStatusDTO} from
 * @returns {TopUpStatusModel}
 */
export function toTopUpStatusModel(from: TopUpStatusDTO): TopUpStatusModel {
  return from;
}

/**
 * Converts payment fee response to payment fee model
 *
 * @param {PaymentFeeDTO} from
 * @returns {PaymentFeeModel}
 */
export function toPaymentFeeModel(from: PaymentFeeDTO): PaymentFeeModel {
  return { ...from, currency: from.currency.toLowerCase() as Currency };
}

// #endregion
