import { Children, type MouseEventHandler, type ReactNode, forwardRef, useMemo, useState } from 'react';

import type { LoadingButtonTypeMap, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import { Icon } from 'components/Icon';
import type { IconName, IconProps } from 'components/Icon/types';

import Styled from './styled';

export type ButtonProps = Omit<MuiLoadingButtonProps, 'startIcon' | 'endIcon'> & {
  startIcon?: IconName;
  customStartIcon?: ReactNode;
  endIcon?: IconName;
  customEndIcon?: ReactNode;
  maxWidth?: number;
  slotProps?: { startIcon?: Partial<IconProps>; endIcon?: Partial<IconProps> };
};

export const Button: OverridableComponent<LoadingButtonTypeMap<ButtonProps>> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      size = 'large',
      startIcon,
      customStartIcon,
      endIcon,
      customEndIcon,
      color = 'primary',
      onClick,
      slotProps,
      ...props
    }: MuiLoadingButtonProps & ButtonProps,
    ref,
  ) => {
    const [debounceDisable, setDebounceDisable] = useState(false);
    const isLarge = useMemo(() => size === 'large', [size]);

    const iconSize = isLarge ? 'large' : 'small';

    // globaly prevent double clicks
    const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      if (!debounceDisable) {
        setDebounceDisable(true);
        onClick?.(e);

        setTimeout(() => {
          setDebounceDisable(false);
        }, 500);
      }
    };

    if (Children.count(children) === 0 && startIcon) {
      return (
        <Styled.MuiButton
          ref={ref}
          variant="contained"
          data-testid="button-start-icon"
          color={color}
          size={size}
          startIcon={
            customStartIcon ?? (
              <Icon name={startIcon} size={slotProps?.startIcon?.size ?? iconSize} {...slotProps?.startIcon} />
            )
          }
          disableRipple={props.variant === 'text'}
          onClick={onButtonClick}
          {...props}
        />
      );
    }

    if (Children.count(children) === 0 && endIcon) {
      return (
        <Styled.MuiButton
          ref={ref}
          variant="contained"
          data-testid="button-end-icon"
          color={color}
          size={size}
          endIcon={
            customEndIcon ?? <Icon name={endIcon} size={slotProps?.endIcon?.size ?? iconSize} {...slotProps?.endIcon} />
          }
          disableRipple={props.variant === 'text'}
          onClick={onButtonClick}
          {...props}
        />
      );
    }

    return (
      <Styled.MuiButton
        ref={ref}
        variant="contained"
        color={color}
        size={size}
        startIcon={
          customStartIcon ??
          (startIcon && (
            <Icon name={startIcon} size={slotProps?.startIcon?.size ?? iconSize} {...slotProps?.startIcon} />
          ))
        }
        endIcon={
          customEndIcon ??
          (endIcon && <Icon name={endIcon} size={slotProps?.endIcon?.size ?? iconSize} {...slotProps?.endIcon} />)
        }
        disableRipple={props.variant === 'text'}
        $hasBothIcons
        $isLarge={isLarge}
        onClick={onButtonClick}
        {...props}
      >
        {children}
      </Styled.MuiButton>
    );
  },
);
