import { useMemo } from 'react';

import { useGetUserVPNQuery } from 'store/api';
import { VPNStatus } from 'store/vpns/dtos';

import { useFeatureFlag } from './useFeatureFlag';

export function useVPN() {
  const { data: vpn, isLoading: isVPNLoading, ...query } = useGetUserVPNQuery();
  const { isLegacyVpnSupportEnabled } = useFeatureFlag();

  const shouldDisplayVPN = useMemo(() => {
    if (isVPNLoading) return false;

    if (!vpn) return false;

    return vpn.status === VPNStatus.ACTIVE || isLegacyVpnSupportEnabled;
  }, [isLegacyVpnSupportEnabled, isVPNLoading, vpn]);

  return useMemo(() => {
    return { ...query, vpn, isVPNLoading, shouldDisplayVPN };
  }, [isVPNLoading, query, shouldDisplayVPN, vpn]);
}
