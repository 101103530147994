import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';

import Styled from './styled';

type OrderFieldTooltipProps = {
  title: string;
};

export function OrderFieldTooltip({ title }: OrderFieldTooltipProps) {
  return (
    <Styled.Container>
      <Tooltip title={title} arrow>
        <Styled.IconButton>
          <Icon name="question" iconStroke="#101A333D" size="small" />
        </Styled.IconButton>
      </Tooltip>
    </Styled.Container>
  );
}
