import type { ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/Icon';
import type { IconProps } from 'components/Icon/types';

import Styled from './styled';

type BaseResultProps = {
  title?: string | null;
  subtitle?: ReactNode;
  actions?: ReactNode;
};

type ResultCustomIconProps = BaseResultProps & {
  iconName?: never;
  Icon: ReactNode;
};

type ResultIconProps = BaseResultProps & {
  iconName: IconProps['name'];
  Icon?: never;
};

type ResultProps = ResultCustomIconProps | ResultIconProps;

export function Result({ iconName, Icon: CustomIcon, title, subtitle, actions }: ResultProps) {
  return (
    <Styled.Container container rowGap={2} direction="column">
      <Grid item>
        {CustomIcon && CustomIcon}

        {iconName && <Icon name={iconName} size={48} iconStroke={(theme) => theme.palette.text.secondary} />}
      </Grid>

      <Grid item>
        {(title || subtitle) && (
          <Grid container rowGap={0.5} direction="column">
            {title && (
              <Grid item>
                <Typography variant="headline-2" color="text.secondary">
                  {title}
                </Typography>
              </Grid>
            )}
            {subtitle && (
              <Grid item>
                <Styled.Subtitle variant="body-1" color="text.secondary">
                  {subtitle}
                </Styled.Subtitle>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {actions && (
        <Grid item pt={2}>
          {actions}
        </Grid>
      )}
    </Styled.Container>
  );
}
