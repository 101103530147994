import FormGroup, { type FormGroupProps } from '@mui/material/FormGroup';
import MuiMenuItem, { type MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import MuiSelect, { selectClasses, type SelectProps as MuiSelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export type ContainerProps = FormGroupProps & {
  floating?: boolean;
};

const Container = styled(({ floating, ...props }: ContainerProps) => <FormGroup {...props} />)(
  ({ floating, theme }) => ({
    width: '100%',

    ...(floating && {
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
      width: 'auto',
    }),
  }),
);

type SelectProps = MuiSelectProps<string> & {
  iconOnly?: boolean;
};

const Select = styled(({ iconOnly, ...props }: SelectProps) => <MuiSelect {...props} />)(({ iconOnly, theme }) => ({
  [`& .${selectClasses.outlined}`]: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),

    ...(iconOnly && {
      justifyContent: 'center',
      paddingRight: `${theme.spacing(3)} !important`,
    }),
  },
}));

export type MenuItemProps = MuiMenuItemProps & {
  iconOnly?: boolean;
};

const MenuItem = styled(({ iconOnly, ...props }: MenuItemProps) => <MuiMenuItem {...props} />)(
  ({ iconOnly, theme }) => ({
    ...(iconOnly && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),

    columnGap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      minHeight: 36,
    },
  }),
);

export default { Container, MenuItem, Select };
