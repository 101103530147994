import { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { Input } from 'components/form-partials/Input';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { TextButton } from 'components/TextButton';
import { posthogEvents } from 'constants/posthogEvents';
import { getValidationErrors } from 'hooks/useRequestError';
import {
  useGetGlobalIPWhitelistSettingsQuery,
  useGetUserSessionQuery,
  useUpdateGlobalIPWhitelistSettingsMutation,
} from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { GlobalIPWhitelistPayload } from 'store/proxies/dtos';
import { ToastManager } from 'utils/toast';

export type GlobalIPWhitelistModalProps = {
  onConfirm: () => void;
};

type IPWhitelistValues = {
  ipWhitelist: string[];
};

const schema: Yup.Schema<IPWhitelistValues> = Yup.object().shape({
  ipWhitelist: Yup.array().of(Yup.string().default('').optional()).required(),
});

export function GlobalIPWhitelistModal({ onConfirm }: GlobalIPWhitelistModalProps) {
  const { t } = useTranslation();
  const { data } = useGetGlobalIPWhitelistSettingsQuery();
  const posthog = usePostHog();
  const [updateGlobalIPWhitelist] = useUpdateGlobalIPWhitelistSettingsMutation();

  const hideModal = useHideModal();
  const { data: session } = useGetUserSessionQuery();

  const initialValues = useMemo<IPWhitelistValues>(() => {
    if (!data) return { ipWhitelist: ['', '', ''] };

    const ipWhitelist = Array.from(Array(data.allowedWhitelistIpCount)).map(() => '');

    if (!data.whitelistedIps.length) {
      return { ipWhitelist };
    }

    return {
      ipWhitelist: ipWhitelist.map((item, i) => data.whitelistedIps[i] ?? item),
    };
  }, [data]);

  const mapResponseKeyIntoFormKey = useCallback((key: keyof GlobalIPWhitelistPayload): keyof IPWhitelistValues => {
    if (key === 'ips') return 'ipWhitelist';

    throw new Error('Unsupported key');
  }, []);

  return (
    <Formik<IPWhitelistValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async ({ ipWhitelist }, { setErrors, setSubmitting }) => {
        try {
          await updateGlobalIPWhitelist({ ips: ipWhitelist.filter((i) => Boolean(i.length)) }).unwrap();

          posthog?.capture(posthogEvents.proxyGlobalIPWhitelistModified);

          ToastManager.success(t('proxies.modals.globalIPWhitelist.success'));
          onConfirm();
        } catch (ex) {
          const validationErrors = getValidationErrors(ex as AxiosBaseQueryError, mapResponseKeyIntoFormKey);

          if (validationErrors && Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
          }

          setSubmitting(false);
        }
      }}
      validationSchema={schema}
      validateOnMount
      validateOnChange
    >
      {({ values, handleSubmit, setFieldValue, isValid, dirty, isSubmitting }) => {
        const actions = [
          <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
            {t('common:buttons.cancel')}
          </Button>,
          <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
            {t('common:buttons.update')}
          </Button>,
        ];

        const setIP = () => {
          const isAnyFieldEmpty = values.ipWhitelist.some((item) => !item.length);

          if (!isAnyFieldEmpty) return setFieldValue('ipWhitelist[0]', session?.userIp || '');

          const emptyIndex = values.ipWhitelist.findIndex((item) => !item.length);

          return setFieldValue(`ipWhitelist[${emptyIndex}]`, session?.userIp || '');
        };

        return (
          <ModalContainer
            component="form"
            containerProps={{ onSubmit: handleSubmit, noValidate: true }}
            title={t('proxies.modals.globalIPWhitelist.title')}
            subtitle={t('proxies.modals.globalIPWhitelist.subtitle')}
            actions={actions}
            disablePortal
          >
            <Grid container direction="column" rowSpacing={4}>
              <Grid item display="flex" flexDirection="column" rowGap={2}>
                {values.ipWhitelist.map((_, index) => (
                  <Grid item key={`ip-whitelist-${index}`}>
                    <Input fullWidth name={`ipWhitelist[${index}]`} />
                  </Grid>
                ))}
              </Grid>
              {session?.userIp && (
                <Grid item display="flex" justifyContent="center">
                  <TextButton onClick={setIP}>
                    {t('common:buttons.setYourCurrentIp', { ip: session.userIp })}
                  </TextButton>
                </Grid>
              )}
            </Grid>
          </ModalContainer>
        );
      }}
    </Formik>
  );
}
