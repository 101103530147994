/**
 * Common types for order
 */
import type { NetworkType } from 'store/proxies/types';

import type {
  OrderPriceModel,
  ProxySetupEnabledFields,
  ProxySetupFormConfigurations,
  ProxySetupFormErrors,
  ProxySetupModel,
} from './models';

export type Range = {
  min: number;
  max: number;
};

export type Choices = {
  choices: number[];
};

export type ISPOption = {
  id: string;
  name: string;
  country: string;
  amount: string;
};

export type OrderStep = 'proxy-setup' | 'personal-details' | 'checkout' | 'vpn-plan';

export type OrderState = {
  /**
   * Coupon code provided by user
   */
  couponCode: string | null;
  /**
   * User's selected network type for proxy flow
   */
  selectedNetworkType: NetworkType | null;
  /**
   * User's current order flow step
   */
  currentStep: OrderStep | null;
  /**
   * Price for user's configured proxy
   */
  price: OrderPriceModel | null;

  /**
   * Price loading flag
   */
  isPriceLoading: boolean;

  /**
   * User's selected proxy configuration
   */
  proxySettings: ProxySetupModel | null;

  /**
   * Proxy configurations for User's selected proxy configuration
   */
  proxyFieldsConfigurations: ProxySetupFormConfigurations | null;

  /**
   * Proxy Setup form errors
   */
  proxyFieldsErrors: ProxySetupFormErrors | null;

  /**
   * Proxy Setup form enabled fields
   */
  proxyFieldsEnabled: ProxySetupEnabledFields | null;
};

export enum OrderStatus {
  WAITING = 'WAITING',
  PAYING = 'PAYING',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  FAILED = 'FAILED',
}

// #region Helpers

export type ToSupportedTimePeriodModelArgs = {
  fromDays: Choices;
  fromMonths: Range;
  supportsSettingDays: boolean;
  supportsSettingMonths: boolean;
};

// #endregion

export type ResetOrderFlowPayload = {
  shouldResetFlow?: boolean;
  shouldResetCouponCode?: boolean;
};
