import { forwardRef } from 'react';

import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export type ButtonProps = MuiButtonProps & {
  isSelected?: boolean;
};

const Button = styled(
  forwardRef<HTMLButtonElement, ButtonProps>(({ isSelected, ...props }, ref) => <MuiButton ref={ref} {...props} />),
)(({ isSelected, theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  border: '1px solid rgba(16, 26, 51, 0.12)',
  padding: `calc(${theme.spacing(1.5)} - 1px) calc(${theme.spacing(2)} - 1px)`,

  ...(isSelected && {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: `calc(${theme.spacing(1.5)} - 2px) calc(${theme.spacing(2)} - 2px)`,

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  }),
}));

export default { Button };
