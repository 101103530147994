import { forwardRef } from 'react';

import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import type { DiagnosticRoutineModel } from 'store/proxies/models';

type ResultItemProps = DiagnosticRoutineModel & { withDivider?: boolean };

// TODO: Add redirect from the ITEM
export const ResultItem = forwardRef<HTMLDivElement, ResultItemProps>(function ResultItemComponent(
  { url, metadata, statusCode, time, withDivider },
  ref,
) {
  const { t } = useTranslation();

  return (
    <Stack ref={ref} spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.4} alignItems="center">
          <Typography variant="caption">{t('proxies.overview.admin.actions.diagnostic.results.url')}</Typography>

          <Typography variant="body-2">{url}</Typography>
        </Stack>

        <Chip label={statusCode} color={statusCode >= 200 && statusCode <= 300 ? 'success' : 'error'} />
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.4} alignItems="center" />

        <Typography variant="caption">
          {t('proxies.overview.admin.actions.diagnostic.results.time', { time: time.toFixed(2) })}
        </Typography>
      </Stack>

      <Stack spacing={1} maxWidth={356}>
        <Typography variant="subtitle">{t('proxies.overview.admin.actions.diagnostic.results.metadata')}</Typography>

        <Stack sx={{ wordBreak: 'break-all', overflow: 'auto' }}>
          <Typography component="pre" variant="body-2">
            {JSON.stringify(metadata, null, 2)}
          </Typography>
        </Stack>
      </Stack>

      {withDivider && <Divider />}
    </Stack>
  );
});
