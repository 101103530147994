import { toSupportedLanguage } from 'store/accounts/helpers';
import {
  type AuthenticationDTO,
  type CaptchaDTO,
  type ForgotPasswordPayload,
  type MultiFactorDTO,
  type MultiFactorPayload,
  type ProfileDetailsDTO,
  type SessionDTO,
  type SignInPayload,
  type SignUpPayload,
  type TokenDTO,
  type VerificationRequestDTO,
  VerificationStatusDTO,
} from 'store/auth/dtos';

import {
  type AuthenticationModel,
  type CaptchaModel,
  type ForgotPasswordModel,
  type MultiFactorModel,
  type MultiFactorPayloadModel,
  type ProfileDetailsModel,
  type SessionModel,
  type SignInModel,
  type SignUpModel,
  type TokenModel,
  type VerificationRequestModel,
  VerificationStatus,
} from './models';

export function toCaptchaModel({ captchaEnabled, captchaSiteKey }: CaptchaDTO): CaptchaModel {
  return { isEnabled: captchaEnabled, siteKey: captchaSiteKey };
}

export function toSignInPayload({ email, ...from }: SignInModel): SignInPayload {
  return { ...from, username: email };
}

export function toSignUpPayload({ password, ...from }: SignUpModel): SignUpPayload {
  return { ...from, plainPassword: password };
}

export function toTokenModel({ token, ...from }: TokenDTO): TokenModel {
  return { ...from, accessToken: token };
}

export function toMultiFactorModel(from: MultiFactorDTO): MultiFactorModel {
  return { ...from };
}

export function toAuthenticationModel({ mfa, refreshToken, token }: AuthenticationDTO): AuthenticationModel {
  return { accessToken: token, refreshToken, mfa: mfa ? toMultiFactorModel(mfa) : null };
}

export function toForgotPasswordPayload(from: ForgotPasswordModel): ForgotPasswordPayload {
  return from;
}

export function toMultiFactorPayload(from: MultiFactorPayloadModel): MultiFactorPayload {
  return from;
}

export function toVerificationStatus(from?: VerificationStatusDTO): VerificationStatus {
  if (from === VerificationStatusDTO.VERIFICATION_FAILED) {
    return VerificationStatus.FAILED;
  }

  if (from === VerificationStatusDTO.VERIFICATION_PENDING) {
    return VerificationStatus.PENDING;
  }

  if (from === VerificationStatusDTO.VERIFICATION_REQUIRED) {
    return VerificationStatus.REQUIRED;
  }

  if (from === VerificationStatusDTO.VERIFICATION_SUCCESSFUL) {
    return VerificationStatus.SUCCESSFUL;
  }

  return VerificationStatus.NOT_VERIFIED;
}

export function toVerificationRequestModel(from?: VerificationRequestDTO): VerificationRequestModel {
  return { reason: from?.reason ?? null, status: toVerificationStatus(from?.status) };
}

export function toProfileDetailsModel(from?: ProfileDetailsDTO): ProfileDetailsModel {
  return {
    email: from?.email ?? null,
    firstName: from?.firstName ?? null,
    lastName: from?.lastName ?? null,
    valid: from?.valid ?? false,
    isActive: from?.isActive ?? false,
    isSuspended: from?.isSuspended ?? false,
    suspensionReason: from?.suspensionReason,
    suspensionReasonExplanation: from?.suspensionReasonExplanation,
    verificationRequest: toVerificationRequestModel(from?.verificationRequest),
    isProfileSkipped: from?.isProfileSkipped ?? false,
  };
}

export function toSessionModel(from: SessionDTO): SessionModel {
  return {
    userId: from.userId,
    userIp: from.userIp,
    language: from.language ? toSupportedLanguage(from.language) : null,
    profileDetails: toProfileDetailsModel(from.profileDetails),
    roles: from.roles,
  };
}
