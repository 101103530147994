import type { AppEndpointBuilder } from 'store/base';
import type { Paginate, PaginationParams } from 'types';

import type {
  BankDetails,
  PayoutRequest,
  ReferralsDetails,
  ReferredUser,
  Transaction,
  UpsertBankDetailsPayload,
} from './dtos';

export const referralsEndpoints = (builder: AppEndpointBuilder) => ({
  getReferralsDetails: builder.query<ReferralsDetails, void>({
    query: () => ({
      url: 'referrals/details',
      method: 'GET',
    }),
    providesTags: ['Referrals-Details'],
  }),

  getReferredUsers: builder.query<Paginate<ReferredUser>, PaginationParams>({
    query: (params) => ({
      url: 'referrals/referred-users',
      method: 'GET',
      params,
    }),
  }),

  getReferralTransactions: builder.query<Paginate<Transaction>, PaginationParams>({
    query: (params) => ({
      url: 'referrals/transactions',
      method: 'GET',
      params,
    }),
  }),

  getReferralPayoutRequests: builder.query<Paginate<PayoutRequest>, PaginationParams>({
    query: (params) => ({
      url: 'referrals/payout-requests',
      method: 'GET',
      params,
    }),
  }),

  getBankDetails: builder.query<BankDetails, void>({
    query: () => ({
      url: 'referrals/bank-details',
      method: 'GET',
    }),
    providesTags: ['Account-Bank-Details'],
  }),

  enrollReferrals: builder.mutation<void, void>({
    query: () => ({
      url: 'referrals/enroll',
      method: 'POST',
    }),
    invalidatesTags: ['Referrals-Details'],
  }),

  upsertBankDetails: builder.mutation<void, UpsertBankDetailsPayload>({
    query: (data) => ({
      url: 'referrals/bank-details',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Bank-Details'],
  }),

  referralsPayout: builder.mutation<void, void>({
    query: () => ({
      url: 'referrals/payout',
      method: 'POST',
    }),
    invalidatesTags: ['Referrals-Details'],
  }),
});
