import type { StripeElementLocale } from '@stripe/stripe-js';
import { type TOptions, t } from 'i18next';

import { SupportedLanguage } from 'modules/common/Language/types';

export function createTranslationLink(key: string, defaultParams: Record<string, unknown> = {}) {
  return (params: TOptions) =>
    t(key, {
      ...defaultParams,
      ...params,
    }) || '';
}

export function getStripeLocale(language: SupportedLanguage): StripeElementLocale {
  switch (language) {
    case SupportedLanguage.CN:
      return 'zh';
    case SupportedLanguage.EN:
      return 'en';
    case SupportedLanguage.ES:
      return 'es';
    case SupportedLanguage.VI:
      return 'vi';
    case SupportedLanguage.RU:
      return 'ru';
    default:
      return 'en';
  }
}
