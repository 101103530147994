import { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { useIDVerificationStatus } from 'hooks/useIDVerificationStatus';
import { paths } from 'paths';
import { VerificationStatus } from 'store/auth/models';
import type { ITheme } from 'theme';

import Styled from './styled';

type IDVerificationBannerProps = {
  horizontalLayout?: boolean;
};

export function IDVerificationBanner({ horizontalLayout }: IDVerificationBannerProps) {
  const { t } = useTranslation();

  const { verificationRequest } = useIDVerificationStatus();
  const navigate = useNavigate();
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('sm'));

  const shouldApplyMobileStyles = useMemo(
    () => isMobileDevice || !horizontalLayout,
    [isMobileDevice, horizontalLayout],
  );

  const button = useMemo(
    () => (
      <Button fullWidth={shouldApplyMobileStyles} onClick={() => navigate(paths.account.identityVerification)}>
        {t('common:buttons.completeVerification')}
      </Button>
    ),
    [shouldApplyMobileStyles, navigate, t],
  );

  const { subtitle, title, reason } = useMemo(() => {
    if (verificationRequest?.status === VerificationStatus.FAILED) {
      return {
        title: t('common:idVerification.failed.title'),
        subtitle: t('common:idVerification.failed.subtitle'),
        reason: verificationRequest?.reason,
      };
    }

    return {
      title: t('common:idVerification.title'),
      subtitle: t('common:idVerification.subtitle'),
      reason: null,
    };
  }, [t, verificationRequest?.reason, verificationRequest?.status]);

  return (
    <Styled.Container
      size={shouldApplyMobileStyles ? 'small' : 'large'}
      direction={shouldApplyMobileStyles ? 'vertical' : 'horizontal'}
      actions={isMobileDevice ? [button] : undefined}
      isFailed={!!reason}
    >
      <Grid container rowSpacing={2} justifyContent="space-between" direction={horizontalLayout ? 'row' : 'column'}>
        <Grid item>
          <Grid container rowSpacing={0.5} direction="column">
            <Grid item>
              <Typography variant="title">{title}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="body-2">{subtitle}</Typography>
            </Grid>

            {reason && (
              <Grid item>
                <Typography variant="body-2">{t('common:idVerification.failed.reason', { reason })}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isMobileDevice && !reason && <Grid item>{button}</Grid>}
      </Grid>
    </Styled.Container>
  );
}
