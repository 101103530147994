import { ReactComponent as DatacenterIcon } from 'assets/icons/proxy/datacenter.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/proxy/mobile.svg';
import { ReactComponent as RotatingResidentialIcon } from 'assets/icons/proxy/rotating-residential.svg';
import { ReactComponent as StaticResidentialIcon } from 'assets/icons/proxy/static-residential.svg';

export const proxy = {
  'proxy-datacenter': DatacenterIcon,
  'proxy-mobile': MobileIcon,
  'proxy-rotating-residential': RotatingResidentialIcon,
  'proxy-static-residential': StaticResidentialIcon,
};
