import { styled } from '@mui/material/styles';

import { Icon as IconComponent } from 'components/Icon';
import type { IconProps as IconComponentProps } from 'components/Icon/types';

type IconProps = IconComponentProps & {
  isActive?: boolean;
};

const Icon = styled(({ isActive, ...props }: IconProps) => <IconComponent {...props} />)(({ isActive, theme }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.text.secondary,
}));

export default { Icon };
