import { useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Autocomplete } from 'components/form-partials/Autocomplete';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useUplinkSpeedFormatter } from 'hooks/useUplinkSpeedFormatter';
import { useGetBandwidthSpeedUpgradePriceQuery } from 'store/api';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

import { BalanceSummary } from '../BalanceSummary';
import ModalStyled from '../styled';

export type UpgradeBandwidthSpeedFormProps = {
  proxyIds: number[];
  price: number;
  setPriceForSingle: (price: number) => void;
};

type UpgradeBandwidthSpeedValues = {
  bandwidthSpeed: number;
};

export function UpgradeBandwidthSpeedForm({ proxyIds, price, setPriceForSingle }: UpgradeBandwidthSpeedFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { values, setFieldValue, handleSubmit, isSubmitting, isValid } =
    useFormikContext<UpgradeBandwidthSpeedValues>();

  const prepareUplinkSpeedLabel = useUplinkSpeedFormatter();

  const {
    data: speedPrices,
    isFetching: isPriceFetching,
    refetch,
  } = useGetBandwidthSpeedUpgradePriceQuery(proxyIds[0], {
    skip: proxyIds.length > 1,
  });

  useEffect(() => {
    // no cached data for new instance, prevent errors when calling refetching without initial fetch
    if (speedPrices) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // set 1st by default if not found
  useEffect(() => {
    if (speedPrices?.length) {
      const foundPrice = speedPrices.find(({ mbit }) => mbit === values.bandwidthSpeed);

      if (!foundPrice) {
        setFieldValue('bandwidthSpeed', speedPrices[0].mbit);
      }
    }
  }, [speedPrices, values.bandwidthSpeed, setFieldValue]);

  useEffect(() => {
    if (speedPrices) {
      const foundPrice = speedPrices.find(({ mbit }) => mbit === values.bandwidthSpeed);

      if (foundPrice) {
        setPriceForSingle(+foundPrice.price.toFixed(2));
      }
    }
  }, [speedPrices, values.bandwidthSpeed, setPriceForSingle]);

  const speedOptions = useMemo<Option[]>(
    () =>
      speedPrices?.map(({ mbit }) => {
        return { value: mbit, label: prepareUplinkSpeedLabel(mbit) };
      }) ?? [],
    [speedPrices, prepareUplinkSpeedLabel],
  );

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.upgrade')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, isPriceFetching, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.upgradeBandwidthSpeed.title')}
      subtitle={t('proxies.modals.upgradeBandwidthSpeed.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        <Autocomplete size="small" disableClearable options={speedOptions} name="bandwidthSpeed" />

        <Stack direction="column" rowGap={2}>
          <BalanceSummary isLoading={isPriceFetching} price={price} />
          <Divider />

          <ModalStyled.PriceContainer>
            <Typography variant="title" color="grey.500">
              {t('common:form.total')}
            </Typography>
            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(price || 0)}</Typography>
            )}
          </ModalStyled.PriceContainer>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
