import type { SVGProps } from 'react';

import { styled } from '@mui/material/styles';

import { ReactComponent as NewEmailSvg } from 'assets/images/new-email.svg';
import { Card, type CardProps } from 'components/Card';

type ContainerProps = Omit<CardProps, 'type'> & { type: 'error' | 'info' | 'contrast'; verticalLayout?: boolean };

const Container = styled(({ type, verticalLayout, ...props }: ContainerProps) => <Card {...props} />)(
  ({ type, verticalLayout, theme }) => ({
    width: 'auto',
    paddingTop: verticalLayout ? theme.spacing(4) : theme.spacing(2),
    paddingBottom: theme.spacing(2),

    svg: {
      minWidth: 128,
      height: 128,
    },

    ...(type === 'contrast' && {
      backgroundColor: theme.palette.primary.darkest,
    }),

    ...(type === 'error' && {
      backgroundColor: theme.palette.error.light,
    }),

    ...(type === 'info' && {
      backgroundColor: theme.palette.grey['200'],
    }),
  }),
);

const NewEmailIcon = styled(
  ({ light, ...props }: SVGProps<SVGSVGElement> & { title?: string | undefined; light?: boolean }) => (
    <NewEmailSvg {...props} />
  ),
)(({ theme, light }) => ({
  circle: {
    stroke: light ? theme.palette.grey['200'] : theme.palette.primary.darkest,
  },
}));

export default { Container, NewEmailIcon };
