/**
 * Available payment options
 * ! This enum should be only used to communicate between application and API.
 *
 * Defines the selected payment method on the API side.
 * STRIPE - used for payments by Credit Card
 * CRYPTO - used for payments by Crypto
 * ALIPAY - used for payments by AliPay
 * PAYPAL - used for payments by PayPal
 * GIROPAY - used for payments by Giropay
 * SOFORT - used for payments by Sofort
 * IDEAL - used for payments by iDeal
 * BLIK - used for payments by Blik
 * P24 - used for payments by P24
 * WALLET - used for payments by the user's balance
 */
export enum PaymentOption {
  STRIPE = 'STRIPE',
  CRYPTO = 'CRYPTO',
  ALIPAY = 'ALIPAY',
  PAYPAL = 'PAYPAL',
  GIROPAY = 'GIROPAY',
  SOFORT = 'SOFORT',
  IDEAL = 'IDEAL',
  BLIK = 'BLIK',
  P24 = 'P24',
  WALLET = 'WALLET',
}

/**
 * Credit card types
 *
 * Defines which credit card type was used by the use.
 * Needs to be defined when user selects the CreditCard payment option.
 */
export type CreditCardType = 'new-card' | 'another-card' | 'existing-card';

/**
 * Top-up payment types
 *
 * Defines the available payment methods in Top-up process.
 */
export type TopUpPaymentOptionType =
  | CreditCardType
  | 'crypto'
  | 'alipay'
  | 'paypal'
  | 'giropay'
  | 'sofort'
  | 'ideal'
  | 'blik'
  | 'p24';

/**
 * Checkout payment types
 *
 * Defines the available payment method in Checkout process.
 */
export type PaymentOptionType = TopUpPaymentOptionType | 'balance';

/**
 * Event type for Payments Broadcast channel
 */
export type PaymentCallbackEvent = {
  isPaymentFinished: boolean;
};

/**
 * Payment redirect metadata obtained from API
 */
export type PaymentRedirectMetadata = {
  /**
   * Payment metadata type
   */
  type: 'redirect';

  /**
   * URL to which SPA should redirect user on success
   */
  data: string;
};

/**
 * Payment token metadata obtained from API
 */
export type PaymentTokenMetadata = {
  /**
   * Payment metadata type
   */
  type: 'token';

  /**
   * PaymentIntent token required by Stripe
   */
  data: string;
};

export type PaymentMetadata = PaymentRedirectMetadata | PaymentTokenMetadata;

/**
 * Available currencies for Stripe purposes
 *
 * USD - Creidt card, Alipay, PayPal
 * EUR - GiroPay, Sofort, iDeal
 * PLN - Blik, P24
 */
export type Currency = 'eur' | 'cny' | 'pln' | 'usd';
