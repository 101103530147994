import { forwardRef } from 'react';

import { Link, type LinkProps, type To } from 'react-router-dom';

type RouterLinkProps = Omit<LinkProps, 'to'> & {
  href: To;
};

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(function RouterLinkComponent(
  { href, ...props },
  ref,
) {
  return <Link ref={ref} to={href} {...props} />;
});
