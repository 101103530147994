import { useEffect, useMemo } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { useUplinkSpeedFormatter } from 'hooks/useUplinkSpeedFormatter';
import { useChangeBandwidthSpeedMutation, useGetBandwidthSpeedChangeOptionsQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { Option } from 'types';
import { ToastManager } from 'utils/toast';

import { ChangeUplinkSpeedForm, type ChangeUplinkSpeedValues } from './ChangeUplinkSpeedForm';

export type ChangeUplinkSpeedModalProps = {
  proxyIds: number[];
};

export function ChangeUplinkSpeedModal({ proxyIds }: ChangeUplinkSpeedModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const prepareUplinkSpeedLabel = useUplinkSpeedFormatter();

  const [changeBandwidthSpeed] = useChangeBandwidthSpeedMutation();
  const {
    data: bandwidthSpeedOptions,
    isFetching: isOptionsLoading,
    refetch,
  } = useGetBandwidthSpeedChangeOptionsQuery(proxyIds[0], {
    skip: proxyIds.length > 1,
  });

  const options = useMemo<Option[]>(() => {
    return bandwidthSpeedOptions?.options.map((i) => ({ value: i, label: prepareUplinkSpeedLabel(i) })) ?? [];
  }, [bandwidthSpeedOptions?.options, prepareUplinkSpeedLabel]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Formik<ChangeUplinkSpeedValues>
      initialValues={{ bandwidthSpeed: bandwidthSpeedOptions?.currentUplinkSpeed ?? 100 }}
      enableReinitialize
      onSubmit={async ({ bandwidthSpeed }) => {
        try {
          await changeBandwidthSpeed({ proxyId: proxyIds[0], uplinkSpeed: bandwidthSpeed }).unwrap();

          ToastManager.success(t('proxies.modals.upgradeBandwidthSpeed.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('proxies.modals.upgradeBandwidthSpeed.failure'));
          }
        }
      }}
    >
      <ChangeUplinkSpeedForm options={options} isLoading={isOptionsLoading} />
    </Formik>
  );
}
