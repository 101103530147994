import { type ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { MultiUpdatesAccordion } from 'modules/proxies/MultiUpdatesAccordion';
import { useGetBandwidthExtensionPriceQuery, useGetBuyTrafficPriceBulkQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { CurrencyFormatter } from 'utils/currency';

import { BalanceSummary } from '../BalanceSummary';
import ModalStyled from '../styled';

export type ExtendBandwidthFormProps = {
  proxies: ProxyModel[];
  setPriceForSingle: (price: number) => void;
};

type ExtendBandwidthValues = {
  bandwidth: number;
};

export function ExtendBandwidthForm({ proxies, setPriceForSingle }: ExtendBandwidthFormProps) {
  const { t } = useTranslation();
  const { values, setFieldValue, handleSubmit, isValid, isSubmitting } = useFormikContext<ExtendBandwidthValues>();
  const hideModal = useHideModal();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const { data: extensionPriceSingle, isFetching: isPriceSingleFetching } = useGetBandwidthExtensionPriceQuery(
    { proxyId: proxyIds[0], bandwidth: values.bandwidth },
    { skip: proxyIds.length > 1 },
  );

  const { data: extensionPriceBulk, isFetching: isPriceBulkFetching } = useGetBuyTrafficPriceBulkQuery(
    { ids: proxyIds, trafficInGB: values.bandwidth },
    { skip: proxyIds.length <= 1 },
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseFloat(e.target.value.trim());

      setFieldValue('bandwidth', Number.isNaN(parsedValue) ? 1 : parsedValue);
    },
    [setFieldValue],
  );

  const extensionPrice = proxyIds.length > 1 ? extensionPriceBulk : extensionPriceSingle;
  const isPriceFetching = isPriceBulkFetching || isPriceSingleFetching;

  const priceForSingle = useMemo(() => {
    if (extensionPriceSingle) {
      return +extensionPriceSingle.finalPrice.toFixed(2);
    }

    if (!extensionPrice?.finalPrice) {
      return 0;
    }

    return proxyIds.length > 1 ? +(extensionPrice.finalPrice / proxyIds.length).toFixed(2) : extensionPrice.finalPrice;
  }, [extensionPrice?.finalPrice, extensionPriceSingle, proxyIds.length]);

  useEffect(() => {
    setPriceForSingle(priceForSingle);
  }, [priceForSingle, setPriceForSingle]);

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.extend')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, t, isPriceFetching],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendBandwidth.title')}
      subtitle={t('proxies.modals.extendBandwidth.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <NumericFormat
          fullWidth
          name="bandwidth"
          suffix=" GB"
          value={values.bandwidth}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          customInput={Field}
          component={TextField}
        />

        <Stack direction="column" rowGap={2}>
          <BalanceSummary isLoading={isPriceFetching} price={extensionPrice?.finalPrice} />
          <Divider />
          <ModalStyled.PriceContainer>
            <Typography variant="title" color="grey.500">
              {t('common:form.total')}
            </Typography>
            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(extensionPrice?.finalPrice || 0)}</Typography>
            )}
          </ModalStyled.PriceContainer>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
