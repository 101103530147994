import { Fragment, useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { closeQuiz } from 'store/auth/reducers';

type OptionValue = 'experienced' | 'new';

export const ExperienceCheckModal = () => {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const [selectedOption, setSelectedOption] = useState<OptionValue>();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const dispatch = useAppDispatch();

  const options = useMemo(
    () => [
      {
        value: 'experienced',
        title: t('order.modals.experienceCheck.experienced.title'),
        subtitle: t('order.modals.experienceCheck.experienced.subtitle'),
      },
      {
        value: 'new',
        title: t('order.modals.experienceCheck.new.title'),
        subtitle: t('order.modals.experienceCheck.new.subtitle'),
      },
    ],
    [t],
  );

  const onClose = useCallback(() => {
    dispatch(closeQuiz());

    return hideModal();
  }, [dispatch, hideModal]);

  const onContinue = useCallback(() => {
    if (selectedOption === 'experienced') {
      navigate(paths.order.index);
      posthog?.capture(posthogEvents.order.experiencedUser);
    }

    if (selectedOption === 'new') {
      navigate(paths.quiz.index);
      posthog?.capture(posthogEvents.order.newbieUser);
    }

    hideModal();
  }, [selectedOption, hideModal, navigate, posthog]);

  return (
    <ModalContainer
      title={t('order.modals.experienceCheck.title')}
      subtitle={t('order.modals.experienceCheck.subtitle')}
      actions={[
        <Button key="cancel" color="secondary" fullWidth onClick={onClose}>
          {t('common:buttons.cancel')}
        </Button>,
        <Button key="continue" fullWidth onClick={onContinue} disabled={!selectedOption}>
          {t('common:buttons.continue')}
        </Button>,
      ]}
    >
      <RadioGroup name="experience" onChange={(_, value) => setSelectedOption(value as OptionValue)}>
        <Grid container rowSpacing={3} direction="column">
          {options.map(({ value, title, subtitle }, index, initialArray) => (
            <Fragment key={value}>
              <Grid item>
                <FormControl>
                  <FormControlLabel
                    value={value}
                    control={<Radio size="medium" />}
                    label={
                      <Box display="flex" flexDirection="column">
                        <Typography variant="title">{title}</Typography>
                        <Typography variant="body-2" color="grey.500">
                          {subtitle}
                        </Typography>
                      </Box>
                    }
                  />
                </FormControl>
              </Grid>
              {index !== initialArray.length - 1 && (
                <Grid item>
                  <Divider />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </RadioGroup>
    </ModalContainer>
  );
};
