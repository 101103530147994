/**
 * Common types for proxy
 */

export enum AuthenticationType {
  IP_WHITELIST = 'IP_WHITELIST',
  USERNAME_PASSWORD = 'USERNAME_PASSWORD',
}

export enum IPVersion {
  IPv4 = 'IPv4',
  IPv6 = 'IPv6',
}
export enum NetworkType {
  Mobile = 'MOBILE',
  Residential = 'RESIDENTIAL',
  ResidentialStatic = 'RESIDENTIAL_STATIC',
  Datacenter = 'DATACENTER',
  VPN = 'VPN',
}

export type ProxyNetworkType = Exclude<NetworkType, NetworkType.VPN>;

export enum Protocol {
  SOCKS5 = 'SOCKS5',
  HTTP = 'HTTP',
  HTTPS = 'HTTPS',
}

export enum ProxyStatus {
  PENDING = 'PENDING',
  INITIATING = 'INITIATING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  EXPIRING_SOON = 'EXPIRING_SOON',
}

export enum ServiceType {
  PROXY_MOBILE = 'PROXY_MOBILE',
  PROXY_RESIDENTIAL = 'PROXY_RESIDENTIAL',
  PROXY_RESIDENTIAL_STATIC = 'PROXY_RESIDENTIAL_STATIC',
  PROXY_DATACENTER = 'PROXY_DATACENTER',
  VPN = 'VPN',
}

export enum ProxySubnetStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  DRAINING = 'DRAINING',
  NEW_ORDERS_PAUSED = 'NEW_ORDERS_PAUSED',
}

export type ISP = {
  id: string;
  name: string;
};

export type ProxyCredentialsFormat = 'IP_PORT' | 'IP_PORT_USERNAME_PASSWORD' | 'USERNAME_PASSWORD_IP_PORT';
