import { useCallback, useEffect } from 'react';

export type FreshChatProps = {
  externalId?: string;
  firstName?: string;
  lastName?: string;
  restoreId?: string;
  email?: string;
  phone?: string;
  phoneCountryCode?: string;
  config?: unknown;
  open?: boolean;
  tags?: [string];
  faqTags?: unknown;
  locale?: string;
  onInit: () => void;

  isSuspended?: boolean;
  profileLink?: string;
};

export function FreshChat({ onInit, ...props }: FreshChatProps) {
  const loadScript = useCallback(() => {
    const id = 'freshchat-lib';

    if (document.getElementById(id) || window.fcWidget) return;

    const script = document.createElement('script');

    script.async = true;
    script.type = 'text/javascript';
    script.src = '//eu.fw-cdn.com/11781861/441206.js';
    script.id = id;
    document.head.appendChild(script);
  }, []);

  const init = useCallback(() => {
    window.fcWidget?.init(props);

    window.fcSettings = {
      onInit: () => {
        if (props.externalId) {
          window.fcWidget?.setExternalId(props.externalId);
        }

        if (props.firstName) {
          window.fcWidget?.user.setFirstName(props.firstName);
        }

        if (props.email) {
          window.fcWidget?.user.setEmail(props.email);
        }

        if (props.profileLink) {
          window.fcWidget?.user.setProperties({
            isSuspended: !!props.isSuspended,
            profileLink: props.profileLink,
          });
        }
      },
    };

    onInit();
  }, [props, onInit]);

  useEffect(() => {
    loadScript();

    const interval = setInterval(() => {
      if (!window.fcWidget) return;

      clearInterval(interval);

      try {
        init();
      } catch {
        // noop
      }
    }, 100);
  }, [init, loadScript]);

  return <></>;
}
