import isJWT from 'validator/lib/isJWT';

import type { UserRole } from 'store/auth/types';

export type JwtPayload = {
  iat: number;
  exp?: number;
  roles: UserRole[];
  mfa: boolean;
  username: string;
};

export function isTokenValid(token?: string | null) {
  return token && isJWT(token);
}

export function parseJWT(token?: string | null) {
  if (!token || !isJWT(token)) return null;

  const [, base64Url] = token.split('.');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(
    decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    ),
  ) as JwtPayload;
}

export function isTokenExpired(token: string, tokenThreshold = 30_000) {
  const payload = parseJWT(token);

  if (!payload) return true;

  const { exp } = payload;

  if (!exp) {
    return false;
  }

  const expirationTimestamp = exp * 1000 - tokenThreshold;

  return Date.now() > expirationTimestamp;
}
