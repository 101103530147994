import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CannyButtonWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),

  '& .Canny_Badge': {
    top: '14px',
    right: '15px',
  },
}));

export default {
  CannyButtonWrapper,
};
