import type { ChangeEvent } from 'react';

import MenuItem from '@mui/material/MenuItem';
import MuiPagination from '@mui/material/Pagination';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import type { Option } from 'types';

import TableStyled from './styled';

export type PaginationProps = {
  page: number;
  perPage: number;
  pages: number;
  options?: Option[];
  label?: string | null;
  disabled?: boolean;
  onPageChange: (e: ChangeEvent<unknown>, newPage: number) => void;
  onPerPageChange?: (e: SelectChangeEvent) => void;
};

export const DEFAULT_OPTIONS: Option[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export function Pagination({
  page,
  perPage = 20,
  pages,
  options = DEFAULT_OPTIONS,
  label,
  disabled,
  onPageChange,
  onPerPageChange,
}: PaginationProps) {
  return (
    <TableStyled.PaginationWrapper>
      <TableStyled.PaginationFiller>
        {pages > 1 && <MuiPagination disabled={disabled} count={pages} page={page} onChange={onPageChange} />}
      </TableStyled.PaginationFiller>

      {perPage && onPerPageChange && options && (
        <TableStyled.PerPageSwitchWrapper>
          {label && (
            <Typography variant="body-2" color="text.secondary">
              {label}
            </Typography>
          )}
          <Select size="small" disabled={disabled} value={perPage.toString()} onChange={onPerPageChange}>
            {options.map((option) => (
              <MenuItem key={option.label} value={typeof option.value !== 'object' ? option.value : undefined}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </TableStyled.PerPageSwitchWrapper>
      )}
    </TableStyled.PaginationWrapper>
  );
}
