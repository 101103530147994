import { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Input } from 'components/form-partials/Input';
import { Icon } from 'components/Icon';
import { TextButton } from 'components/TextButton';
import { OrderFieldTooltip } from 'modules/orders/OrderFieldTooltip';
import { useGetProxyIpWhitelistQuery, useGetUserSessionQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';

import Styled from './styled';

type IPWhitelistFormProps = {
  proxyIds: Array<ProxyModel['id']>;
};

export function IPWhitelistForm({ proxyIds }: IPWhitelistFormProps) {
  const { t } = useTranslation();
  const { data: session } = useGetUserSessionQuery();
  const { values, setFieldValue, errors } = useFormikContext<{ ipWhitelist: string[] }>();
  const { data: whitelistData } = useGetProxyIpWhitelistQuery(proxyIds[0], {
    skip: proxyIds.length > 1,
  });

  const MAX_IPS = useMemo(() => whitelistData?.allowedWhitelistIpCount || 1, [whitelistData]);

  const handleAddNewIPField = useCallback(() => {
    setFieldValue(`ipWhitelist`, values.ipWhitelist.concat(['']));
  }, [values.ipWhitelist, setFieldValue]);

  const setIP = useCallback(() => {
    const isAnyFieldEmpty = values.ipWhitelist.some((item) => !item.length);

    if (!isAnyFieldEmpty) return setFieldValue('ipWhitelist[0]', session?.userIp || '');

    const emptyIndex = values.ipWhitelist.findIndex((item) => !item.length);

    return setFieldValue(`ipWhitelist[${emptyIndex}]`, session?.userIp || '');
  }, [values, setFieldValue, session]);

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item>
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <Grid container direction="column" rowSpacing={4}>
              {values.ipWhitelist.map((_, index) => (
                <Grid item key={`ip-whitelist-${index}`} display="flex">
                  <Input fullWidth name={`ipWhitelist[${index}]`} />

                  {index > 0 ? (
                    <IconButton
                      sx={{ ml: 2, p: 0 }}
                      disableRipple
                      onClick={() =>
                        setFieldValue(
                          'ipWhitelist',
                          values.ipWhitelist.filter((__, itemIndex) => itemIndex !== index),
                        )
                      }
                    >
                      <Icon name="minus-circle" size="small" iconStroke="#FF604A" />
                    </IconButton>
                  ) : (
                    <OrderFieldTooltip title={t('order.tooltips.ipWhitelist')} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {values.ipWhitelist.length < MAX_IPS && (
            <Grid item>
              <TextButton onClick={handleAddNewIPField} labelProps={{ color: 'primary.main' }} labelVariant="caption">
                {t('common:buttons.addAnother')}
              </TextButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {session?.userIp && (
        <Grid item>
          <Styled.SetIPButton onClick={setIP}>
            {t('common:buttons.setYourCurrentIp', { ip: session.userIp })}
          </Styled.SetIPButton>
        </Grid>
      )}
      {getIn(errors, 'globalError') && (
        <Grid item>
          <Typography variant="body-2" color="error.main">
            {getIn(errors, 'globalError')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
