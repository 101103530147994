import type { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';

import type { AppEndpointBuilder } from 'store/base';
import type { Paginate, PaginationParams } from 'types';
import { buildLegacyAppURL } from 'utils/url';
import { downloadFile } from 'utils/values';

import type {
  AccountAuthenticationMethods,
  AccountBalanceResponse,
  AccountDisable2FAPayload,
  AccountEnableTOTPPayload,
  AccountEnableTOTPResponse,
  AccountEnableWebAuthnPayload,
  AccountProfileResponse,
  AutoTopUpPayload,
  BillingPreferences,
  ChangePasswordPayload,
  EmailNotificationsPayload,
  EmailNotificationsResponse,
  Invoice,
  SaveProfilePayload,
  TopUpPayload,
  Transaction,
  ChangeLanguagePayload,
  TopUpFeesPayload,
  TopUpStatusPayload,
} from './dtos';
import {
  toAddCreditCardModel,
  toCreditCardModel,
  toPaymentFeeModel,
  toTopUpModel,
  toTopUpStatusModel,
} from './helpers';
import type {
  APIKeyModel,
  AddCreditCardModel,
  CreditCardModel,
  PaymentFeeModel,
  TopUpModel,
  TopUpStatusModel,
} from './models';

export const accountEndpoints = (builder: AppEndpointBuilder) => ({
  getUserAccountBalance: builder.query<AccountBalanceResponse, void>({
    query: () => ({
      url: 'account/balance',
      method: 'GET',
    }),
    providesTags: ['Account-Balance'],
  }),

  getUserAccountProfile: builder.query<AccountProfileResponse, void>({
    query: () => ({
      url: 'account/profile',
      method: 'GET',
    }),
    providesTags: ['Account-Details'],
  }),

  getNotificationPreferences: builder.query<EmailNotificationsResponse, void>({
    query: () => ({
      url: 'account/communication/preferences',
      method: 'GET',
    }),
    providesTags: ['Account-Communication-Preferences'],
  }),

  get2FAAuthenticationMethodsStatuses: builder.query<AccountAuthenticationMethods, void>({
    query: () => ({
      url: buildLegacyAppURL('account/2fa/authentication-methods'),
      method: 'GET',
    }),
    providesTags: ['Account-2FA-Methods-Statuses'],
  }),

  getInvoices: builder.query<Paginate<Invoice>, PaginationParams>({
    query: (params) => ({
      url: 'account/billing/invoices',
      method: 'GET',
      params,
    }),
  }),

  getTransactions: builder.query<Paginate<Transaction>, PaginationParams>({
    query: (params) => ({
      url: 'account/billing/transactions',
      method: 'GET',
      params,
    }),
  }),

  getBillingPreferences: builder.query<BillingPreferences, void>({
    query: () => ({
      url: 'account/billing/preferences',
      method: 'GET',
    }),
    providesTags: ['Account-Billing-Preferences'],
  }),

  getApiKeys: builder.query<Paginate<APIKeyModel>, PaginationParams>({
    query: (params) => ({
      url: 'account/api-keys',
      method: 'GET',
      params,
    }),
    providesTags: (result) =>
      result?.data
        ? [
            ...result.data.map(({ apiKey }) => ({ type: 'API-Key' as const, id: apiKey })),
            { type: 'API-Key', id: 'LIST' },
          ]
        : [{ type: 'API-Key', id: 'LIST' }],
  }),

  getIDVerificationToken: builder.query<{ token: string }, void>({
    query: () => ({
      url: 'account/identity/token',
      method: 'GET',
    }),
  }),

  saveAccountProfile: builder.mutation<Record<string, never>, SaveProfilePayload>({
    query: (profile) => ({
      url: 'account/profile',
      method: 'POST',
      data: profile,
    }),
    invalidatesTags: ['Account-Details', 'Session'],
  }),

  changePassword: builder.mutation<void, ChangePasswordPayload>({
    query: (data) => ({
      url: 'account/change-password',
      method: 'POST',
      data,
    }),
  }),

  setNotificationPreferences: builder.mutation<void, EmailNotificationsPayload>({
    query: (data) => ({
      url: 'account/communication/preferences',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Communication-Preferences'],
  }),

  enable2FAToTP: builder.mutation<AccountEnableTOTPResponse | Record<string, unknown>, AccountEnableTOTPPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/totp'),
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-2FA-Methods-Statuses'],
  }),

  disable2FAToTP: builder.mutation<void, AccountDisable2FAPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/totp'),
      method: 'PUT',
      data,
    }),
    invalidatesTags: ['Account-2FA-Methods-Statuses'],
  }),

  enable2FAWebAuthn: builder.mutation<PublicKeyCredentialCreationOptionsJSON, AccountEnableWebAuthnPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/webauthn'),
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-2FA-Methods-Statuses'],
  }),

  disable2FAWebauthn: builder.mutation<void, AccountDisable2FAPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/2fa/webauthn'),
      method: 'PUT',
      data,
    }),
    invalidatesTags: ['Account-2FA-Methods-Statuses'],
  }),

  removeCreditCard: builder.mutation<void, void>({
    query: (data) => ({
      url: 'account/billing/card/unauthorize',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Billing-Preferences', 'Account-Card-Details'],
  }),

  // #region Payments

  getCreditCardDetails: builder.query<CreditCardModel, void>({
    query: () => ({
      url: 'account/billing/card-details',
      method: 'GET',
    }),
    transformResponse: toCreditCardModel,
    providesTags: ['Account-Card-Details'],
  }),

  addCreditCard: builder.mutation<AddCreditCardModel, void>({
    query: () => ({
      url: 'account/billing/card/authorize',
      method: 'POST',
    }),
    transformResponse: toAddCreditCardModel,
  }),

  updateAutoTopUpPreferences: builder.mutation<void, AutoTopUpPayload>({
    query: (data) => ({
      url: 'account/billing/auto-top-up',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Account-Billing-Preferences'],
  }),

  topUp: builder.mutation<TopUpModel, TopUpPayload>({
    query: (data) => ({
      url: buildLegacyAppURL('account/billing/top-up'),
      method: 'POST',
      data,
    }),
    transformResponse: toTopUpModel,
    invalidatesTags: ['Account-Balance', 'Account-Card-Details', 'Session'],
  }),

  getTopUp: builder.query<TopUpStatusModel, TopUpStatusPayload>({
    query: ({ id }) => ({
      url: buildLegacyAppURL(`account/billing/top-up/${id}`),
      method: 'GET',
    }),
    transformResponse: toTopUpStatusModel,
  }),

  getTopUpFees: builder.mutation<PaymentFeeModel, TopUpFeesPayload>({
    query: (data) => ({
      url: 'account/billing/top-up/fees',
      method: 'POST',
      data,
    }),
    transformResponse: toPaymentFeeModel,
  }),

  // #endregion

  changeLanguage: builder.mutation<void, ChangeLanguagePayload>({
    query: (data) => ({
      url: 'account/language',
      method: 'POST',
      data,
    }),
  }),

  skipProfileDetails: builder.mutation<void, void>({
    query: () => ({
      url: 'account/profile/skip-profile',
      method: 'POST',
    }),
    invalidatesTags: ['Session'],
  }),

  generateApiKey: builder.mutation<void, void>({
    query: () => ({
      url: 'account/api-keys',
      method: 'POST',
    }),
    invalidatesTags: ['API-Key'],
  }),

  deleteApiKey: builder.mutation<void, string>({
    query: (apiKey: string) => ({
      url: `account/api-keys/${apiKey}`,
      method: 'DELETE',
    }),
    invalidatesTags: (_, __, param) => [{ type: 'API-Key', id: param }],
  }),

  resendEmailVerificationLink: builder.mutation<void, void>({
    query: () => ({
      url: 'account/resend-activation-email',
      method: 'POST',
    }),
  }),

  bulkDownloadInvoices: builder.mutation<void, number[]>({
    query: (ids) => ({
      url: '/account/billing/invoices-download',
      method: 'POST',
      data: { ids },
      responseType: 'blob',
    }),
    // Needs to be directly called here
    // RTK provides the serialization check for each value that can be stored in the RTK cache
    // ! https://redux-toolkit.js.org/api/serializabilityMiddleware
    transformResponse: (data: Blob) => downloadFile(data, 'invoices.zip'),
  }),
});
