import { useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Field, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { MultiUpdatesAccordion } from 'modules/proxies/MultiUpdatesAccordion';
import type { ProxyModel } from 'store/proxies/models';

import Styled from '../styled';

type BulkAutoExtendFormProps = {
  proxyIds: Array<ProxyModel['id']>;
  isAllSelected: boolean;

  isLoading: boolean;
  isBandwidthRequired: boolean;
};

export type BulkAutoExtendValues = {
  isEnabled: boolean;
  bandwidth: number;
};

export function BulkAutoExtendForm({
  proxyIds,
  isAllSelected,
  isBandwidthRequired,
  isLoading,
}: BulkAutoExtendFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, values, handleSubmit } = useFormikContext<BulkAutoExtendValues>();

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="submit" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isLoading}>
        {t('common:buttons.confirm')}
      </Button>,
    ],
    [hideModal, isLoading, isSubmitting, isValid, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.autoExtend.title')}
      subtitle={t('proxies.modals.autoExtend.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        {proxyIds.length > 1 && !isAllSelected && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <Stack direction="column" rowGap={2}>
          <Styled.PriceContainer>
            <Typography variant="title">{t('common:form.isAutoExtendEnabled')}</Typography>
            {isLoading ? (
              <Skeleton width={40} height={24} />
            ) : (
              <Field component={Switch} disabled={isSubmitting} type="checkbox" name="isEnabled" />
            )}
          </Styled.PriceContainer>

          <Collapse in={isBandwidthRequired}>
            <Styled.PriceContainer>
              <Typography variant="title">{t('common:form.bandwidth')}</Typography>

              <NumericFormat
                name="bandwidth"
                value={values.bandwidth}
                suffix=" GB"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                customInput={Field}
                component={TextField}
                size="small"
              />
            </Styled.PriceContainer>
          </Collapse>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
