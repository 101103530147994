import useMediaQuery from '@mui/material/useMediaQuery';

import { Link } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { useRouter } from 'hooks/useRouter';
import { paths } from 'paths';
import type { ITheme } from 'theme';

import Styled from './styled';

export function FeatureRequestLayoutAppBar() {
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));
  const router = useRouter();

  return (
    <Styled.AppBarRoot>
      <Styled.AppBarToolbar disableGutters>
        <Styled.AppBarLogoWrapper>
          <Link to={paths.index}>
            <Logo wide={!isMobileDevice} />
          </Link>
        </Styled.AppBarLogoWrapper>

        <Styled.AppBarIconButton onClick={() => router.replace(paths.index)}>
          <Icon name="cross" />
        </Styled.AppBarIconButton>
      </Styled.AppBarToolbar>
    </Styled.AppBarRoot>
  );
}
