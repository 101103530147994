import { useMemo } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { Input } from 'components/form-partials/Input';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useGetProxyOverviewQuery, useUpsertProxyNoteMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { ToastManager } from 'utils/toast';

export type ProxyNoteModalProps = {
  proxyId: ProxyModel['id'];
  initialNote: ProxyModel['note'];
};

type NoteValues = {
  note: string;
};

export function ProxyNoteModal({ initialNote = null, proxyId }: ProxyNoteModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const { data: proxy } = useGetProxyOverviewQuery(proxyId);
  const [upsertNote] = useUpsertProxyNoteMutation();

  const schema = useMemo<Yup.Schema<NoteValues>>(
    () =>
      Yup.object().shape({
        note: initialNote ? Yup.string().default('').max(120) : Yup.string().required().max(120),
      }),
    [initialNote],
  );

  return (
    <Formik<NoteValues>
      initialValues={{
        note: proxy?.note || '',
      }}
      onSubmit={async ({ note }, { setSubmitting }) => {
        try {
          await upsertNote({ proxyId, note: note.length > 0 ? note : null });

          posthog?.capture(note.length > 0 ? posthogEvents.proxyNoteAdded : posthogEvents.proxyNoteDeleted);

          ToastManager.success(t(`proxies.modals.note.success.${initialNote ? 'edit' : 'add'}`));

          hideModal();
        } catch {
          ToastManager.error(t(`proxies.modals.note.success.${initialNote ? 'edit' : 'add'}`));
        } finally {
          setSubmitting(false);
        }
      }}
      enableReinitialize
      validationSchema={schema}
    >
      {({ isValid, isSubmitting, handleSubmit, dirty }) => (
        <ModalContainer
          component="form"
          containerProps={{ onSubmit: handleSubmit, noValidate: true }}
          title={t('proxies.modals.note.title.edit')}
          subtitle={t(`proxies.modals.note.subtitle`)}
          actions={[
            <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
              {t('common:buttons.cancel')}
            </Button>,
            <Button key="confirm" type="submit" fullWidth disabled={!isValid || !dirty} loading={isSubmitting}>
              {t('common:buttons.confirm')}
            </Button>,
          ]}
        >
          <Input id="note" name="note" multiline rows={6} fullWidth placeholder={t('common:form.note')} />
        </ModalContainer>
      )}
    </Formik>
  );
}
