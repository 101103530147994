import { useCallback, useMemo } from 'react';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button, type ButtonProps } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useEmailVerification } from 'hooks/useEmailVerification';
import { useRouter } from 'hooks/useRouter';
import { useShowModal } from 'modules/common/GlobalModals';
import { paths } from 'paths';
import { useGetUserSessionQuery, useSkipProfileDetailsMutation } from 'store/api';

import type { TopUpValues } from '../modals/TopUpModal/types';

type TopUpButtonProps = Omit<ButtonProps, 'onClick'> & {
  label?: string | null;
  isOrderFlow?: boolean;
  initialValues?: Partial<TopUpValues>;
};

export function TopUpButton({ label, isOrderFlow, size = 'medium', initialValues, ...props }: TopUpButtonProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const showModal = useShowModal();
  const router = useRouter();
  const posthog = usePostHog();
  const { shouldForceEmailVerification } = useEmailVerification();

  const { data: session, isLoading } = useGetUserSessionQuery();
  const [skipProfileDetails] = useSkipProfileDetailsMutation();

  const isButtonDisabled = useMemo(
    () => isLoading || session?.profileDetails?.isSuspended,
    [isLoading, session?.profileDetails?.isSuspended],
  );

  const onClick = useCallback(() => {
    if (shouldForceEmailVerification()) return;

    if (!session?.profileDetails?.valid && !session?.profileDetails?.isProfileSkipped) {
      return showModal('account.billing.topUp.confirmation', {
        onConfirm: () => {
          router.push(paths.account.general);
          hideModal();
        },
        title: t('account.modals.beforeTopUp.title'),
        subtitle: t('account.modals.beforeTopUp.subtitle'),
        confirmLabel: t('account.modals.beforeTopUp.confirm'),
        cancelLabel: t('common:buttons.skip'),

        onCancel: async () => {
          try {
            await skipProfileDetails().unwrap();
          } catch {
            // noop
          }

          return showModal('account.billing.topUp', { isOrderFlow, initialValues });
        },
      });
    }

    return showModal('account.billing.topUp', { isOrderFlow, initialValues });
  }, [
    hideModal,
    initialValues,
    isOrderFlow,
    router,
    session,
    shouldForceEmailVerification,
    showModal,
    skipProfileDetails,
    t,
  ]);

  return (
    <Button
      onClick={onClick}
      size={size}
      {...props}
      disabled={isButtonDisabled}
      onClickCapture={() => posthog?.capture(posthogEvents[isOrderFlow ? 'orderTopUp' : 'topUp'].click)}
    >
      {label ?? t('common:buttons.topUp')}
    </Button>
  );
}
