import { useCallback, type ReactNode, useEffect } from 'react';

import MuiTabs, { type TabsProps as MuiTabsProps } from '@mui/material/Tabs';

import { useLocation } from 'react-router-dom';

import Styled from './styled';
import { Tab } from './Tab';

type UseSyncTabsOptions = {
  items: string[];
  callback: (value: number) => void;
};

export function useSyncTabs({ items, callback }: UseSyncTabsOptions) {
  const location = useLocation();

  const syncTabs = useCallback(() => {
    const indexPath = items.findIndex((path) => path === location.pathname);

    callback(indexPath >= 0 ? indexPath : 0);
  }, [callback, items, location.pathname]);

  useEffect(() => {
    syncTabs();
  }, [syncTabs]);
}

type TabsProps<T> = MuiTabsProps & {
  items: T[];
  keyExtractor: (item: T) => string | number;
  renderItem: (Component: typeof Tab, item: T, key: string | number) => ReactNode;
  extra?: ReactNode;
};

export function Tabs<T>({ items, renderItem, keyExtractor, extra, ...props }: TabsProps<T>) {
  return (
    <Styled.Container>
      <MuiTabs {...props}>{items.map((item) => renderItem(Tab, item, keyExtractor(item)))}</MuiTabs>
      {extra}
    </Styled.Container>
  );
}
