import type { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography, { type TypographyProps } from '@mui/material/Typography';

import Styled from './styled';

export type TextButtonProps = Omit<ButtonProps, 'variant'> & {
  labelVariant?: TypographyProps['variant'];
  labelProps?: TypographyProps;
};

export const TextButton: OverridableComponent<ButtonTypeMap<TextButtonProps>> = ({
  labelVariant = 'caption',
  labelProps,
  children,
  ...props
}: ButtonProps & TextButtonProps) => {
  return (
    <Styled.Container {...props}>
      <Typography {...labelProps} component="span" variant={labelVariant}>
        {children}
      </Typography>
    </Styled.Container>
  );
};
